import Grid from "./Grid";

export default function PlanGridMonthly(props) {
	return (
    <div className="text-center color-fancy-gray d-none d-sm-block">
      <div className="row">
        <div className="col-2 p-5"></div>
        <div className="col-2 p-5"></div>
        <div className="col-2 p-5 bg-color-gray border-bottom border-end" style={{borderTopLeftRadius: '0.5rem'}}>
          <div className="text-dark">Standard</div>
          <div>Month to Month</div>
          <div className="text-dark fw-bold">Starting at $222.49</div>
        </div>
        <div className="col-2 p-5 bg-color-gray border-bottom border-end">
          <div className="text-dark">Advanced</div>
          <div>Month to Month</div>
          <div className="text-dark fw-bold">Starting at $337.49</div>
        </div>
        <div className="col-2 p-5 bg-color-gray text-muted border-bottom border-end" style={{borderTopRightRadius: '0.5rem'}}>
          <div className="">Enterprise</div>
          <div className="fw-bold">Just available for annually</div>
          <div className="text-decoration-line-through">N/A</div>
        </div>
        <div className="col-2 p-5"></div>
      </div>

      <Grid />

    </div>
  );
}