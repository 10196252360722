import Nav from "../components/Nav";
import Footer from "../components/Footer";

export default function PrivacyPolicy(props) {
  return (
    <div>
      <header className="">
        <Nav/>
      </header>

      <div className="container">
        <div className="row">
          <div className="col-12 p-5">
            <h1 className="text-center fw-bolder color-aqua">Privacy Policy</h1>
          </div>
          <div className="col-12 fw-bolder px-5 pb-5 fs-5">
            <div className="row">
              <div className="col-1"/>
              <div className="col-10" style={{fontSize: '1.2rem'}}>
                <p><strong>Last Updated: January, 16, 2023</strong></p><p>This Privacy Policy is designed to help you understand how Krikey, Inc. (“<strong>Krikey</strong>,” “<strong>we</strong>,” “<strong>us</strong>,” or<strong> </strong>“<strong>our</strong>”) collects, uses, processes, and shares your personal information, and to help you understand and exercise your privacy rights.</p><p>If you need to access this Policy in an alternative format due to having a disability, please contact
                us at (650) 257-0835, or support@krikey.com.</p><p><strong>Notice at Collection</strong></p><p>At or before the time of collection, California residents have a right to receive notice of Krikey’s privacy practices, including the categories of personal information to be collected, the purposes for which such information is collected or used, whether such information is “sold” or “shared” and how to opt-out of such uses and how long such information is retained.</p><p><strong>About Our Services and the Blockchain</strong></p><p>Our Services (as defined below) may facilitate interaction with certain decentralized cryptographic protocols like blockchains that we do not own or control (each, a “<strong>Protocol</strong>”). Our Services may also require the use of a digital wallet
                provided by third party services, such as Blotco<em>,</em> that we do not own or control that enable you to take self-custody and manage digital assets (such as Non-Fungible Token or other cryptocurrencies),. Use of these third party services will then enable you to interact with other third party services and supported Protocols that support the sale, purchase, trade and distribution of digital assets on the decentralized blockchains on which the supported digital assets are
                recorded.</p><p><em>An Important Note Regarding Blockchain Technologies:</em><strong> </strong>If you participate in our Services relating to the creation non-fungible tokens (“<strong>NFT(s)</strong>”) or other digital assets, smart contracts will be employed that necessarily collect information, such as your digital wallet address (which some legal jurisdictions may consider personal information), and that information will be stored on a public blockchain that we do not
                control. A smart contract is computer code that automatically processes events when certain conditions are met. For example, when a user wants to add an NFT to a digital wallet, a smart contract would automatically execute the proposed action. In these situations, your personal information will be stored on that blockchain through the execution of a smart contract and that personal information may not be able to be modified or deleted due to the immutable nature of the
                blockchain.</p><p><strong>Privacy Policy Table of Contents</strong></p><p><a href="#1-scope-to-this-privacy-policy">1......... SCOPE TO THIS PRIVACY POLICY</a></p><p><a href="#2-personal-information-we-collect">2......... PERSONAL INFORMATION WE COLLECT</a></p><p><a
                href="#3-how-we-use-your-personal-information">3......... HOW WE USE YOUR PERSONAL INFORMATION</a></p><p><a href="#4-how-we-disclose-your-personal-information">4......... HOW WE DISCLOSE YOUR PERSONAL INFORMATION</a></p><p><a href="#5-your-privacy-choices-and-rights">5.........
                YOUR PRIVACY CHOICES AND RIGHTS</a></p><p><a href="#6-retention-of-personal-information">6......... RETENTION OF PERSONAL INFORMATION</a></p><p><a href="#7-supplemental-notice-for-california-residents">7......... SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</a></p><p><a
                href="#8-supplemental-notice-for-nevada-residents">8......... SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</a></p><p><a href="#9-childrens-information">9......... CHILDREN’S INFORMATION</a></p><p><a href="#10-other-provisions">10....... OTHER
                PROVISIONS</a></p><p><a href="#11-contact-us">11....... CONTACT US</a></p><h2 id="1-scope-to-this-privacy-policy"><strong>1.</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <strong><a href="#scope"></a>SCOPE TO THIS PRIVACY POLICY</strong></h2><p>This Privacy Policy applies to personal information processed by us, including on our websites, and other online or offline offerings. To make this Privacy Policy
                easier to read, our websites and other offerings are collectively called the “<strong>Services</strong>.”</p><p><strong>Changes to our Privacy Policy</strong>. We may revise this Privacy Policy from time to time in our sole discretion. If there are any material changes to this Privacy Policy, we will notify you as required by applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if you continue to use our Services after the
                new Privacy Policy takes effect.</p><h2 id="2-personal-information-we-collect"><strong>2.</strong> <strong>PERSONAL INFORMATION WE COLLECT</strong></h2><p>The categories of personal information we collect depend on how you interact with us, our Services, and the requirements of applicable law. We collect information that you provide to us, information we obtain automatically when you use our Services, and information from other sources such as third-party services and
                organizations, as described below.</p><p><strong>A.</strong> <strong>Personal Information You Provide to Us Directly</strong></p><p>We may collect personal information that you provide to us.</p><p>· &nbsp;  <strong>Account Creation</strong>. We will create your account through certain third-party services such as Google (a “<strong>Third-Party Account</strong>”) and collect necessary personal information by extracting from your Third-Party Account certain personal information
                such as your name, email address, and other personal information that your privacy settings on the Third-Party Account permits us to access.</p><p>· &nbsp;  <strong>Purchases</strong>. We may collect personal information and details associated with your purchases, including payment information. Any payments made via our Services are processed by third-party payment processors. We do not directly collect or store any payment card information entered through our Services, but we
                may receive information associated with your payment card information (for example, your billing details).</p><p>· &nbsp;  <strong>Your Communications with Us</strong>. We may collect personal information, such as email address, phone number, or mailing address when you request information about our Services, register for our newsletter, request customer or technical support, or otherwise communicate with us.</p><p>· &nbsp;  <strong>Surveys</strong>. We may contact you to
                participate in surveys. If you decide to participate, we may collect personal information from you in connection with the survey.</p><p>· &nbsp;  <strong>Interactive Features</strong>. We and others who use our Services may collect personal information that you submit or make available through our interactive features (for example, blockchain, messaging and chat features, commenting functionalities, forums, blogs, and social media pages). Any information you provide using the
                public sharing features of the Services will be considered “public,” unless otherwise required by applicable law, and is not subject to the privacy protections referenced herein. Please exercise caution before revealing any information that may identify you in the real world to other users.</p><p>· &nbsp;  <strong>Sweepstakes or Contests</strong>. We may collect personal information you provide for any sweepstakes or contests that we offer. In some jurisdictions, we are
                required to publicly share information of sweepstakes and contest winners.</p><p>· &nbsp;  <strong>Conferences, Trade Shows, and Other Events</strong>. We may collect personal information from individuals when we attend or host conferences, trade shows, and other events.</p><p>· &nbsp;  <strong>Business Development and Strategic Partnerships</strong>. We may collect personal information from individuals and third parties to assess and pursue potential business opportunities.
              </p><p>· &nbsp;  <strong>Job Applications</strong>. We may post job openings and opportunities on our Services. If you respond to one of these postings, we may collect your personal information, such as your application, CV, cover letter, and/or any other information you provide to us.</p><p><strong>B.</strong> <strong>Personal Information Collected Automatically</strong></p><p>We may collect personal information automatically when you use our Services.</p><p>· &nbsp;  <strong>Automatic
                Collection of Personal Information</strong>. We may collect certain information automatically when you use our Services, such as your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile carrier, mobile advertising and other unique identifiers, browser or device information, location information (including approximate location derived from IP address), and Internet service provider. We may also automatically collect information regarding your
                use of our Services, such as pages that you visit before, during and after using our Services, information about the links you click, the types of content you interact with, the frequency and duration of your activities, and other information about how you use our Services.</p><p>· &nbsp;  <strong>Cookie Policy (and Other Technologies).</strong> We, as well as third parties that provide content, advertising, or other functionality on our Services, may use cookies, pixel tags,
                and other technologies (“<strong>Technologies</strong>”) to automatically collect information through your use of our Services.</p><p>o &nbsp; <strong>Cookies</strong>. Cookies are small text files placed in device browsers that store preferences and facilitate and enhance your experience.</p><p>o &nbsp; <strong>Pixel Tags/Web Beacons</strong>. A pixel tag (also known as a web beacon) is a piece of code embedded in our Services that collects information about engagement on our
                Services. The use of a pixel tag allows us to record, for example, that a user has visited a particular web page or clicked on a particular advertisement. We may also include web beacons in e-mails to understand whether messages have been opened, acted on, or forwarded.</p><p>Our uses of these Technologies fall into the following general categories:</p><p>· &nbsp;  <strong>Operationally Necessary</strong>. This includes Technologies that allow you access to our Services,
                applications, and tools that are required to identify irregular website behavior, prevent fraudulent activity, improve security, or allow you to make use of our functionality;</p><p>· &nbsp;  <strong>Performance-Related</strong>. We may use Technologies to assess the performance of our Services, including as part of our analytic practices to help us understand how individuals use our Services (<em>see Analytics below</em>);</p><p>· &nbsp;  <strong>Functionality-Related</strong>.
                We may use Technologies that allow us to offer you enhanced functionality when accessing or using our Services. This may include identifying you when you sign into our Services or keeping track of your specified preferences, interests, or past items viewed;</p><p>· &nbsp;  <strong>Advertising- or Targeting-Related</strong>. We may use first party or third-party Technologies to deliver content, including ads relevant to your interests, on our Services or on third-party digital
                properties.</p><p><em>See “Your Privacy Choices and Rights” below to understand your choices regarding these Technologies.</em></p><p>· &nbsp;  <strong>Analytics</strong>. We may use Technologies and other third-party tools to process analytics information on our Services. These Technologies allow us to better understand how our digital Services are used and to continually improve and personalize our Services. [<strong>To the extent these are used</strong> <strong>,
                include:</strong> Some of our analytics partners include:</p><p>o &nbsp; <strong>Google Analytics</strong>.<strong> </strong>For more information about how Google uses your personal information (including for its own purposes, for example, for profiling or linking it to other data), please visit<a href="http://www.google.com/policies/privacy/partners/"> Google Analytics’ Privacy Policy</a>. To learn more about how to opt-out of Google Analytics’ use of your information, please
                click<a href="http://tools.google.com/dlpage/gaoptout"> here</a>.</p><p><strong>C.</strong> <strong>Personal Information Collected from Other Sources</strong></p><p><strong>Third-Party Services and Sources</strong>. We may obtain personal information about you from other sources, including through third-party services and organizations. &nbsp;For example, if you access our Services through a third-party application, such as an app store, a third-party login service (like
                Google), or a social networking site, we may collect personal information about you from that third-party application that you have made available via your privacy settings.</p><p><strong>Blockchain Information. </strong>We may obtain personal information about you through our analysis of blockchain information.</p><p><strong>[Referrals and Sharing Features</strong>.<strong> </strong>Our Services may offer various tools and functionalities that allow you to provide personal
                information about your friends through our referral service. [Third parties may also use the Services to upload personal information about you, including when then tag you.] Our referral services may also allow you to forward or share certain content with a friend or colleague, such as an email inviting your friend to use our Services. Please only share with us contact information of people with whom you have a relationship (for example, relative, friend, neighbor, or
                co-worker).]</p><h2 id="3-how-we-use-your-personal-information"><strong>3.</strong> <strong>HOW WE USE YOUR PERSONAL INFORMATION</strong></h2><p>We use your personal information for a variety of business purposes, including to provide our Services, for administrative purposes, and to market our products and Services, as described below.</p><p><strong>A.</strong> <strong>Provide Our Services</strong></p><p>We use your information to fulfill our contract with you and provide you
                with our Services, such as:</p><p>· &nbsp;  Managing your information and accounts;</p><p>· &nbsp;  Facilitating digital transactions you enter into;</p><p>· &nbsp;  Providing access to certain areas, functionalities, and features of our Services;</p><p>· &nbsp;  Answering requests for customer or technical support;</p><p>· &nbsp;  Communicating with you about your account, activities on our Services, and policy changes;</p><p>· &nbsp;  Processing your financial information and
                other payment methods for products or Services purchased;</p><p>· &nbsp;  Processing applications if you apply for a job we post on our Services; and</p><p>· &nbsp;  Allowing you to register for events.<br/></p><p><strong>B.</strong> <strong>Administrative Purposes</strong></p><p>We use your information for various administrative purposes, such as:</p><p>· &nbsp;  Pursuing our legitimate interests such as direct marketing, research and development (including marketing research),
                network and information security, and fraud prevention;</p><p>· &nbsp;  Detecting security incidents, protecting against malicious, deceptive, fraudulent or illegal activity, and prosecuting those responsible for that activity;</p><p>· &nbsp;  Measuring interest and engagement in our Services;</p><p>· &nbsp;  Short-term, transient use, such as contextual customization of ads;</p><p>· &nbsp;  Improving, upgrading, or enhancing our Services;</p><p>· &nbsp;  Developing new
                products and services;</p><p>· &nbsp;  Ensuring internal quality control and safety;</p><p>· &nbsp;  Authenticating and verifying individual identities, including requests to exercise your rights under this Privacy Policy;</p><p>· &nbsp;  Debugging to identify and repair errors with our Services;</p><p>· &nbsp;  Auditing relating to interactions, transactions, and other compliance activities;</p><p>· &nbsp;  Sharing personal information with third parties as needed to provide
                the Services;</p><p>· &nbsp;  Enforcing our agreements and policies; and</p><p>· &nbsp;  Carrying out activities that are required to comply with our legal obligations.</p><p><strong>C.</strong> <strong>Marketing and Advertising our Products and Services</strong></p><p>We may use personal information to tailor and provide you with content and advertisements. We may provide you with these materials as permitted by applicable law.</p><p>Some of the ways we market to you include
                email campaigns, custom audiences advertising, and “interest-based” or “personalized advertising” or “targeted advertising” that may be done through cross-device tracking.</p><p>If you have any questions about our marketing practices, you may contact us at any time as set forth in “Contact Us” below.</p><p><strong>D.</strong> <strong>Other Purposes</strong></p><p>We also use your personal information for other purposes as requested by you or as permitted by applicable law.</p>
                <p>· &nbsp;  <strong>With Your Consent</strong> We may use personal information for other purposes that are clearly disclosed to you at the time you provide personal information or with your consent.</p><p>· &nbsp;  <strong>De-identified and Aggregated Information</strong>. We may use personal information to create de-identified and/or aggregated information, such as demographic information, information about the device from which you access our Services, or other analyses we
                create.</p><h2 id="4-how-we-disclose-your-personal-information"><strong>4.</strong> <strong>HOW WE DISCLOSE YOUR PERSONAL INFORMATION</strong></h2><p>We disclose your personal information to third parties for a variety of business purposes, including to provide our Services, to protect us or others, or in the event of a major business transaction such as a merger, sale, or asset transfer, as described below.</p><p><strong>A.</strong> <strong>Disclosures to Provide our
                Services</strong></p><p><strong>Notice Regarding Use of the Blockchain.</strong> Aspects of our Services may be hosted on or interact with the blockchain. Where you use aspects of our Services that are hosted on or interact with the blockchain, information about your interactions and/or transactions will be provided to the applicable blockchain network and may be accessible to third parties due to the nature of the blockchain protocol.</p><p>The categories of third parties with
                whom we may share your personal information are described below.</p><p>· &nbsp;  <strong>Service Providers</strong>. We may share your personal information with our third-party service providers and vendors that assist us with the provision of our Services. This includes service providers and vendors that provide us with IT support, hosting, payment processing, customer service, and related services.</p><p>· &nbsp;  <strong>Business Partners</strong>. We may share your personal
                information with business partners to provide you with a product or service you have requested. We may also share your personal information with business partners with whom we jointly offer products or services.</p><p>· &nbsp;  <strong>Affiliates</strong>. We may share your personal information with our company affiliates.</p><p>· &nbsp;  <strong>Other Users or Third Parties You Share or Interact With</strong>. As described above in “Personal Information We Collect,” our
                Services may allow you to share personal information or interact with other users and third parties (including individuals and third parties who do not use our Services and the general public, such as those on the blockchain).</p><p>· &nbsp;  <strong>Advertising Partners</strong>. We may share your personal information with third-party advertising partners. These third-party advertising partners may set Technologies and other tracking tools on our Services to collect
                information regarding your activities and your device (for example, your IP address, cookie identifiers, page(s) visited, location, time of day). These advertising partners may use this information (and similar information collected from other services) for purposes of delivering personalized advertisements to you when you visit digital properties within their networks. This practice is commonly referred to as “interest-based advertising”, “targeted advertising,” “personalized
                advertising, or cross-context behavioral advertising.”</p><p>· &nbsp;  <strong>APIs/SDKs</strong>. We may use third-party application program interfaces (“<strong>APIs</strong>”) and software development kits (“<strong>SDKs</strong>”) as part of the functionality of our Services. SDKs may allow third parties including analytics and advertising partners to collect your information for various purposes including to provide analytics services and personalized advertising. For more
                information about our use of APIs and SDKs, please contact us as set forth in “Contact Us”<strong> </strong>below.</p><p><strong>B.</strong> <strong>Disclosures to Protect Us or Others</strong></p><p>We may access, preserve, and disclose any information we store associated with you to external parties if we, in good faith, believe doing so is required or appropriate to: comply with law enforcement or national security requests and legal process, such as a court order or
                subpoena; protect your, our, or others’ rights, property, or safety; enforce our policies or contracts; collect amounts owed to us; or assist with an investigation or prosecution of suspected or actual illegal activity.</p><p><strong>C.</strong> <strong>Disclosure in the Event of Merger, Sale, or Other Asset Transfers</strong></p><p>If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or
                transition of service to another provider, your information may be sold or transferred as part of such a transaction, as permitted by law and/or contract.</p><h2 id="5-your-privacy-choices-and-rights"><strong>5.</strong> <strong>YOUR PRIVACY CHOICES AND RIGHTS</strong></h2><p><strong>Your Privacy Choices</strong>. The privacy choices you may have about your personal information are determined by applicable law and are described below.</p><p>· &nbsp;  <strong>Email
                Communications</strong>. If you receive an unwanted email from us, you can use the unsubscribe link found at the bottom of the email to opt out of receiving future emails. Note that you will continue to receive transaction-related emails regarding products or Services you have requested. We may also send you certain non-promotional communications regarding us and our Services, and you will not be able to opt out of those communications (for example, communications regarding our
                Services or updates to our Terms or this Privacy Policy).</p><p>· &nbsp;  <strong>“Do Not Track</strong>.<strong>”</strong> Do Not Track (“<strong>DNT</strong>”) is a privacy preference that users can set in certain web browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers.</p><p>· &nbsp;  <strong>Cookies and Personalized Advertising</strong>. You may stop or restrict the placement of Technologies on your device
                or remove them by adjusting your preferences as your browser or device permits. However, if you adjust your preferences, our Services may not work properly. Please note that cookie-based opt-outs are not effective on mobile applications. However, you may opt-out of personalized advertisements on some mobile applications by following the instructions for<a href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en"> Android</a>,<a
                  href="https://support.apple.com/en-us/HT202074"> iOS</a>, and<a href="https://www.networkadvertising.org/mobile-choice/"> others</a>.</p><p>The online advertising industry also provides websites from which you may opt out of receiving targeted ads from data partners and other advertising partners that participate in self-regulatory programs. You can access these and learn more about targeted advertising and consumer choice and privacy by visiting the<a
                href="http://www.networkadvertising.org/managing/opt_out.asp"> Network Advertising Initiative</a>,<a href="http://www.aboutads.info/choices/"> the Digital Advertising Alliance</a>,<a href="https://www.youronlinechoices.eu/"> the European Digital Advertising Alliance</a>, and<a href="https://youradchoices.ca/choices/"> the Digital Advertising Alliance of Canada</a>.</p><p>Please note you must separately opt out in each browser and on each device.</p><p><strong>Your Privacy
                Rights</strong>. In accordance with applicable law, you may have the right to:</p><p>· &nbsp;  <strong>Confirm Whether We Are Processing Your Personal Information </strong>(the right to know);</p><p>· &nbsp;  <strong>Access to and Portability of Your Personal Information</strong>, including: (i) obtaining access to or a copy of your personal information; and (ii) receiving an electronic copy of personal information that you have provided to us, or asking us to send that
                information to another company in a structured, commonly used, and machine readable format (also known as the “right of data portability”);</p><p>· &nbsp;  <strong>Request Correction </strong>of your personal information where it is inaccurate or incomplete. In some cases, we may provide self-service tools that enable you to update your personal information;</p><p>· &nbsp;  <strong>Request Correction </strong>of your personal information where it is inaccurate or incomplete. In
                some cases, we may provide self-service tools that enable you to update your personal information;</p><p>· &nbsp;  <strong>Request Deletion </strong>of your personal information;</p><p>· &nbsp;  <strong>Request to Opt-Out of Certain Processing Activities</strong> including, as applicable, if we process your personal information for “targeted advertising” (as “targeted advertising” is defined by applicable privacy laws), &nbsp;if we “sell” your personal information (as “sell” is
                defined by applicable privacy laws), or if we engage in “profiling” in furtherance of certain “decisions that produce legal or similarly significant effects” concerning you (as such terms are defined by applicable privacy laws);</p><p>· &nbsp;  <strong>Request Restriction of or Object to </strong>our<strong> </strong>processing of your personal information;</p><p>· &nbsp;  <strong>Withdraw your Consent</strong> to our processing of your personal information. Please note that
                your withdrawal will only take effect for future processing, and will not affect the lawfulness of processing before the withdrawal; and</p><p>· &nbsp;  <strong>Appeal our Decision </strong>to decline to process your request.</p><p>If you would like to exercise any of these rights, please contact us as set forth in “Contact Us”<strong> </strong>below. We will process such requests in accordance with applicable laws.</p><p>If applicable laws grant you an appeal right and you
                would like to appeal our decision with respect to your request, you may do so by informing us of this and providing us with information supporting your appeal.</p><h2 id="6-retention-of-personal-information"><strong>6.</strong> <strong>RETENTION OF PERSONAL INFORMATION</strong></h2><p>We store the personal information we collect as described in this Privacy Policy for as long as you use our Services, or as necessary to fulfill the purpose(s) for which it was collected, provide
                our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.</p><p>To determine the appropriate retention period for personal information, we may consider applicable legal requirements, the amount, nature, and sensitivity of the personal information, certain risk factors, the purposes for which we process your personal information, and whether we can achieve those purposes
                through other means.</p><h2 id="7-supplemental-notice-for-california-residents"><strong>7.</strong> <strong>SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</strong></h2><p>This Supplemental Notice for California Residents supplements our Privacy Notice and only applies to our processing of personal information that is subject to the California Consumer Privacy Act of 2018 (as amended from time to time) (“<strong>CCPA</strong>”).</p><p>The CCPA provides California residents with
                the right to know what categories of personal information Krikey has collected about them, whether Krikey disclosed that personal information for a business purpose (e.g., to a service provider), whether Krikey “sold” that personal information, and whether Krikey “shared” that personal information for “cross-context behavioral advertising” in the preceding twelve months. California residents can find this information below:</p>
                <table style={{border: 'none', borderCollapse: 'collapse'}}>
                  <colgroup>
                    <col width={282}/>
                    <col width={168}/>
                    <col width={140}/>
                  </colgroup>
                  <tbody>
                  <tr style={{height: '66.75pt'}}>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', textAlign: 'center', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '11pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Category of Personal Information Collected by Krikey</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', textAlign: 'center', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '11pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Categories of Third Parties Personal Information is Disclosed to for a Business Purpose</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', textAlign: 'center', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '11pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Categories of Third Parties To Whom Personal Information is Sold and/or Shared</span></p></td>
                  </tr>
                  <tr style={{height: '94.5pt'}}>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Identifiers.</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>[</span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#c0504d', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol (IP) address, email address, account name, digital wallet address, Social Security or government identification numbers (including passports, driver’s licenses, etc.), or other similar identifiers</span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>.]</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', textIndent: '-20pt', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 0pt 20pt'}}>
                      <span style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Service Providers</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', textIndent: '-20pt', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 0pt 20pt'}}><span
                      style={{fontSize: '10pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Blockchain Networks</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', textIndent: '-20pt', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 0pt 20pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Business Partners</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', textIndent: '-20pt', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 0pt 20pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Affiliates</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', textIndent: '-20pt', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 0pt 20pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Other Users or Third Parties You Share or Interact With</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '25pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}> &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Advertising Partners</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '25pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;</span></p></td>
                  </tr>
                  <tr style={{height: '204.75pt'}}>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</span></p><p dir="ltr"
                                                                                                                                                                                                                                                                                                                                                                                                         style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}>
                      <span style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#c0504d', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>[Examples: A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Personal Information does not include publicly available information that is lawfully made available to the general public from federal, state, or local government records. Note: Some personal information included in this category may overlap with other categories.]</span>
                    </p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', textIndent: '-20pt', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 0pt 20pt'}}>
                      <span style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Service Providers</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p></td>
                  </tr>
                  <tr style={{height: '127.5pt'}}>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Protected classification characteristics under California or federal law</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#c0504d', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>[Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).]</span>
                    </p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', textIndent: '-20pt', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 0pt 20pt'}}>
                      <span style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p></td>
                  </tr>
                  <tr style={{height: '61.5pt'}}>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Commercial information</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#c0504d', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>[Examples: Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.]</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', textIndent: '-20pt', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 0pt 20pt'}}>
                      <span style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Service Providers</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', textIndent: '-20pt', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 0pt 20pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p></td>
                  </tr>
                  <tr style={{height: '127.5pt'}}>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Biometric information</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#c0504d', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>[Examples: Physiological, biological, or behavioral, characteristics (including DNA) that can be used to establish individual identity, or imagery of the iris, retina, fingerprint, face, hand, palm, vein patterns, and voice recordings, from which an identifier template such as a faceprint, a minutiae template, or a voiceprint, can be extracted and keystroke&nbsp; patterns or rhythms, gait patterns, or rhythms, and sleep, health or exercise data that contain identifying information.]</span>
                    </p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p></td>
                  </tr>
                  <tr style={{height: '51pt'}}>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Internet or other electronic network activity</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#c0504d', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>[Examples: Browsing history, search history, information on a consumer's interaction with an internet website, application, or advertisement.]</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', textIndent: '-20pt', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 0pt 20pt'}}>
                      <span style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Service Providers</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '25pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}> &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Advertising Partners</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;</span></p></td>
                  </tr>
                  <tr style={{height: '28.5pt'}}>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Geolocation data</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#c0504d', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>[Examples: Physical location or movements.]</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p></td>
                  </tr>
                  <tr style={{height: '39.75pt'}}>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Sensory data</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#c0504d', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>[Examples: Audio, electronic, visual, thermal, olfactory, or similar information.]</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p></td>
                  </tr>
                  <tr style={{height: '51pt'}}>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Professional or employment-related information</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#c0504d', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>[Examples: Current or past job history or performance evaluations.]</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', textIndent: '-20pt', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 0pt 20pt'}}>
                      <span style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Service Providers</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p></td>
                  </tr>
                  <tr style={{height: '105.75pt'}}>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Sec. 1232g, 34 C.F.R. Part 99))</span></p><p dir="ltr" style={{
                      lineHeight: '1.38',
                      marginLeft: '7pt',
                      marginRight: '7pt',
                      marginTop: '0pt',
                      marginBottom: '0pt'
                    }}><span style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#c0504d', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>[Examples: Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.]</span>
                    </p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>N/A</span></p></td>
                  </tr>
                  <tr style={{height: '84pt'}}>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Inferences drawn from other personal information to create a profile about a consumer</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#c0504d', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>[</span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#c0504d', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Examples: Profile reflecting a consumer's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.]</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', textIndent: '-20pt', marginTop: '0pt', marginBottom: '0pt', padding: '0pt 0pt 0pt 20pt'}}>
                      <span style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp; &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Service Providers</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '27pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;</span></p></td>
                    <td style={{borderLeft: 'solid #bfbfbf 0.9999975pt', borderRight: 'solid #bfbfbf 0.9999975pt', borderBottom: 'solid #bfbfbf 0.9999975pt', borderTop: 'solid #bfbfbf 0.9999975pt', verticalAlign: 'top', backgroundColor: '#ffffff', padding: '3pt 3pt 3pt 3pt', overflow: 'hidden', overflowWrap: 'break-word'}}><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '25pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: 'Calibri,sans-serif', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>·</span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}> &nbsp; </span><span
                      style={{fontSize: '6.999999999999999pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}><span className="Apple-tab-span" style={{whiteSpace: 'pre'}}>	</span></span><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Advertising Partners</span></p><p dir="ltr" style={{lineHeight: '1.38', marginLeft: '7pt', marginRight: '7pt', marginTop: '0pt', marginBottom: '0pt'}}><span
                      style={{fontSize: '10pt', fontFamily: '"Times New Roman"', color: '#000000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>&nbsp;</span></p></td>
                  </tr>
                  </tbody>
                </table>
                <p><br/></p><p>The categories of sources from which we collect personal information and our business and commercial purposes for using and disclosing personal information are set forth in “Personal Information We Collect”, “How We Use Your Personal Information”, and “How We Disclose Your Personal Information” above, respectively. We will retain personal information in accordance with the time periods set forth in “Retention of Personal Information.”</p>
                <p>If applicable from the table above, we “sell” and “share” your personal information to provide you with “cross-context behavioral advertising” about Krikey’s products and services and to measure and analyze performance, improve the services we provide, and detect illegal or harmful activities.</p><p>Please note we want to collect, nor do we need to collect, any “sensitive information” as defined under the CCPA. Please do not attempt to send us sensitive information.</p><p>
                <strong>Additional Privacy Rights for California Residents</strong></p><p><strong>Opting Out of “Sales” of Personal Information and/or “Sharing” for Cross-Context Behavioral Advertising under the CCPA</strong>. California residents have the right to opt out of the “sale” of personal information and the “sharing” of personal information for “cross-context behavioral advertising.” California residents may exercise these rights by emailing support@krikey.com .</p><p><strong>Disclosure
                Regarding Individuals Under the Age of 16</strong>. Krikey does not have actual knowledge of any “sale” of personal information of minors under 16 years of age. Krikey does not have actual knowledge of any “sharing” of personal information of minors under 16 years of age for “cross-context behavioral advertising.”</p><p><strong>Non-Discrimination</strong>. California residents have the right not to receive discriminatory treatment by us for the exercise of their rights
                conferred by the CCPA.</p><p><strong>Authorized Agent</strong>.<strong> </strong>Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child. To authorize an agent, provide written authorization signed by you and your designated agent and contact us as set forth in “Contact Us” below for additional instructions.</p><p>
                <strong>Verification</strong>. To protect your privacy, we will take steps to reasonably verify your identity before fulfilling requests submitted under the CCPA. These steps may involve asking you to provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative. Examples of our verification process may include asking you to provide the email address or phone number or other
                personal information we may have collected while providing our Services to you, or opening a link sent to the contact information provided and following the instructions on the website you are taken to.</p><p><strong>California Shine the Light</strong>. The California “Shine the Light” law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we have disclosed their personal information (if any)
                for their direct marketing purposes in the prior calendar year, as well as the type of personal information disclosed to those parties.</p><p>If you are a California resident and would like to exercise any of your rights under the CCPA, please contact us as set forth in “Contact Us” below. We will process such requests in accordance with applicable laws.</p><h2 id="8-supplemental-notice-for-nevada-residents"><strong>8.</strong> <strong>SUPPLEMENTAL NOTICE FOR NEVADA
                RESIDENTS</strong><br/></h2><p>If you are a resident of Nevada, you have the right to opt-out of the sale of certain personal information to third parties who intend to license or sell that personal information. Please note that we do not currently sell your personal information as sales are defined in Nevada Revised Statutes Chapter 603A. If you have any questions, please contact us as set forth in Contact Us below.</p><h2 id="9-childrens-information">
                <strong>9.</strong> <strong>CHILDREN’S INFORMATION</strong><br/></h2><p>The Services are not directed to children under 18 (or other age as required by local law), and we do not knowingly collect personal information from children.</p><p>If you are a parent or guardian and believe your child has uploaded personal information through our Services without your consent and wish to review information collected from your child, or have that information modified or deleted, you may
                contact us as described in “Contact Us” below. If we become aware that a child has provided us with personal information in violation of applicable law, we will delete any personal information we have collected unless we have a legal obligation to keep it.</p><h2 id="10-other-provisions"><strong>10.</strong> &nbsp;<strong>OTHER PROVISIONS</strong></h2><p><strong>Third-Party Websites/Applications/Services. </strong>The Services may contain links to other websites/applications
                and other websites/applications may reference or link to our Services or the Services may allow you to interact with Third-Party Services. These third-party services are not controlled by us. We encourage our users to read the privacy policies of each website and application with which they interact. We do not endorse, screen, or approve, and are not responsible for the third party’s actions or omissions, including but not limited to their privacy practices, content of such
                other websites or applications, and errors or bugs in their software. Providing personal information to third-party websites or applications is at your own risk.</p><h2 id="11-contact-us"><strong>11.</strong> &nbsp;<strong>CONTACT US</strong></h2><p>If you have any questions about our privacy practices or this Privacy Policy, need to access this Policy in an alternative format due to having a disability, or to exercise your rights as detailed in this Privacy Policy, please
                contact us at:</p><p>Krikey, Inc.,</p><p>3000 Sand Hill Road, 4-100,</p><p>Menlo Park, CA 94025,</p><p>(650) 257-0835</p><p>support@krikey.com<br/><br/></p>
              </div>
              <div className="col-1"/>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
