import axios from "axios";

const SetUserSession = (email, user_id, wid, wid_provider, is_new_user, is_initialized, chain_id) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  localStorage.setItem('user', JSON.stringify({
    email,
    user_id,
    wid,
    wid_provider,
    is_new_user,
    is_initialized,
    chain_id,
    expire_date: currentDate,
  }));
  window.location.reload();
}

const GoogleAuthenticate = async (payload) => {
  return await axios.post(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/authenticate`, payload, {withCredentials: true})
    .then(function (res) {
      return res;
    })
    .catch(function (err) {
      return err.response.data;
    });
}

export function googleSuccessFlow() {
  return {
    flow: 'implicit',
    scope: 'profile',
    onSuccess: async (googleResult) => {
      const payload = {
        google_user_token: googleResult.access_token,
        platform: 'web',
        provider: 'google',
        cookie_domain: '.krikey.ai',
      };
      const authResult = await GoogleAuthenticate(payload);
      if (authResult.status === 200) {
        const {data} = authResult;
        const {email, id, is_new_user} = data.user;
        SetUserSession(email, id, '', '', is_new_user, false, '');
      }
    },
  };
}
