import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getUserSession} from "../Login";
import {getUserSubscriptions, searchProducts, getProduct, checkout} from "../../utils/Stripe";
import NavSDK from '../Nav/NavSDK';
import NavDashboard from '../Nav/NavDashboard';
import Projects from './Projects';
import Shop from './Shop';
import Combined from "./Combined";
import Basic from "./Basic";
import Billing from "./Billing";
import Api from "./Api";
import '../../assets/css/dashboard.css';

export default function Main(props) {
  const { page } = useParams();
  const [cartCount, setCartCount] = useState(0);
  const [showLoader, setShowLoader] = useState("");
  const [loadProducts, setLoadProducts] = useState("");
  const [discountedCount, setDiscountedCount] = useState(0);
  const [cartPrices, setCartPrices] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [purchasedProducts, setPurchasedProducts] = useState([]);
  const [productsBasic, setProductsBasic] = useState([]);
  const [productsPremium, setProductsPremium] = useState([]);
  const [productsApi, setProductsApi] = useState([]);
  const [show, setShow] = useState({
    'projects': (page === 'projects')? '': 'd-none',
    'billing': (page === 'billing')? '': 'd-none',
    'shop': (page === 'shop')? '': 'd-none',
    'combined': (page === 'combined')? '': 'd-none',
    'basic': (page === 'basic')? '': 'd-none',
    'premium': 'd-none',
    'api': 'd-none',
  });
  const [bold, setBold] = useState({
    'projects': (page === 'projects')? 'fw-bolder': '',
    'billing': (page === 'billing')? 'fw-bolder': '',
    'shop': (page === 'shop')? 'fw-bolder': '',
    'combined': '',
    'basic': '',
    'premium': '',
    'api': '',
  });

  const updateShow = (navItem) => {
    let clearedShow = {
      'projects': 'd-none',
      'combined': 'd-none',
      'billing': 'd-none',
      'shop': 'd-none',
      'basic': 'd-none',
      'premium': 'd-none',
      'api': 'd-none',
    };
    let clearedBold = {
      'projects': '',
      'combined': '',
      'billing': '',
      'shop': '',
      'basic': '',
      'premium': '',
      'api': '',
    };
    clearedShow[navItem] = ''; // show nav item by clearing css (i.e. 'd-none')
    clearedBold[navItem] = 'fw-bolder'
    setShow(clearedShow);
    setBold(clearedBold);
  };

  const addToCart = (priceObj, isDiscountable) => {
    if (isDiscountable) {
      setDiscountedCount(discountedCount+1);
    }

    const cartIds = cartPrices.map((price)=>price.id);
    if (!cartIds.includes(priceObj.id)) { // make sure a price is only ever added to the cart once
      cartPrices.push(priceObj);
      setCartPrices(cartPrices);
      setCartCount(cartPrices.length);
    }
  }

  const removeFromCart = (priceObj, isDiscountable) => {
    let index = -1;
    for (let i = 0; i < cartPrices.length; i++) {
      if (cartPrices[i].id === priceObj.id) {
        index = i;
      }
    }

    if (index !== -1) {
      console.log('removing...', priceObj.id)
      cartPrices.splice(index,1);
      setCartPrices(cartPrices);
      setCartCount(cartPrices.length);

      if (isDiscountable) {
        setDiscountedCount(discountedCount-1);
      }
    }
  }

  const runCheckout = async () => {
    const url = await checkout(cartPrices);
    window.location.href = url;
  }

  useEffect(() => {
    let prevSubCount = -1;
    let loadCount = 0;
    let maxCount = 4;
    const loadPurchasedProducts = async () => {
      loadCount++;
      console.log('loading...', loadCount);
      if (getUserSession()) {
        let purchasedProducts = [];
        let subscriptions = await getUserSubscriptions();
        for (let sub of subscriptions) {
          sub.products = [];
          if (sub.status !== 'canceled') {
            for (let price of sub.items.data) {
              let product = await getProduct(price.plan.product);
              product.price = price.price;
              sub.products.push(product);
              purchasedProducts.push(product);
            }
          }
        }
        setSubscriptions(subscriptions);
        setPurchasedProducts(purchasedProducts);
        console.log('purchasedProducts', purchasedProducts);
        console.log('subscriptions', subscriptions);

        // if we found new subscriptions turn off fetcher loader
        if (prevSubCount < subscriptions.length && prevSubCount > -1) {
          setShowLoader('d-none');
        }
        prevSubCount = subscriptions.length;

        setTimeout(() => {
          if (loadCount < maxCount) {
            loadPurchasedProducts();
          } else {
            setShowLoader('d-none'); // after enough product fetches turn off fetcher loader
          }
        }, 6000);
      }
    }
    loadPurchasedProducts();
  }, [loadProducts]);

  return (
    <div  style={{backgroundColor:'#f4f8fe', minHeight:'75rem'}}>

      <NavDashboard location="sdk" />

      <div className="container-fluid">

          <NavSDK location={page} updateShow={updateShow} bold={bold} />

          <div className={`${show.projects}`}>
            <Projects
              purchasedProducts={purchasedProducts}
              showLoader={showLoader}
            />
          </div>
          <div className={`${show.billing}`}>
            <Billing
              updateShow={updateShow}
              showLoader={showLoader}
              subscriptions={subscriptions}
              setLoadProducts={setLoadProducts}
            />
          </div>
          <div className={`${show.shop}`}>
            <Shop
              updateShow={updateShow}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
              purchasedProducts={purchasedProducts}
            />
          </div>
          <div className={`${show.combined}`}>
            <Combined
              products={productsBasic}
              setDiscountedCount={setDiscountedCount}
              purchasedProducts={purchasedProducts}
              runCheckout={runCheckout}
              cartCount={cartCount}
              discountedCount={discountedCount}
              setProducts={setProductsBasic}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
            />
          </div>
          <div className={`${show.api}`}>
            <Api
              products={productsApi}
              purchasedProducts={purchasedProducts}
              runCheckout={runCheckout}
              cartCount={cartCount}
              discountedCount={discountedCount}
              setProducts={setProductsApi}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
            />
          </div>

      </div>

    </div>
  );
}
