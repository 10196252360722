import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import Spinner from "../Spinner";

export default function PaymentModal(props) {
  return (
    <Modal
      size="md"
      name='paymentModal'
      show={props.modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={props.closeHandler}
      centered
      contentClassName='rounded-modal border-blue'
    >
      <Modal.Body className='m-3'>
        <div className='row'>
          <div className='col-12 text-center'>
            <img width={'50px'} src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/small-logo.webp`} alt={'Krikey AI logo'}/>
          </div>
          <div className='col-12 text-center'>
            <p className={'pt-2 font-bold'} style={{fontSize: '2em'}}>Transaction</p>
          </div>
          <div className='col-12 text-center'>
            <p className={'m-0 pb-4'} style={{fontSize: '1.5em'}}>
              You will be charged <strong>{props.cost > 1 ? `${props.cost} credits` : `${props.cost} credit`}</strong> for your generated assets
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col text-center'>
            <button className='btn-blue w-100 text-white btn-size-md' type='submit' onClick={props.submitHandler}>
              <strong>Payment</strong>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
