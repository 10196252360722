import {Modal} from "react-bootstrap";
import React from "react";

export default function RedirectModalWithButton(props) {
  return (
    <Modal
      size="md"
      name='redirectModalWithButton'
      show={props.modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={props.closeHandler}
      centered
      contentClassName='rounded-modal border-blue'
    >
      <Modal.Header className={'border-0 pb-0'} closeButton={true}/>
      <Modal.Body className='m-1 py-2'>
        <div className='row'>
          <div className='col-12 text-center'>
            <img width={'50px'} src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/small-logo.webp`} alt={'Krikey AI logo'}/>
          </div>
          <div className='col-12 text-center'>
            <p className={'m-0 py-4'} style={{fontSize: '1.5em'}}>
              {props.description}
            </p>
          </div>
          <div className='col-12 text-center'>
            <button className={'btn-normal btn-size-lg text-white font-normal'} onClick={() => window.location.href = props.redirect}>{props.redirectDescription}</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
