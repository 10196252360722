import {Modal} from "react-bootstrap";
import React from "react";
import {BsCloudDownloadFill} from "react-icons/all";
import ProgressBar from "react-bootstrap/ProgressBar";

export default function ProcessingModal(props) {
  return (
    <Modal
      size="md"
      name='processingModal'
      show={props.modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName='rounded-modal border-blue'
    >
      <Modal.Body className='m-3'>
        <div className='row'>
          <div className='col-12 text-center'>
            <BsCloudDownloadFill size={50}/>
          </div>
          <div className='col-12 text-center'>
            <p className={'pt-2 font-bold'} style={{fontSize: '2em'}}>Downloading... {props.percentage}%</p>
          </div>

          <div className={'col-12 text-center'}>
            <ProgressBar animated now={props.percentage}/>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
