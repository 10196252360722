import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

export default function EditingAnimations(props) {
  return (
    <div>
      <header className="">
        <Nav location="usecases"/>
      </header>

      <div className="container text-center text-lg-start">
        <div className="row p-5">
          <div className="col-12">
            <h1 className="fw-bolder text-center">Editing AI Animations</h1>
          </div>
          <div className="col-12 p-1 p-lg-5">
            <p className="fs-3 text-secondary pb-2">It's easy to polish and customize our high fidelity animations in the tool of your choice.</p>
            <div className="row border-heavy-left-green bg-color-gray rounded-5 py-4 pe-4">
              <div className="col-1 text-end" style={{paddingRight:'0rem'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-circle color-green" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                </svg>
              </div>
              <div className="col-11 fs-6 text-start">
                <div className="pb-2">
                  To begin using Krikey's tools, please visit krikey.ai. You can sign up for an account and will receive 5 free credits to start. These can be used towards asset generation.
                </div>
                <div className="pb-2">
                  Our SDKs & APIs can also be accessed via krikey.ai. There is a 7-day free trial for all Krikey tools.
                </div>
                <div>
                  Questions? Please email us at support@krikey.com!
                </div>
              </div>
            </div>
          
            <h3 className="fw-bolder pt-5">Importing Assets</h3>
            <p className="fs-5">Once you have generated your animations, they can easily be taken into any 3D engine or editing tool.</p>
            <div className="fs-6 ps-3">
              <div className="form-check py-1">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled />
                You can take the rig into a 3D software to edit the animation curves.
              </div>
              <div className="form-check py-1">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" disabled />
                You can import just the rig and attach the rig a new FBX file for another humanoid avatar. 
              </div>
              <div className="form-check py-1">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" disabled />
                You can import both the Krikey avatar & rig to a video editing software and make content right away. 
              </div>
            </div>

            <h3 className="fw-bolder py-3">Editing Animation Curves</h3>
            <p className="fs-5 py-1">Sometimes minor asset adjustments can be made to enhance the quality of the generative asset. These can be done using standard 3D modeling & design software, or directly within a 3D authoring engine. </p>
   
            <h3 className="fw-bolder py-3">Example: Adjusting the Throw Ball Animation</h3>
            <img className="img-fluid" src="https://cdn.krikeyapp.com/krikey.xyz/images/use-cases/ThrowBall.gif" />
            <p className="text-center text-secondary">Animation generated from "Throw Ball" prompt on the Krikey AI Animations tool. </p>
            <p className="fs-5 pt-5">While the original throw ball animation was high fidelity, two minor changes could be made to the animation:</p>

            <ul class="list-group fs-5 ps-3 ps-lg-5">
              <li class="p-1">The original animation file was edited by changing the values for the Right Shoulder Joint's 'Rotate Z'</li>
              <li class="p-1">This process was done Manually using the Graph Editor tool in Maya by first selecting the keyed values on the Right Shoulder Joint's 'Rotate Z' and changing the value to avoid the shoulder clipping with the upper torso in the animation.</li>
            </ul>

            <img className="img-fluid py-3" src="https://cdn.krikeyapp.com/krikey.xyz/images/use-cases/AnimationFix.gif" />
            <p className="fs-5 py-3">The updated animation can be seen below. Krikey's tools support artists in delivering their best work more quickly for production-ready 3D games and experiences.</p>

            <img className="img-fluid" src="https://cdn.krikeyapp.com/krikey.xyz/images/use-cases/ThrowBall_Update_02.gif" />
            <p className="text-center text-secondary">Final Throw Ball Animation </p>

            <h3 className="fw-bolder pt-5">Importing Rigs onto New Avatars</h3>
            <p className="fs-5">You can also import the rig into a 3D authoring tool and place it on a new avatar. Below, we show how a single animation - generated from the prompt "a person kicks the air" can be added onto multiple human characters.</p>
            <p className="fs-5">The animations from our AI tool can be downloaded and attached to any other human character FBX file.</p>

            <div className="row">
              <div className="col-6">
                <img className="img-fluid" src="https://cdn.krikeyapp.com/krikey.xyz/images/use-cases/KickingAvatar1.gif" />
                <p className="text-center text-secondary">Krikey AI Kicking Animation on Avatar 1.</p>
              </div>
              <div className="col-6">
                <img className="img-fluid" src="https://cdn.krikeyapp.com/krikey.xyz/images/use-cases/KickingAvatar2.gif" />
                <p className="text-center text-secondary">Krikey AI Kicking Animation on Avatar 2.</p>
              </div>
            </div>

            <div className="py-3">
              <div class="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/EqfLPVcODMY" title="YouTube video" allowfullscreen></iframe>
              </div>
              <p className="text-center text-secondary">Transferring a Krikey AI Animation between avatars. </p>
            </div>

            <h3 className="fw-bolder pt-5">Creating Content</h3>
            <p className="fs-5">The character FBX and animation can also be downloaded together and taken into video editing software. Content can be made right away with these characters. Videos can be made with the following steps:</p>
            <div className="fs-6 ps-3 py-3">
              <div className="form-check py-1">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled />
                Download avatar video from Krikey AI tool using greenscreen. Uncheck the shadow option.
              </div>
              <div className="form-check py-1">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" disabled />
                Import the video into video editing tool of choice. 
              </div>
              <div className="form-check py-1">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" disabled />
                Remove the greenscreen background. Only the avatar and animation should remain, now with a transparent background.
              </div>
              <div className="form-check py-1">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" disabled />
                Add the avatars to videos as you desire. Export the final product when ready and distribute.
              </div>
            </div>

            <div className="py-3">
              <div class="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/H-zmogTemNo" title="YouTube video" allowfullscreen></iframe>
              </div>
              <p className="text-center text-secondary">Transferring Krikey AI Animations into Canva to produce content.</p>
            </div>

            <div className="py-3">
              <div class="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/LoYMDZgpGTE" title="YouTube video" allowfullscreen></iframe>
              </div>
              <p className="text-center text-secondary">Transferring Krikey AI Animations into Final Cut Pro to produce content. </p>
            </div>

          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
