import axios from "axios";

export const getWebGLPath = async (gameId) => {
  return await axios.get(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/builds/${gameId}`,
    {withCredentials: true}).then((res) => {
    return res
  }).catch((err) => {
    return err.response.data;
  });
}
