import React, {useEffect, useState} from "react";
import {useAuth} from "./OnFlow/AuthContext";
import * as fcl from "@onflow/fcl";
import {RiLogoutCircleRLine} from "react-icons/all";
import discovery from "./OnFlow/discovery.json";
import {config} from "@onflow/fcl";
import axios from "axios";
import SetupModal from "./Modals/SetupModal";
import {SetupAccountTx} from "../cadence/transactions/account/SetupAccount";
import {useGoogleLogin} from '@react-oauth/google';
import CongratsModal from "./Modals/CongratsModal";
import LoginConfirmModal from "./Modals/LoginConfirmModal";

require('dotenv').config();

const TRANSACTION_FAIL_ERROR = 'Oops! Looks like your sign up didn\'t go through. Please click the button below to finish signing up.';
const INITIALIZED_UPDATE_ERROR = 'Oops! Looks like your sign up didn\'t go through. Please click the button below to finish signing up.';
const TRANSACTION_CLOSE_ERROR = 'Oops! Looks like your sign up didn\'t go through. Please click the connect wallet button to sign up.';

export const getUserSession = () => localStorage.getItem('user');
export const isDeveloper = () => localStorage.getItem('isDeveloperMode');
export const GetUserBalance = async () => {
  return await axios.get(process.env.REACT_APP_API_ADDRESS + `/accounts/${process.env.REACT_APP_ACCOUNT_ID}/balance`,
    {withCredentials: true,}).then(res => {
    return res.data
  }).catch(err => {
    return err;
  });
}

export default function Login(props) {
  const {currentUser, authenticate, logOut, getFCL} = useAuth();
  const [wid, setWid] = useState('');
  const [, setWidProvider] = useState('');

  const [walletProviders,] = useState({});

  const [setupProcessing, setSetupProcessing] = useState(false);

  const [showSetupModal, setShowSetupModal] = useState(false);
  const [showSetupModalError, setShowSetupModalError] = useState(false);
  const [setupModalError, setSetupModalError] = useState(TRANSACTION_CLOSE_ERROR);

  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState({});

  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [showLoginConfirmModal, setShowLoginConfirmModal] = useState(false);

  useEffect(() => {
    if (props.openAuth) {
      setShowLoginConfirmModal(true);
    }
  }, [props.openAuth]);

  useEffect(() => {
    setIsLogin(false);
    const userObj = JSON.parse(getUserSession()) ?? '';
    if (userObj) {
      setIsLogin(true);
      setUser(userObj);
      const {wid, wid_provider, is_initialized} = userObj;
      setWidProvider(wid_provider);
      if (!is_initialized && wid) {
        setWid(wid);
        setShowSetupModal(true);
      }
    }
  }, []);

  useEffect(() => {
    const providerResults = discovery[process.env.REACT_APP_ONFLOW_NETWORK];
    for (const provider of providerResults) {
      const id = provider.provider.address;
      walletProviders[id] = provider;
    }
  }, []);

  const AuthenticateRequest = async (wid, chain_id) => {
    return await axios.post(process.env.REACT_APP_API_ADDRESS + `/accounts/${process.env.REACT_APP_ACCOUNT_ID}/authenticate/request`, {
      wid,
      provider: 'wallet',
      platform: 'web',
      chain_id,
    }, {withCredentials: true,})
      .then(function (res) {
        return res;
      })
      .catch(function (err) {
        return err.response.data;
      });
  }

  const Authenticate = async (wid, message, signature, key_id, chain_id) => {
    return await axios.post(process.env.REACT_APP_API_ADDRESS + `/accounts/${process.env.REACT_APP_ACCOUNT_ID}/authenticate`, {
      wid,
      provider: 'wallet',
      platform: 'web',
      message,
      signature,
      key_id,
      chain_id,
      cookie_domain: '.krikey.ai',
    }, {withCredentials: true})
      .then(function (res) {
        return res;
      })
      .catch(function (err) {
        return err.response.data;
      });
  }

  const UserSettings = async (chain_id) => {
    return await axios.get(process.env.REACT_APP_API_ADDRESS + `/accounts/${process.env.REACT_APP_ACCOUNT_ID}/user-settings/${chain_id}/${process.env.REACT_APP_NFT_NAME}`, {withCredentials: true})
      .then(function (res) {
        return res;
      })
      .catch(function (err) {
        return err.response.data;
      });
  }

  const AuthenticateFlow = async (walletProviderAddress = process.env.REACT_APP_BLOCTO_ADDRESS) => {
    try {
      const provider = walletProviders[walletProviderAddress];
      if (walletProviderAddress === process.env.REACT_APP_BLOCTO_ADDRESS) {
        config().put('discovery.wallet', process.env.REACT_APP_BLOCTO_DISCOVERY_WALLET);
        config().put('discovery.wallet.method', 'HTTP/POST');
        provider.method = 'HTTP/POST'
      }
      const flowResult = await authenticate(provider);
      const chain_id = process.env.REACT_APP_ONFLOW_NETWORK === 'mainnet' ? 90001 : 90002;
      if (flowResult.addr) {
        const authRequestResult = await AuthenticateRequest(flowResult.addr, chain_id);
        if (authRequestResult.status === 200) {
          const {message} = authRequestResult.data;
          const signUserMessage = await fcl.currentUser.signUserMessage(message);
          const {addr, signature, keyId} = signUserMessage[0];
          const authResult = await Authenticate(addr, message, signature, keyId, chain_id);
          if (authResult.status === 200) {
            const {user} = authResult.data;
            const {id, is_new_user} = user;
            const userSettingsResult = await UserSettings(chain_id);
            if (userSettingsResult.status === 200) {
              const {is_initialized} = userSettingsResult.data;
              SetUserSession(id, addr, 'blocto', is_new_user, is_initialized, chain_id);
              if (!is_initialized) {
                setShowSetupModal(true);
              } else {
                window.location.reload();
              }
            }
          }
        } else {
          console.log('Authenticate Request Error');
        }
      }
    } catch (e) {
    }
  }


  const SetUserSession = (email, user_id, wid, wid_provider, is_new_user, is_initialized, chain_id) => {
    const currentDate = new Date();
    const twelveHours = 12 * 60;
    currentDate.setMinutes(currentDate.getMinutes() + twelveHours); // switched to minutes so easier for testing
    localStorage.setItem('user', JSON.stringify({
      email,
      user_id,
      wid,
      wid_provider,
      is_new_user,
      is_initialized,
      chain_id,
      expire_date: currentDate,
    }));
  }

  const SetupAccountTX = async () => {
    const result = {isSuccess: false, transaction_id: '0000', status_code: 0, err_description: ''};

    try {
      result.transaction_id = await getFCL().send([
        fcl.transaction(SetupAccountTx),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz]),
        fcl.limit(9999)
      ]).then(fcl.decode);
    } catch (e) {
      result.err_description = TRANSACTION_CLOSE_ERROR;
      return result;
    }

    console.log('Setup TX id:', result.transaction_id);
    try {
      const transaction_result = await getFCL().tx(result.transaction_id).onceSealed();
      result.status_code = transaction_result.statusCode;
      if (transaction_result.errorMessage) {
        result.err_description = TRANSACTION_FAIL_ERROR;
        return result;
      }
      result.isSuccess = true;
    } catch (e) {
      result.err_description = TRANSACTION_FAIL_ERROR;
      result.status_code = await getFCL().send([fcl.getTransactionStatus(result.transaction_id)]).then(fcl.decode);
      return result;
    }
    return result;
  }

  const UpdateUserSettings = async (chain_id) => {
    return await axios.post(process.env.REACT_APP_API_ADDRESS + `/accounts/${process.env.REACT_APP_ACCOUNT_ID}/user-settings`, {
      is_initialized: true,
      contract_name: process.env.REACT_APP_NFT_NAME,
      chain_id,
    }, {withCredentials: true})
      .then(function (res) {
        return res;
      })
      .catch(function (err) {
        return err.response.data;
      });
  }

  const SubmitSetupModal = async () => {
    setSetupProcessing(true);
    setShowSetupModalError(false);
    const result = await SetupAccountTX();
    if (result.isSuccess) {
      const user = JSON.parse(getUserSession());
      const {user_id, wid, wid_provider, is_new_user, chain_id} = user;
      const updateResult = await UpdateUserSettings(chain_id);
      if (updateResult.status === 200) {
        SetUserSession(user_id, wid, wid_provider, is_new_user, true, chain_id);
        setSetupProcessing(false);
        CloseSetupModal();
        window.location.reload();
      } else if (updateResult.status === 409) {
        SetUserSession(user_id, wid, wid_provider, is_new_user, true, chain_id);
        setSetupProcessing(false);
        CloseSetupModal();
      } else {
        setShowSetupModalError(true);
        setSetupModalError(INITIALIZED_UPDATE_ERROR);
        setSetupProcessing(false);
      }
    } else {
      //TODO Throw the error for updating user's wid
      setShowSetupModalError(true);
      setSetupModalError(result.err_description);
      setSetupProcessing(false);
    }
  }

  const CloseSetupModal = () => {
    if (!setupProcessing) setShowSetupModal(false);
  }

  const CloseLoginConfirmModal = () => {
    setShowLoginConfirmModal(false);
  }

  const GoogleAuthenticate = async (payload) => {
    return await axios.post(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/authenticate`, payload, {withCredentials: true})
      .then(function (res) {
        return res;
      })
      .catch(function (err) {
        return err.response.data;
      });
  }

  const GoogleLogin = useGoogleLogin({
    flow: 'implicit',
    scope: 'profile',
    onSuccess: async (googleResult) => {
      const payload = {
        google_user_token: googleResult.access_token,
        platform: 'web',
        provider: 'google',
        cookie_domain: '.krikey.ai',
      };
      const authResult = await GoogleAuthenticate(payload);
      if (authResult.status === 200) {
        const {data} = authResult;
        const {email, id, is_new_user} = data.user;
        SetUserSession(email, id, '', '', is_new_user, false, '');
        if (is_new_user === true) {
          setShowCongratsModal(true);
        } else {
          window.location.href = '/product/ai';
        }
      }
    },
  });

  const CloseCongratsModal = () => {
    setShowCongratsModal(false);
    window.location.href = '/';
  }

  const UnauthenticatedState = () => {
    return (
      <span>
        <button className={'btn-normal btn-size-md text-white font-normal'} onClick={() => setShowLoginConfirmModal(true)}>Get Started</button>
      </span>
    );
  };

  const AuthedState = () => {
    return (
      <div>
        {/*
          MOBILE MODE
        */}
        <ul className="d-block d-lg-none text-white">
          <div>
            <a className="dropdown-item" href="#" onClick={logOut}>
              <h5 className="fw-bolder font-white">LOGOUT <span className="float-end"><RiLogoutCircleRLine size={'1.4rem'}/></span></h5>
            </a>
          </div>
        </ul>

        {/*
          DESKTOP MODE
        */}
        <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <button className="nav-link circle-profile" id="navbarDarkDropdownMenuLink dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className={'text-center text-uppercase text-white'}>{user.email[0]}</div>
              </button>
              <div className="dropdown-menu dropdown-menu-dark dropdown-menu-right" aria-labelledby="navbarDarkDropdownMenuLink"
                   style={{backgroundColor: '#111111', opacity: '0.90'}}>
                <button className="dropdown-item" onClick={logOut}>
                  <h5 className="fw-bolder font-white">LOGOUT <span className="float-end"><RiLogoutCircleRLine size={'1.4rem'}/></span></h5>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <span>
      <span>
        {isLogin ? <AuthedState/> : <UnauthenticatedState/>}
      </span>
      <LoginConfirmModal modalShow={showLoginConfirmModal} closeHandler={CloseLoginConfirmModal} submitHandler={GoogleLogin}/>
      <CongratsModal modalShow={showCongratsModal} closeHandler={CloseCongratsModal}/>
      <SetupModal modalShow={showSetupModal} closeHandler={CloseSetupModal}
                  setupProcessing={setupProcessing} submitHandler={SubmitSetupModal}
                  showModalError={showSetupModalError} modalError={setupModalError}/>
    </span>
  )
}
