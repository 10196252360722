import Grid from "./Grid";

export default function PlanGridYearly(props) {
	return (
    <div className="text-center color-fancy-gray d-none d-sm-block">
      <div className="row">
        <div className="col-2 p-5"></div>
        <div className="col-2 p-5"></div>
        <div className="col-2 p-5 bg-color-gray border-bottom border-end" style={{borderTopLeftRadius: '0.5rem'}}>
          <div className="text-dark">Standard</div>
          <div>Annual, paid monthly</div>
          <div className="text-dark fw-bold">Starting at $199.99</div>
        </div>
        <div className="col-2 p-5 bg-color-gray border-bottom border-end">
          <div className="text-dark">Advanced</div>
          <div>Annual, paid monthly</div>
          <div className="text-dark fw-bold">Starting at $299.99</div>
        </div>
        <div className="col-2 p-5 bg-color-gray border-bottom border-end" style={{borderTopRightRadius: '0.5rem'}}>
          <div className="text-dark">Enterprise</div>
          <div>Annual, paid monthly</div>
          <div className="text-dark fw-bold">Starting at $499.99</div>
        </div>
        <div className="col-2 p-5"></div>
      </div>

      <Grid />

    </div>
  );
}