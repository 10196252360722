import Nav from './components/Nav';
import Footer from './components/Footer';
import './index.css';
import './assets/css/home.css';
import './assets/css/flip-countdown.css';
import React, {useEffect, useState} from "react";
import {getUserSession} from "./components/Login";

import {Unity, useUnityContext} from 'react-unity-webgl';
import MobileErrorModal from "./components/Modals/MobileErrorModal";
import axios from "axios";
import PaymentModal from "./components/Modals/PaymentModal";
import PurchaseCreditsModal from "./components/Modals/PurchaseCreditsModal";
import CreditButton from "./components/CreditButton";
import RedirectModalWithButtons from "./components/Modals/RedirectModalWithButtons";
import {getWebGLPath} from "./utils/WebGLHandler";

const WEBGL_PATH = '2023.02.28';
const GAME_ID = '873ef149-0a3c-4485-b35f-1e87312e542a'
export default function AvatarCreator() {
  const {unityProvider, initialisationError, isLoaded, loadingProgression, addEventListener, sendMessage} = useUnityContext({
    loaderUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${WEBGL_PATH}/Build/AvatarCreator.loader.js?v=1.0.2`,
    dataUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${WEBGL_PATH}/Build/AvatarCreator.data?v=1.0.2`,
    frameworkUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${WEBGL_PATH}/Build/AvatarCreator.framework.js?v=1.0.2`,
    codeUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${WEBGL_PATH}/Build/AvatarCreator.wasm?v=1.0.2`,
    streamingAssetsUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${WEBGL_PATH}/StreamingAssets`,
  });

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentModalError, setPaymentModalError] = useState({isShow: false, description: ''});
  const [showPurchaseCreditsModal, setShowPurchaseCreditsModal] = useState(false);
  const [showMobileErrorModal, setShowMobileErrorModal] = useState(false);
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [refreshCredit, setRefreshCredit] = useState(false);

  const [saveAvatar, setSaveAvatar] = useState({type: '', data: '', user: ''});

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  const [openAuth, setOpenAuth] = useState(false);
  const loadingPercentage = Math.round(loadingProgression * 100);

  const [webglPath, setWebglPath] = useState('');

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    if (getUserSession()) {
      if (isMobile) {
        setShowMobileErrorModal(true);
      }
    }

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, value) => searchParams.get(value),
    });

    if (params.unityPath) {
      unityProvider.unityConfig = {
        loaderUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${params.unityPath}/Build/AvatarCreator.loader.js?v=1.0.2`,
        dataUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${params.unityPath}/Build/AvatarCreator.data?v=1.0.2`,
        frameworkUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${params.unityPath}/Build/AvatarCreator.framework.js?v=1.0.2`,
        codeUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${params.unityPath}/Build/AvatarCreator.wasm?v=1.0.2`,
        streamingAssetsUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${params.unityPath}/StreamingAssets`,
      }
    } else {
      getWebGLPath(GAME_ID).then((result) => {
        if (result.status === 200) {
          const {location} = result.data;
          let path = WEBGL_PATH;
          if (location) {
            path = location;
            setWebglPath(location);
          } else {
            setWebglPath(WEBGL_PATH);
          }
          unityProvider.unityConfig = {
            loaderUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${path}/Build/AvatarCreator.loader.js?v=1.0.2`,
            dataUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${path}/Build/AvatarCreator.data?v=1.0.2`,
            frameworkUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${path}/Build/AvatarCreator.framework.js?v=1.0.2`,
            codeUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${path}/Build/AvatarCreator.wasm?v=1.0.2`,
            streamingAssetsUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AvatarCreator/${path}/StreamingAssets`,
          }
        }
      });
    }

  }, [])

  const initializeResult = (type) => {
    return {type: `Result${type}`, data: {}, code: 200, status: '', message: ''};
  }

  const BrowserCommunication = (result) => {
    console.log('FE:', result);
    const string_result = {
      type: result.type,
      data: JSON.stringify(result.data),
      code: result?.code,
      result: result?.status,
      message: result?.message,
    };
    console.log('FE -> Unity:', JSON.stringify(string_result));
    sendMessage('BrowserCommunication', 'SendToUnity', JSON.stringify(string_result));
  }

  const RequestSaveAvatar = async (avatar) => {
    return await axios.post(process.env.REACT_APP_API_ADDRESS + `/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-animation-avatars`,
      {content: avatar.data.recipe},
      {withCredentials: true})
      .then(function (res) {
        return res;
      })
      .catch(function (err) {
        return err.response.data;
      });
  }

  useEffect(() => {
    if (isLoaded) {
      console.log('initialisationError', initialisationError ? initialisationError : 'No issue');
      addEventListener('Communication', async function (result) {
        const payload = JSON.parse(result);
        payload.data = JSON.parse(payload.data);
        const type = payload.type;
        const data = payload.data;
        const user = JSON.parse(getUserSession());
        switch (type) {
          case 'SaveAvatar':
            if (getUserSession()) {
              setSaveAvatar({type, data, user});
              await SubmitPaymentModal({type, data, user});
            } else {
              setOpenAuth(true);
            }
            break;
        }
      });
    }
  }, [isLoaded]);

  const SubmitPaymentModal = async (avatar) => {
    setShowPaymentModal(false);
    const result = initializeResult(avatar.type);
    const response = await RequestSaveAvatar(avatar);
    if (response.status === 200) {
      BrowserCommunication(result);
      setShowRedirectModal(true);
    } else {
      alert(response);
    }
  }

  const CloseMobileErrorModal = () => {
    setShowMobileErrorModal(false);
  }

  const ClosePaymentModal = () => {
    setSaveAvatar({type: '', data: '', user: ''});
    setShowPaymentModal(false);
  }

  const ClosePurchaseCreditsModal = () => {
    setShowPurchaseCreditsModal(false);
  }

  const CloseRedirectModal = () => {
    setShowRedirectModal(false);
  }

  return (
    <div className="bg-cover-solar-pups">
      <header>
        <Nav location="avatar-creator" openAuth={openAuth}/>
      </header>

      <MobileErrorModal modalShow={showMobileErrorModal} closeHandler={CloseMobileErrorModal}/>
      <PurchaseCreditsModal modalShow={showPurchaseCreditsModal} closeHandler={ClosePurchaseCreditsModal} redirect={'avatar-creator'}/>
      <PaymentModal modalShow={showPaymentModal} error={paymentModalError} closeHandler={ClosePaymentModal} cost={2} submitHandler={SubmitPaymentModal}/>
      <RedirectModalWithButtons modalShow={showRedirectModal} closeHandler={CloseRedirectModal} redirect={'/avatar-creator'} noRedirect={'/'} yesRedirect={'/'}/>

      <div className='container-fluid py-4 px-3 bg-black'>
        <div className='container-fluid py-4 px-3 bg-black' style={{minHeight: '9vh'}}/>

        <CreditButton refreshCredit={refreshCredit} setRefreshCredit={setRefreshCredit}/>

        <div className={'row w-100 justify-content-center align-self-center'}>
          <div className={'col-10 my-3 py-2'}>
            {isLoaded === false && (
              <div className="text-white">
                <p>Loading... ({loadingPercentage}%)</p>
              </div>
            )}
            {webglPath !== '' && <Unity tabIndex='1' unityProvider={unityProvider} style={{width: '100%'}}/>}
          </div>
        </div>

        <div className='container-fluid py-4 px-3 bg-black' style={{minHeight: '9vh'}}/>
      </div>
      <Footer/>
    </div>
  );
}
