import {useEffect, useState} from "react";
import {searchProducts} from "../../utils/Stripe";
import BannerBuy from "./BannerBuy";
import BannerCart from "./BannerCart";
import ShopCard from "./ShopCard";

export default function Combined(props) {
  useEffect(() => {
    const loadProducts = async () => {
      const plan = JSON.parse(localStorage.getItem('plan'));
      const basicProducts = await searchProducts("Basic SDK: ");
      const advancedProducts = await searchProducts("Premium SDK: ");
      const products = [...basicProducts, ...advancedProducts];

      let discountedCount = 0;
      let purchasedIds = props.purchasedProducts.map((purchased)=> purchased.id);
      for (let product of products) {
        product.prices = product.prices.filter((price) => price.recurring.interval === plan.interval); // remove prices that don't match interval
        product.prices = product.prices.sort((price1, price2) => price1.unit_amount > price2.unit_amount);
        product.alreadyPurchased = purchasedIds.includes(product.id);
        if (product.alreadyPurchased) {
          discountedCount++;
        }
      }
      if (discountedCount > 0) {
        props.setDiscountedCount(discountedCount);
      }

      console.log('>> combined products', products);
      props.setProducts(products);
    }
    loadProducts();
  }, [props.purchasedProducts]);

	return (
    <div className="row">
      <div className="col-0 col-md-2"></div>
      <div className="col-12 col-md-10">
        <BannerCart title="" cartCount={props.cartCount} />
        <h6 className="pt-3 px-3">Basic SDKs</h6>
        <div className="row">
          {props.products.slice(0,2).map((product) =>
            <ShopCard
              key={product.id}
              image={product.image}
              title={product.name.replace("Basic SDK:","")}
              description={product.description}
              prices={product.prices}
              addToCart={props.addToCart}
              removeFromCart={props.removeFromCart}
              discountedCount={props.discountedCount}
              isDiscountable={true}
              alreadyPurchased={product.alreadyPurchased}
            />
          )}
        </div>

        <h6 className="pt-0 px-3">Premium SDKs</h6>
        <div className="row">
          {props.products.slice(2,4).map((product) =>
            <ShopCard
              key={product.id}
              image={product.image}
              title={product.name.replace("Premium SDK:","")}
              description={product.description}
              prices={product.prices}
              addToCart={props.addToCart}
              removeFromCart={props.removeFromCart}
              discountedCount={props.discountedCount}
              isDiscountable={true}
              alreadyPurchased={product.alreadyPurchased}
            />
          )}
        </div>
      </div>
      <BannerBuy runCheckout={props.runCheckout} />
    </div>
  );
}
