import {useEffect, useState} from "react";
import axios from "axios";
import NavSDK from './Nav/NavSDK';
import NavDashboard from './Nav/NavDashboard';
import '../assets/css/dashboard.css';

export default function DashboardSDK(props) {

  return (
    <div>
      
      <NavDashboard location="sdk" />

      <div className="container-fluid">
        <div className="row">

          <NavSDK />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4" style={{backgroundColor:'#f4f8fe', minHeight:'75rem'}}>
            <div className="row py-3">
              <div className="col-12">
                <h3 className="fw-bolder" style={{color:'#6f7191'}}>PROJECTS PLACEHOLDER</h3>
              </div>
            </div>
            <div className="row py-3">
              
            </div>
          </main>

        </div>
      </div>

    </div>
  );
}
