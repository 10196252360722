import {useEffect, useState} from "react";

export default function ShopCard(props) {
  const [priceObj, setPriceObj] = useState({});
  const [price, setPrice] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [showPrice, setShowPrice] = useState('d-none');
  const [showPurchased, setShowPurchased] = useState('d-none');

  useEffect(() => {
    const plan = JSON.parse(localStorage.getItem('plan'));
    let discountAllowed = 1;
    if (plan.type === 'advanced') {
      discountAllowed = 2;
    }

    let selectedPrice = "";
    if (props.discountedCount >= discountAllowed && props.prices.length > 1) {
      selectedPrice = props.prices.at(1);
    } else {
      selectedPrice = props.prices.at(0);
    }

    if (!isChecked) { // only allow price to be reset if the checkbox is not selected
      let amount = selectedPrice.unit_amount?? 0;
      let price = amount / 100;
      setPriceObj(selectedPrice);
      setPrice(price);
    }

  }, [props.discountedCount]);

  useEffect(() => {
    if (props.alreadyPurchased) {
      setShowPrice('d-none');
      setShowPurchased('');
    } else {
      setShowPrice('');
      setShowPurchased('d-none');
    }

  }, [props.alreadyPurchased]);

	return (
    <div className="col-12 col-lg-4 py-3" key={props.id}>
      <div className="p-3 rounded shadow-sm shop-card" style={{minHeight:'16.5rem'}}>
        <div className="row px-1">
          <div className="col-2">
          <img className="img-fluid" src={props.image} />
          </div>
          <div className="col-9">
            <span className="fw-bold ps-1" style={{fontSize:'1.1rem'}}>
              {props.title}<sup className={`p-1 ${props.hideSDK}`} style={{fontSize:'0.7rem'}}>SDK</sup>
            </span>
          </div>
          <div className="col-1 text-end" style={{width:'1%'}}>

            <input className={`form-check-input ${showPrice}`} type="checkbox" value="" style={{cursor: 'pointer'}}
              onChange={(e) => {
                if (e.target.checked) {
                  setIsChecked(true);
                  props.addToCart(priceObj, props.isDiscountable);
                } else {
                  setIsChecked(false);
                  props.removeFromCart(priceObj, props.isDiscountable);
                }
              }}
              />

            <input className={`form-check-input ${showPurchased}`} type="checkbox" value="" checked disabled />
          </div>
        </div>
        <div className="text-end">

          <button type="button" className={`btn btn-outline-secondary ${showPrice}`} disabled>
            ${price}
          </button>
          <button type="button" className={`btn btn-outline-secondary ${showPurchased}`} disabled>
            PURCHASED
          </button>

        </div>
        <div className="pt-3">
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
}