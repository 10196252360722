import React from 'react';
import ReactDOM from "react-dom/client";
import App from "./App";
import AuthProvider from './components/OnFlow/AuthContext';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <AuthProvider>
    <App/>
  </AuthProvider>
);
