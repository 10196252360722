import Nav from './components/Nav';
import Footer from './components/Footer';
import './index.css';
import './assets/css/home.css';
import './assets/css/flip-countdown.css';
import React, {useEffect, useState} from "react";

import CreditButton from "./components/CreditButton";
import AvatarSaveModal from "./components/Modals/AvatarSaveModal";
import {getUserSession} from "./components/Login";
import axios from "axios";

export default function ReadyPlayerMe() {
  const [openAuth, setOpenAuth] = useState(false);
  const [refreshCredit, setRefreshCredit] = useState(false);
  const [showAvatarSaveModal, setShowAvatarSaveModal] = useState(false);

  const [avatarUrl, setAvatarUrl] = useState('');

  const ParseEvent = (event) => {
    try {
      return JSON.parse(event.data);
    } catch (e) {
      return null;
    }
  }

  const messageHandler = (event) => {
    const json = ParseEvent(event);
    if (json === null || json?.source !== 'readyplayerme') {
      return;
    }

    // Susbribe to all events sent from Ready Player Me once frame is ready
    if (json.eventName === 'v1.frame.ready') {
      const readyPlayerMeCreator = document.getElementById('readyPlayerMeCreator');
      readyPlayerMeCreator.contentWindow.postMessage(
        JSON.stringify({
          target: 'readyplayerme',
          type: 'subscribe',
          eventName: 'v1.**'
        }),
        '*'
      );
    }

    // Get avatar GLB URL
    if (json.eventName === 'v1.avatar.exported') {
      const {url} = json.data;
      setAvatarUrl(url);
      setShowAvatarSaveModal(true);
    }

    // Get user id
    if (json.eventName === 'v1.user.set') {
      console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
    }
  }

  useEffect(() => {
    if (getUserSession()) {
      window.addEventListener('message', messageHandler);
      return () => window.removeEventListener('message', messageHandler);
    } else {
      setOpenAuth(true);
    }
  }, []);

  const CloseAvatarSaveModal = () => {
    setShowAvatarSaveModal(false);
  }

  const SaveAvatarRequest = async () => {
    return await axios.post(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-assets/readyplayerme`, {avatar_url: avatarUrl}, {withCredentials: true})
      .then((res) => res)
      .catch((err) => err.response.data);
  }

  const SaveAvatarModal = async () => {
    setShowAvatarSaveModal(false);
    const saveResult = await SaveAvatarRequest();
    if (saveResult.status === 200) {
      window.location.href = '/';
    } else {
      alert('Something wrong with saving avatar');
    }
  }

  return (
    <div className="bg-cover-solar-pups">
      <header>
        <Nav location="readyplayerme" openAuth={openAuth}/>
      </header>

      <AvatarSaveModal modalShow={showAvatarSaveModal} closeHandler={CloseAvatarSaveModal} saveHandler={SaveAvatarModal}/>

      <div className='container-fluid py-4 px-3 bg-black'>
        <div className='container-fluid py-4 px-3 bg-black' style={{minHeight: '9vh'}}/>

        <CreditButton refreshCredit={refreshCredit} setRefreshCredit={setRefreshCredit}/>

        <div className={'row w-100 justify-content-center align-self-center'}>
          <div className={'col-10 my-3 py-2'}>
            {getUserSession() && <iframe
              title={'ReadyPlayerMe iframe'}
              id={'readyPlayerMeCreator'}
              className={'w-100'}
              height={900}
              allow="camera *; microphone *; clipboard-write"
              src={`https://krikeyai.readyplayer.me/avatar?frameApi`}/>}
          </div>
        </div>

        <div className='container-fluid py-4 px-3 bg-black' style={{minHeight: '9vh'}}/>
      </div>
      <Footer/>
    </div>
  );
}
