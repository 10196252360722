import Nav from "./Nav";
import Footer from "./Footer";
import '../assets/css/about.css';

export default function About(props) {
	return (
    <div style={{backgroundColor:"#fbfcfd"}}>
      <header className="">
        <Nav location="about"/>
      </header>

      <div className="container-fluid px-5" style={{backgroundColor:'#303082'}}>
        <div className="row text-center text-lg-start">
          <div className="col-12 col-lg-6 pt-10 text-white fw-bolder bg-k">
            <h6>ABOUT US</h6>
 
            <h1>Let us fuel your journey into the metaverse</h1>
            <p>Krikey is an AI Tools company whose products include an AI Text to Animation tool, custom 3D Avatar tool, AR gaming toolkit and more. The AI tool enables creators to generate 3D animations in 5 minutes that they can share to social media or export into any video editing or 3D game engine tool to edit. Krikey's AI tools are available online at www.krikey.ai today! </p>

            <div className="row text-lg-start text-center">
              <div className="col-12">
                <a href="/contact">
                  <button type="button" className="btn text-white fw-bold py-2 px-4 gradient-blue-aqua">Contact Us</button>
                </a>
              </div>
              <div className="col-0 col-lg-4">
              </div>
            </div>

          </div>
          <div className="col-12 col-lg-6 py-5">
            <img className="img-fluid p-3" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/rickshawrun.webp" />
          </div>
        </div>
      </div>

      <div className="container-fluid px-5">
        <div className="row">
          <div className="col-12 text-center">
            {/* <img className="img-fluid py-4" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/partners.webp" /> */}
            <h2 className="fw-bolder fs-1 pt-5">A Timeline of Our Journey</h2>
            <div className="row">
              <div className="col-1 col-lg-2"></div>
              <div className="col-10 col-lg-8 fs-5">
                Since 2017, we have built interactive experiences for over 5 million users. The timeline below shows some of those as well as which of our toolkits was used to build them.
                <br />
                <span className="color-blue fw-bold">What experiences will you build with these toolkits?</span>
              </div>
              <div className="col-1 col-lg-2"></div>
            </div>
            <img className="img-fluid py-4" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/years-v3.webp" />
            <h2 className="fw-bolder fs-1 pt-5">At Krikey we Value</h2>
            <img className="img-fluid p-1 p-lg-5" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/values.webp" />
          </div>
        </div>
      </div>

      <div className="container p-5 gradient-blue text-white text-center rounded-4">
        <div className="row g-2">

          <div className="col-0 col-lg-1"></div>
          <div className="col-12 col-lg-10">
            <h2 className="fs-1 fw-bolder">Highly Skilled Engineering Team</h2>
            <h6 className="fw-bolder py-2">Krikey's team brings together top talent in Augmented Reality, Artificial Intelligence and Human-Computer Interaction Design. Our team is drawn from Stanford, Harvard, UC Berkeley, Google, Meta, YouTube, Apple, Lucasfilm and other top companies.</h6>
          </div>
          <div className="col-0 col-lg-1"></div>

          <div className="col-0 col-lg-1"></div>
          <div className="col-6 col-lg-2">
            <div className="p-3 border team-cell rounded-3">
              <img className="img-fluid py-3 px-4" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/google.webp" />
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="p-3 border team-cell rounded-3">
              <img className="img-fluid py-3 px-4" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/meta.webp" />
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="p-3 border team-cell rounded-3">
              <img className="img-fluid py-3 px-4" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/youtube.webp" />
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="p-3 border team-cell rounded-3">
              <img className="img-fluid py-3 px-4" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/apple.webp" />
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="p-3 border team-cell rounded-3">
              <img className="img-fluid py-3 px-2" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/beats.webp" />
            </div>
          </div>

          <div className="col-0 col-lg-1 d-none d-sm-block"></div>
          <div className="col-0 col-lg-1 d-none d-sm-block"></div>

          <div className="col-6 col-lg-2">
            <div className="p-3 border team-cell rounded-3">
              <img className="img-fluid py-1 px-5" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/yale.webp" />
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="p-3 border team-cell rounded-3">
              <img className="img-fluid py-2 px-3" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/stanford.webp" />
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="p-3 border team-cell rounded-3">
              <img className="img-fluid py-3 px-3" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/harvard.webp" />
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="p-3 border team-cell rounded-3">
              <img className="img-fluid py-3 px-3" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/berkeley.webp" />
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="p-3 border team-cell rounded-3">
              <img className="img-fluid py-3 px-3" src="https://cdn.krikeyapp.com/krikey.xyz/images/about/penn.webp" />
            </div>
          </div>
          <div className="col-0 col-lg-1"></div>

        </div>
      </div>

      <div className="container-fluid px-5 py-5">

        {/* <div className="row g-3">
          <div className="col-12 text-center">
            <h2 className="py-5 fw-bolder">Krikey In The Press</h2>
          </div>
          <div className="col-12 col-lg-6">
            <div className="shadow-sm p-3 mb-5 bg-body rounded">
              <h3 className="fw-bolder py-5">Title</h3>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="shadow-sm p-3 mb-5 bg-body rounded">
              <h3 className="fw-bolder py-5">Title</h3>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="shadow-sm p-3 mb-5 bg-body rounded">
              <h3 className="fw-bolder py-5">Title</h3>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="shadow-sm p-3 mb-5 bg-body rounded">
              <h3 className="fw-bolder py-5">Title</h3>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="shadow-sm p-3 mb-5 bg-body rounded">
              <h3 className="fw-bolder py-5">Title</h3>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-3"></div>
          <div className="col-6 text-center">
            <h2 className="py-5 fw-bolder">Onboarding the next billion users into web3 and gaming with our partners</h2>
          </div>
          <div className="col-3"></div>

          {/* <div className="col-12 col-lg-4">
            <div className="shadow-sm p-3 mb-5 bg-body rounded">
              <p>KEYWORD</p>
              <p>"With Krikey 3D NFT Minting SDK we were able to create our own collections."</p>
              <div className="row">
                <div className="col-3">
                  <img src="https://cdn.solarpups.com/web/assets/img/solar-pups/owner-sm.png" style={{maxWidth: '3.5rem'}} />
                </div>
                <div className="col-9">
                  <h6 className="fw-bolder">Luke Hamilton</h6>
                  <h6>Software Eng, SnapChat</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="shadow-sm p-3 mb-5 bg-body rounded">
                <p>KEYWORD</p>
                <p>"With Krikey 3D NFT Minting SDK we were able to create our own collections."</p>
                <div className="row">
                  <div className="col-3">
                    <img src="https://cdn.solarpups.com/web/assets/img/solar-pups/owner-sm.png" style={{maxWidth: '3.5rem'}} />
                  </div>
                  <div className="col-9">
                    <h6 className="fw-bolder">Luke Hamilton</h6>
                    <h6>Software Eng, SnapChat</h6>
                  </div>
                </div>
              </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="shadow-sm p-3 mb-5 bg-body rounded">
              <p>KEYWORD</p>
              <p>"With Krikey 3D NFT Minting SDK we were able to create our own collections."</p>
              <div className="row">
                <div className="col-3">
                  <img src="https://cdn.solarpups.com/web/assets/img/solar-pups/owner-sm.png" style={{maxWidth: '3.5rem'}} />
                </div>
                <div className="col-9">
                  <h6 className="fw-bolder">Luke Hamilton</h6>
                  <h6>Software Eng, SnapChat</h6>
                </div>
              </div>
            </div>
          </div> */}
        </div>

      </div>

      <Footer/>
    </div>
  );
}