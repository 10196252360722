import Nav from './components/Nav';
import Footer from './components/Footer';
import './index.css';
import './assets/css/home.css';
import './assets/css/flip-countdown.css';
import React, {useEffect, useState} from "react";
import {getUserSession} from "./components/Login";
import MobileErrorModal from "./components/Modals/MobileErrorModal";
import ProcessingModal from "./components/Modals/ProcessingModal";
import {ReplaceCDNURL} from "./utils/CDN";
import {Unity, useUnityContext} from 'react-unity-webgl';
import {useParams} from "react-router-dom";
import JsFileDownloader from 'js-file-downloader';
import axios from "axios";
import {getWebGLPath} from "./utils/WebGLHandler";

const WEBGL_PATH = '2023.03.14';
const GAME_ID = '3b10c79f-9658-4b08-95c6-e99597d2c645';

export default function PlayAnimation() {
  const {unityProvider, initialisationError, isLoaded, loadingProgression, addEventListener, sendMessage} = useUnityContext({
    loaderUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${WEBGL_PATH}/Build/AnimationViewer.loader.js?v=1.0.1`,
    dataUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${WEBGL_PATH}/Build/AnimationViewer.data?v=1.0.1`,
    frameworkUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${WEBGL_PATH}/Build/AnimationViewer.framework.js?v=1.0.1`,
    codeUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${WEBGL_PATH}/Build/AnimationViewer.wasm?v=1.0.1`,
    streamingAssetsUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${WEBGL_PATH}/StreamingAssets`,
  });

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const [openAuth, setOpenAuth] = useState(false);

  const [showMobileErrorModal, setShowMobileErrorModal] = useState(false);

  const { assetId } = useParams();

  const loadingPercentage = Math.round(loadingProgression * 100);

  const [webglPath, setWebglPath] = useState('');


  useEffect(() => {
    if (getUserSession()) {
      if (isMobile) {
        setShowMobileErrorModal(true);
      }

      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, value) => searchParams.get(value),
      });

      if (params.unityPath) {
        unityProvider.unityConfig = {
          loaderUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${params.unityPath}/Build/AnimationViewer.loader.js?v=1.0.1`,
          dataUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${params.unityPath}/Build/AnimationViewer.data?v=1.0.1`,
          frameworkUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${params.unityPath}/Build/AnimationViewer.framework.js?v=1.0.1`,
          codeUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${params.unityPath}/Build/AnimationViewer.wasm?v=1.0.1`,
          streamingAssetsUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${params.unityPath}/StreamingAssets`,
        }
      } else {
        getWebGLPath(GAME_ID).then((result) => {
          if (result.status === 200) {
            const {location} = result.data;
            let path = WEBGL_PATH;
            if (location) {
              path = location;
              setWebglPath(location);
            } else {
              setWebglPath(WEBGL_PATH);
            }
            unityProvider.unityConfig = {
              loaderUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${path}/Build/AnimationViewer.loader.js?v=1.0.1`,
              dataUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${path}/Build/AnimationViewer.data?v=1.0.1`,
              frameworkUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${path}/Build/AnimationViewer.framework.js?v=1.0.1`,
              codeUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${path}/Build/AnimationViewer.wasm?v=1.0.1`,
              streamingAssetsUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${path}/StreamingAssets`,
            }
          }
        });
      }
    } else {
      setOpenAuth(true);
    }

  }, [])

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const isDeveloperMode = queryParameters.get("isDeveloperMode");
    if (isDeveloperMode) {
      localStorage.setItem('isDeveloperMode', isDeveloperMode);
    }
  }, []);

  const initializeResult = (type) => {
    return {type: `Result${type}`, data: {}, code: 200, status: '', message: ''};
  }

  const BrowserCommunication = (result) => {
    console.log('FE:', result);
    const string_result = {
      type: result.type,
      data: JSON.stringify(result.data),
      code: result?.code,
      result: result?.status,
      message: result?.message,
    };
    console.log('FE -> Unity:', JSON.stringify(string_result));
    sendMessage('BrowserCommunication', 'SendToUnity', JSON.stringify(string_result));
  }

  const RequestInformation = async (type, data, user) => {
    const result = initializeResult(type);
    const assetsResponse = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-assets`, {withCredentials: true});
    const asset = (assetsResponse.data.filter((asset)=> asset.id === assetId)).at(0);
    result.data = {
      id: assetId,
      account_id: asset.account_id,
      user_id: asset.user_id,
      status: 'success',
      cdn_url: `${process.env.REACT_APP_CDN_PATH}/ai_assets/${assetId}/asset`,
      error: '',
      name: asset.prompt,
    }
    BrowserCommunication(result);
  }

  const RequestAvatars = async (type, data, user) => {
    const result = initializeResult(type);
    const avatars = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-animation-avatars`, {withCredentials: true});
    const krikeyAvatars = [];
    if (avatars.data && avatars.data.length > 0) {
      avatars.data = avatars.data.sort((a, b) => {
        const aNum = a.avatar_id && a.avatar_id.includes(' ') ? parseInt(a.avatar_id.split(' ')[1]) : 0;
        const bNum = b.avatar_id && b.avatar_id.includes(' ') ? parseInt(b.avatar_id.split(' ')[1]) : 0;
        if (aNum < bNum) return -1;
        else if (aNum > bNum) return 1;
        return 0;
      });
      avatars.data.forEach((avatar) => {
        const {content} = avatar;
        content.id = avatar.avatar_id;
        content.uuid = avatar.id;
        krikeyAvatars.push(JSON.stringify(avatar.content));
      });
    }
    result.data.krikeyAvatars = krikeyAvatars;

    const rpmAvatars = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-assets/readyplayerme`, {withCredentials: true});
    const readyPlayerMeAvatars = [];
    if (rpmAvatars.data && rpmAvatars.data.length > 0) {
      rpmAvatars.data.forEach((avatar, idx) => {
        readyPlayerMeAvatars.push(JSON.stringify({
          id: avatar.avatar_id?? `Ready Player Me ${idx+1}`,
          uuid: avatar.id+'-RPM',
          url: avatar.avatar_url
        }));
      });
    }
    result.data.readyPlayerMeAvatars = readyPlayerMeAvatars;
    BrowserCommunication(result);
  }

  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const LargeFileDownload = async (url, filename) => {
    setPercentage(0);
    setShowProcessingModal(true);
    new JsFileDownloader({
      url,
      filename,
      timeout: 500000,
      process: DownloadProcessing,
    })
      .then(function () {
        setShowProcessingModal(false);
      })
      .catch(function (error) {
        alert(error);
        setShowProcessingModal(false);
      });
  }

  const CloseProcessingModal = () => {
    setShowProcessingModal(false);
  }

  const DownloadProcessing = (event) => {
    if (!event.lengthComputable) return; // guard
    setPercentage(Math.floor(event.loaded / event.total * 100));
  }

  const RequestDownloadRig = async (type, data, user) => {
    const assets = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-assets`, {withCredentials: true});
    const asset = assets.data.filter((asset) => asset.id === data.id).at(0);

    await LargeFileDownload(ReplaceCDNURL(asset.fbx_file), `${asset.prompt.replace(" ", "_")}.fbx`);
  }

  const RequestAvatarNameChange = async (type, data, user) => {
    console.log('RequestAvatarNameChange()', data);
    let avatar = {
      avatar_id: data.display_name
    };

    let url = (data.id.search('-RPM') > -1)? // is ready player me avatar?
      `${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-assets/readyplayerme/${data.id.slice(0,data.id.search('-RPM'))}`: // make sure to strip -RPM id
      `${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-animation-avatars/${data.id}`;
    const response = await axios.put(url,
      avatar,
      {withCredentials: true})
      .then(function (res) {
        return res;
      })
      .catch(function (err) {
        return err.response.data;
      });
  }

  useEffect(() => {
    if (isLoaded) {
      console.log('initialisationError', initialisationError ? initialisationError : 'No issue');
      addEventListener('Communication', async function (result) {
        const payload = JSON.parse(result);
        payload.data = JSON.parse(payload.data);
        const type = payload.type;
        const data = payload.data;
        const user = JSON.parse(getUserSession());
        switch (type) {
          case 'RequestInformation':
            await RequestInformation(type, data, user);
            break;
          case 'RequestAvatars':
            await RequestAvatars(type, data, user);
            break;
          case 'RequestDownloadRig':
            await RequestDownloadRig(type, data, user);
            break;
          case 'RequestAvatarNameChange':
            await RequestAvatarNameChange(type, data, user);
            break;
        }
      });
    }
  }, [isLoaded]);

  const CloseMobileErrorModal = () => {
    setShowMobileErrorModal(false);
  }

  const BackToDashboard = () => {
    window.location.href = '/dashboard/collection?to=animated';
  }

  return (
    <div className="bg-cover-solar-pups">
      <header>
        <Nav location="play/animation" openAuth={openAuth}/>
      </header>

      <ProcessingModal modalShow={showProcessingModal} closeHanlder={CloseProcessingModal} percentage={percentage}/>
      <MobileErrorModal modalShow={showMobileErrorModal} closeHandler={CloseMobileErrorModal}/>

      <div className='container-fluid py-4 px-3 bg-black'>
        <div className={'row justify-content-center align-self-center'}>
          <div className={'col-10'}>
            <button className={'btn-normal btn-size-lg text-white font-normal'} onClick={BackToDashboard}>Back to Dashboard</button>
          </div>
        </div>

        <div className={'row w-100 justify-content-center align-self-center'}>
          <div className={'col-10 my-3 py-2'}>
            {isLoaded === false && (
              <div className="text-white">
                <p>Loading... ({loadingPercentage}%)</p>
              </div>
            )}
            {webglPath !== '' && <Unity tabIndex='1' unityProvider={unityProvider} style={{width: '100%'}}/>}
          </div>
        </div>
      </div>

      <Footer/>

    </div>
  );
}
