import {useEffect, useState} from "react";
import {getUserSession} from "./Login";
import Nav from "./Nav";
import Footer from "./Footer";
import PricingFakeAI from "./PricingFakeAI";

export default function PricingAI(props) {
  const [user, setUser] = useState({email:'', user_id: ''});
  const [showFake, setShowFake] = useState('d-none');
  const [showDev, setShowDev] = useState('d-none');
  const [showProd, setShowProd] = useState('d-none');

  useEffect(() => {
    const userObj = JSON.parse(getUserSession()) ?? '';
    if (userObj) {
        setUser(userObj);
        if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
          setShowProd('d-block');
        } else {
          setShowDev('d-block');
        }
    } else {
      setShowFake('d-block');
    }
  }, []);

	return (
    <div>
      <header className="">
        <Nav location="pricing/ai"/>
      </header>

      <div className="p-5" style={{backgroundColor:'#252533'}}>
        <h1 className="text-white text-center">Ready to Get Started?</h1>
        <h3 className="text-center pb-5 color-aqua">Buy credits to generate gaming assets</h3>

        <div className={`${showFake}`} >
          <PricingFakeAI  />
        </div>

        <div className={`${showDev}`}>
          <stripe-pricing-table
            pricing-table-id="prctbl_1MEHgoCEyK4ObA42vYDfhwL6"
            publishable-key="pk_test_51M4tfZCEyK4ObA42kC327YKnfGckyfGc0GU41evC3GLvuWBOzhuUM0CERBW8hIuez7FQhqK2e6969GL7y7FyG1Nk00tuHwizr5"
            client-reference-id={`${user.user_id}`}>
          </stripe-pricing-table>

          <div className="row py-3"></div>

          <stripe-pricing-table
            pricing-table-id="prctbl_1MEHhnCEyK4ObA42PgIDbqGo"
            publishable-key="pk_test_51M4tfZCEyK4ObA42kC327YKnfGckyfGc0GU41evC3GLvuWBOzhuUM0CERBW8hIuez7FQhqK2e6969GL7y7FyG1Nk00tuHwizr5"
            client-reference-id={`${user.user_id}`}>
          </stripe-pricing-table>
        </div>

        <div className={`${showProd}`}>
          <stripe-pricing-table
            pricing-table-id="prctbl_1MWOfOGOMzvtCpnTGb3xLvpG"
            publishable-key="pk_live_51M4tk7GOMzvtCpnTNwOf8Hi5k3fjWwxp09vCOkFcGFKKa4s0yEpOWb1ic011hCWoo37NkIn8sfHrzfMTwBKrjjo600Si96Ia2f"
            client-reference-id={`${user.user_id}`}>
          </stripe-pricing-table>

          <div className="row py-3"></div>

          <stripe-pricing-table
            pricing-table-id="prctbl_1MWOhsGOMzvtCpnTmYBsL4F1"
            publishable-key="pk_live_51M4tk7GOMzvtCpnTNwOf8Hi5k3fjWwxp09vCOkFcGFKKa4s0yEpOWb1ic011hCWoo37NkIn8sfHrzfMTwBKrjjo600Si96Ia2f"
            client-reference-id={`${user.user_id}`}>
          </stripe-pricing-table>
        </div>


        <div className="row py-5"></div>
      </div>

      <Footer/>
    </div>
  );
}