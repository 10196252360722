import {Modal} from "react-bootstrap";
import React from "react";

export default function MobileErrorModal(props) {
  return (
    <Modal
      size="sm"
      name='mobileErrorModal'
      show={props.modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={props.closeHandler}
      centered
      contentClassName='rounded-modal border-blue'
    >
      <Modal.Body className='m-0'>
        <div className='row'>
          <div className='col-12 text-center'>
            <img width={'20px'} src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/exclamation.png`} alt={'Exclamation point'}/>
          </div>
          <div className='col-12 text-center'>
            <p className={'pt-2 font-bold'} style={{fontSize: '1.5em'}}>Device Type Not Supported</p>
          </div>
          <div className='col-12 text-center'>
            <p className={'m-0 pb-4 font-bold'} style={{fontSize: '1.0em'}}>
              Oops! Looks like you're on a mobile browser. To make a 3D asset / animation, you need to be on a desktop machine.
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
