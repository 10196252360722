import {Modal} from "react-bootstrap";
import React from "react";
import {AiOutlineStar} from "react-icons/all";

export default function CongratsModal(props) {
  return (
    <Modal
      size="sm"
      name='errorModal'
      show={props.modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={props.closeHandler}
      centered
      contentClassName='rounded-modal border-blue'
    >
      <Modal.Header className={'border-0 pb-0'} closeButton={true}/>
      <Modal.Body className='m-1 py-2'>
        <div className='row'>
          <div className='col-12 text-center'>
            <AiOutlineStar size={35}/>
          </div>
          <div className='col-12 text-center'>
            <p className={'pt-2 font-bold'} style={{fontSize: '2em'}}>{props.title}</p>
          </div>
          <div className='col-12 text-center'>
            <p className={'m-0 pb-4'} style={{fontSize: '1.2em'}}>
              You received 5 free credits. Start generating assets today!
            </p>
            <div className="pb-3">
              <button className="btn-normal btn-size-md text-white font-normal" onClick={props.closeHandler}>AI Generation</button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
