import FileSaver from 'file-saver';

export const download = (url, name) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    url = url.replace('cdn.dev.krikeyapp.com/', 'cdn.dev.krikey.ai/');
  } else if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    url = url.replace('cdn.krikeyapp.com/', 'cdn.krikey.ai/');
  }

  if (name) {
    FileSaver.saveAs(url, `${name}`);
  } else if (url) {
    const els = url.split('/');
    const fileName = els[els.length - 1];
    FileSaver.saveAs(url, `${fileName}`);
  }
};