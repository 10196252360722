import {useEffect, useState} from "react";
import {getUserSession} from "./Login";
import axios from "axios";
import AssetCardImage from './AssetCardImage';
import AssetCardAnimation from './AssetCardAnimation';
import NavCollection from './Nav/NavCollection';
import NavDashboard from './Nav/NavDashboard';
import '../assets/css/dashboard.css';

export default function DashboardCollection(props) {
  const [title, setTitle] = useState("My Collection Avatars");
  const [location, setLocation] = useState("animated");
  const [show2d, setShow2d] = useState("d-none");
  const [showAnimated, setShowAnimated] = useState("");
  const [assets2d, setAssets2d] = useState([]);
  const [assetsAnimated, setAssetsAnimated] = useState([]);


  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const to = queryParameters.get("to");
    if (to) {
      setNav(to);
    }
  }, []);

  useEffect(() => {
    if (getUserSession()) {
      const fetchAssets2d = async () => {
        const url = `${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/stability?page_number=0&page_count=50`;
        axios.get(url, {
          withCredentials: true,
        }).then(res => {
          setAssets2d(res.data);
        });
      }
      fetchAssets2d();
    }
  }, []);

  useEffect(() => {
    if (getUserSession()) {
      const fetchAssetsAnimated = async () => {
        const url = `${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-assets`;
        const { data } = await axios.get(url, {
          withCredentials: true,
        });
        setAssetsAnimated(data);
      }
      fetchAssetsAnimated();
    }
  }, []);

  const setNav = async (location) => {
    if (location === "2d") {
      setShow2d('');
      setShowAnimated('d-none');
      setLocation('2d');
      setTitle('My Collection Images');
    } else if (location === "animated") {
      setShow2d('d-none');
      setShowAnimated('');
      setLocation('animated');
      setTitle('My Collection Avatars');
    }
  }

  return (
    <div>

      <NavDashboard location="collection" />

      <div className="container-fluid">
        <div className="row px-5" style={{backgroundColor:'#f4f8fe', minHeight:'75rem'}}>

          <NavCollection location={location} setNav={setNav} />

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="row py-3">
              <div className="col-12">
                <h3 className="fw-bolder" style={{color:'#6f7191'}}>{title}</h3>
              </div>
            </div>
            <div className={`row py-3 ${show2d}`}>
              {assets2d.map((asset) =>
                <AssetCardImage
                  key={asset.id}
                  name={asset.prompt}
                  img={asset.image_url}
                />
              )}
            </div>
            <div className={`row py-3 ${showAnimated}`}>
              {assetsAnimated.map((asset) =>
                <AssetCardAnimation
                  key={asset.id}
                  id={asset.id}
                  name={asset.prompt}
                  rig={asset.fbx_file}
                  video={asset.video}
                  validVideo={asset.validVideo}
                />
              )}
            </div>
          </main>

        </div>
      </div>

    </div>
  );
}
