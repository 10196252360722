import {useEffect, useState} from "react";
import {getStandardPlan, getAdvancedPlan, searchProducts, getProduct} from "../../utils/Stripe";

export default function Shop(props) {
  const [showStandard, setShowStandard] = useState('d-none');
  const [showAdvanced, setShowAdvanced] = useState('d-none');

  useEffect(()=> {
    const loadPlan = async () => {
      const plan = JSON.parse(localStorage.getItem('plan'));
      let purchasedIds = props.purchasedProducts.map((purchased)=> purchased.id);
      let standardPlan = await getStandardPlan();
      let advancedPlan = await getAdvancedPlan();

      if (purchasedIds.includes(standardPlan.id)) {
        setShowStandard('');
        setShowAdvanced('d-none');
        for (let price of standardPlan.prices) {
          props.removeFromCart(price, false);
        }
      } else if (purchasedIds.includes(advancedPlan.id)) {
        setShowAdvanced('');
        setShowStandard('d-none');
        for (let price of advancedPlan.prices) {
          props.removeFromCart(price, false);
        }
      } else if (plan.type === 'advanced') {
        setShowAdvanced('');
        setShowStandard('d-none');
        const selectedPlan = advancedPlan;
        const price = selectedPlan.prices.filter((price) => price.recurring.interval === plan.interval).at(0);
        props.addToCart(price, false);
      } else {
        setShowStandard('');
        setShowAdvanced('d-none');
        const selectedPlan = standardPlan;
        const price = selectedPlan.prices.filter((price) => price.recurring.interval === plan.interval).at(0);
        props.addToCart(price, false);
      }
    }
    loadPlan();
  }, [props.purchasedProducts]);

	return (
    <div className="row">
      <div className="col-0 col-md-2"></div>
      <div className="col-12 col-md-10">
        <div className="row px-2 py-4">
          <div className="col-12 col-lg-6 py-1">
            <div className="p-3 rounded shadow-sm shop-card">

              <div className={`${showStandard}`}>
                <h3 className="color-blue">Standard Plan</h3>
                <div style={{height:'15rem'}}>
                  <div>Details:</div>
                  <ul>
                    <li>API calls allowance per month: 2 million</li>
                    <li>API rates: 200 requests per second</li>
                    <li>Storage allowance per month: 5 GB</li>
                    <li>Number of SDK Seats: 1</li>
                    <li>Included Basic SDKs: 1</li>
                    <li>Included Support: Basic</li>
                  </ul>
                </div>
              </div>

              <div className={`${showAdvanced}`}>
                <h3 className="color-blue">Advanced Plan</h3>
                <div style={{height:'15rem'}}>
                  <div>Details:</div>
                  <ul>
                    <li>API calls allowance per month: 5 million</li>
                    <li>API rates: 200 requests per second</li>
                    <li>Storage allowance per month: 20 GB</li>
                    <li>Number of SDK Seats: 10</li>
                    <li>Included Basic SDKs: 2</li>
                    <li>Included Support: Basic</li>
                  </ul>
                </div>
              </div>

              <div className="text-center">
                <button type="button" className="btn btn-outline-dark align-text-bottom"  style={{width:'100%'}} disabled>Current Plan</button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 py-1">
            <div className="p-3 rounded shadow-sm shop-card">
              <h3 className="color-blue">Add on Services</h3>
              <div style={{height:'15rem'}}>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check color-blue" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                  Basic SDKs
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check color-blue" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                  Premium SDKs
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check color-blue" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                  </svg>
                  More API Calls
                </div>
              </div>
              <div className="text-center align-text-bottom">
                <button type="button" className="btn btn-primary" style={{width:'100%'}} onClick={()=> props.updateShow('combined')}>Select Add Ons</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}