import {useEffect, useState} from "react";
import PurchasedCard from "./PurchasedCard";

export default function Projects(props) {
  const [token, setToken] = useState("");
  const [showCopied, setShowCopied] = useState('d-none');

  const makeToken = (length) => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let counter = 0;
      let token = '';
      while (counter < length) {
        token += characters.charAt(Math.floor(Math.random() * characters.length));
        counter += 1;
      }
      return token;
  }

  const generateToken = () => {
    setToken(makeToken(20));
  }

  const copied = () => {
    setShowCopied('');
    setTimeout(()=> {
      setShowCopied('d-none');
    }, 1300);
  }

	return (
    <div className="row">
      <div className="col-0 col-md-2"></div>
      <div className="col-12 col-md-10">

        <div className="p-3">
          <div className="row p-3 shadow-sm" style={{backgroundColor:'#ffffff'}}>
            <div className="col-12 col-lg-3 pt-1 pb-4">
              <label className="form-label">Download SDK Manager</label>
              <div>
                <a href={`${process.env.REACT_APP_CDN_PATH}/packages/KrikeySDKManager.unitypackage`}>
                  <button type="submit" className="btn btn-outline-primary border-blue color-blue">Download</button>
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-5 pt-1 pb-3">
              <div>
                <label className="form-label">Account ID</label>
                <span className={`p-1 ms-2 rounded text-white ${showCopied}`} style={{backgroundColor:'#414552'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                  </svg>
                  Copied
                </span>
              </div>
              <div className="btn ps-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy to clipboard" onClick={() => {navigator.clipboard.writeText('56597001-84e6-4e61-85fb-d9d0e6e3c637');copied();}}>
                <span className="pe-1">56597001-84e6-4e61-85fb-d9d0e6e3c637</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-clipboard2" viewBox="0 0 16 16">
                  <path d="M3.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H12a.5.5 0 0 1 0-1h.5A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1H4a.5.5 0 0 1 0 1h-.5Z"/>
                  <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z"/>
                </svg>

              </div>
            </div>
            <div className="col-12 col-lg-4 pt-1">
              <label className="form-label">Login Token</label>
              <div className="row">
                <div className="col-8">
                  <input type="text" className="form-control" value={token} onChange={()=>{}} />
                </div>
                <div className="col-4">
                  <button type="submit" className="btn btn-outline-primary border-blue color-blue" onClick={generateToken}>Generate</button>
                </div>
              </div>
              <div className="form-text">
                A token is needed to run the SDK Manager
              </div>
            </div>
          </div>
        </div>

        <h6 className="pt-3 px-3">My Purchases</h6>
        <div className="row">
          {props.purchasedProducts.map((product) =>
            <PurchasedCard
              key={product.id}
              image={product.image}
              title={product.name}
              description={product.description}
              hideSDK="d-none"
            />
          )}
        </div>

        <div className={`text-center color-blue ${props.showLoader}`}>
          <strong className="px-3 fs-6">Fetching Products</strong>
          <div className="spinner-border spinner-border-sm" role="status">
          </div>
        </div>

      </div>
    </div>
  );
}