import Nav from './components/Nav';
import Footer from './components/Footer';
import './index.css';
import './assets/css/home.css';
import './assets/css/flip-countdown.css';
import React, {useEffect, useState} from 'react';
import {Button, Card, Form, Image} from 'react-bootstrap';
import Fade from 'react-bootstrap/Fade';
import {BallTriangle} from "react-loader-spinner";
import axios from "axios";

import Carousel from 'react-bootstrap/Carousel';
import {useParams} from "react-router-dom";

export default function Gallery() {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [showPages, setShowPages] = useState(false);
  const [pageCount, setPageCount] = useState(9);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowStyle, setRowStyle] = useState({height: '95vh'});

  const LoadResults = async (type) => {
    setRowStyle({height: '95vh'});
    setShowPages(false);
    setIsLoading(true);
    let currentPageNumber;
    if (type === 'prev') {
      currentPageNumber = pageNumber - 1;
    } else {
      currentPageNumber = pageNumber + 1;
    }
    setPageNumber(currentPageNumber);
    await axios.get(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/stability?page_number=${currentPageNumber}&page_count=${pageCount}`).then(res => {
      LoadingSettings(res);
    });
  }

  const LoadingSettings = (res) => {
    setTimeout(() => {
      setIsLoading(false);
      setResults(res.data);
      setShowPages(true);
      setTimeout(() => {
        setRowStyle({});
      }, 500);
    }, 1000);
  }

  useEffect(() => {
    setRowStyle({height: '95vh'});
    setShowPages(false);
    setIsLoading(true);
    axios.get(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/stability?page_number=${pageNumber}&page_count=${pageCount}`).then(res => {
      LoadingSettings(res);
    });
  }, []);

  return (
    <div>
      <header className='bg-cover-solar-pups'>
        <Nav location='hackathon' openAuth={false}/>
      </header>

      <div className='container-fluid py-2 bg-black'>
        <div className={'row text-white mx-2 mx-lg-4 justify-content-center'} style={rowStyle}>
          {isLoading && (<div className={`col-8 text-center my-auto`}>
            <BallTriangle
              height={250}
              width={250}
              radius={4}
              color="#F26B40"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle={{display: 'contents'}}
              visible={true}
            />
          </div>)
          }
          {showPages && (<div className={'col-8 text-center my-auto'}>
            <div className={'row py-3'}>
              {results.map(result => {
                return (
                  <div className={'col-4 my-2'} key={result.id}>
                    <Card className={'border-orange'}>
                      <Card.Img variant={'top'} src={result.image_url}/>
                      <Card.Body className={' bg-black'}>
                        <p className={'text-orange my-2'}>{result.prompt}</p>
                      </Card.Body>
                    </Card>
                  </div>)
              })}
            </div>
            <div className={'row'}>
              <div className={'col-6'}>
                <button className={'btn-normal btn-size-md text-white font-normal'} onClick={() => LoadResults('prev')}>Previous</button>
              </div>
              <div className={'col-6'}>
                <button className={'btn-normal btn-size-md text-white font-normal'} onClick={() => LoadResults('next')}>Next</button>
              </div>
            </div>
          </div>)}
        </div>
      </div>
      <Footer/>
    </div>
  );
}
