import {googleSuccessFlow} from "../utils/GoogleLoginHandler";
import {useGoogleLogin} from '@react-oauth/google';
import '../assets/css/stripe-table.css';
import {useState} from "react";

export default function PricingFakeSDK(props) {
  const GoogleLogin = useGoogleLogin(googleSuccessFlow());
  const [tab, setTab] = useState('month');

  return (
    <div id="root">
      <div>
        <div className="PricingTable is-whiteButtonText has-threeItems flex-container direction-column justify-content-center align-items-center" data-testid="pricing-table-container" style={{colorPrimary: '#0074d4', backgroundColor: 'rgb(37, 37, 51)'}}>
          <div className="IntervalTabs-refContainer">
            <div className="Tabs IntervalTabs is-insettabs is-desktop">
              <div className="Tabs-Container">
                <div role="tablist" aria-orientation="horizontal" aria-label="Billing intervals" className="Tabs-TabList">
                  <div role="presentation" className={`Tabs-TabListItemContainer ${tab === 'month' ? 'Tabs-TabListItemContainer--is-selected' : ''}`}>
                    <button className={`Tabs-TabListItem ${tab === 'month' ? 'Tabs-TabListItem--is-selected' : ''}`} id="1-month-tab" data-testid="1-month-tab-button" role="tab" type="button" aria-controls="1-month-tab-panel" aria-label="Monthly" aria-selected={tab === 'month'} tabIndex={tab === 'month' ? '0' : '-1'} title="Monthly" onClick={() => {
                        setTab('month');
                    }} style={{backgroundColor: tab === 'month' ? '#0074d4' : 'transparent'}}>
                      <div className="Tabs-TabListItemContent">
                        <div className="Tabs-TabListPaymentMethod">
                          <div className="Tabs-TabListPaymentLabel" data-text="Monthly">Monthly</div>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div role="presentation" className={`Tabs-TabListItemContainer ${tab === 'year' ? 'Tabs-TabListItemContainer--is-selected' : ''}`}>
                      <button className={`Tabs-TabListItem ${tab === 'year' ? 'Tabs-TabListItem--is-selected' : ''}`} id="1-year-tab" data-testid="1-year-tab-button" role="tab" type="button" aria-controls="1-year-tab-panel" aria-label="Yearly" aria-selected={tab === 'year'}  tabIndex="-1" title="Yearly" onClick={() => {
                        setTab('year');
                    }} style={{backgroundColor: tab === 'year' ? '#0074d4' : 'transparent'}}>
                      <div className="Tabs-TabListItemContent">
                        <div className="Tabs-TabListPaymentMethod">
                          <div className="Tabs-TabListPaymentLabel" data-text="Yearly">Yearly</div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="PricingTable-grid">
            <div className="PriceColumn flex-container direction-column justify-content-flex-start align-items-center" data-testid="price-column">
              <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                <div className="PriceColumn-aboutProduct flex-container direction-column">
                  <div className="PriceColumn-badge is-placeholderForHeight flex-container">
                    <div className="Badge is-testModeBadge flex-container align-items-center"><span className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Test Mode</span></div>
                  </div>
                  <div className="PriceColumn-textAndProductImage flex-container">
                    <div className="PriceColumn-textContainerHeightAligner" style={{height: '99px'}}>
                      <div className="PriceColumn-textContainer flex-container direction-column">
                        <div className="PriceColumn-name flex-container"><span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">Standard Plan</span></div>
                        <span className="PriceColumn-description Text Text-color--default Text-fontSize--14">This is the lowest subscription tier that is made for individuals working on smaller projects. </span></div>
                    </div>
                  </div>
                </div>
                <div className="PriceColumn-priceAndButton flex-container direction-column">
                  <div className="PriceColumn-priceContainerHeightAligner" style={{height: '42px'}}>
                    <div className="PriceColumn-priceContainer">
                      <div className="flex-container spacing-4 direction-column align-items-flex-start">
                        <div className="flex-container align-items-center"><span className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"><span>${tab === 'month' ? '199.99' : '2,239.99'}</span></span><span className="PriceColumn-interval Text Text-color--default Text-fontSize--13">per <br/>{tab}</span></div>
                      </div>
                    </div>
                  </div>
                  <button className="Button PriceColumn-button Button--primary Button--lg" type="button" onClick={GoogleLogin} style={{backgroundColor: 'rgb(0, 116, 212)', borderColor: 'rgb(0, 116, 212)'}}>
                    <div className="flex-container justify-content-center align-items-center">
                      <span className="Text Text-color--default Text-fontWeight--500 Text--truncate">7 Day Free Trial</span>
                    </div>
                  </button>
                </div>
              </div>
              <div className="PriceColumn-featureListContainer flex-container direction-column"><span className="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14">This includes:</span>
                <div className="PriceColumn-featureList flex-container direction-column align-items-flex-start">
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">API calls allowance per month: 2 million</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">API rates: 200 requests per second</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Storage allowance per month: 5 GB</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Number of SDK Seats: 1</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Included Basic SDKs: 1</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Included Support: Basic</span></div>
                </div>
              </div>
            </div>
            <div className="PriceColumn is-highlight flex-container direction-column justify-content-flex-start align-items-center" data-testid="price-column">
              <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                <div className="PriceColumn-aboutProduct flex-container direction-column">
                  <div className="PriceColumn-badge flex-container">
                    <div className="Badge is-highlightBadge flex-container align-items-center"><span className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Recommended</span></div>
                    <div style={{marginLeft: '10px'}}>
                      <div className="Badge is-testModeBadge flex-container align-items-center"><span className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Test Mode</span></div>
                    </div>
                  </div>
                  <div className="PriceColumn-textAndProductImage flex-container">
                    <div className="PriceColumn-textContainerHeightAligner" style={{height: '99px'}}>
                      <div className="PriceColumn-textContainer flex-container direction-column">
                        <div className="PriceColumn-name flex-container"><span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">Advanced Plan</span></div>
                        <span className="PriceColumn-description Text Text-color--default Text-fontSize--14">This is the subscription tier that is made for small teams with 10 seats plus more API calls and storage allowance.</span></div>
                    </div>
                  </div>
                </div>
                <div className="PriceColumn-priceAndButton flex-container direction-column">
                  <div className="PriceColumn-priceContainerHeightAligner" style={{height: '42px'}}>
                    <div className="PriceColumn-priceContainer">
                      <div className="flex-container spacing-4 direction-column align-items-flex-start">
                        <div className="flex-container align-items-center"><span className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"><span>${tab === 'month' ? '299.99' : '3,299.99'}</span></span><span className="PriceColumn-interval Text Text-color--default Text-fontSize--13">per <br/>{tab}</span></div>
                      </div>
                    </div>
                  </div>
                  <button className="Button PriceColumn-button Button--primary Button--lg" type="button" onClick={GoogleLogin} style={{backgroundColor: 'rgb(0, 116, 212)', borderColor: 'rgb(0, 116, 212)'}}>
                    <div className="flex-container justify-content-center align-items-center"><span className="Text Text-color--default Text-fontWeight--500 Text--truncate">7 Day Free Trial</span></div>
                  </button>
                </div>
              </div>
              <div className="PriceColumn-featureListContainer flex-container direction-column"><span className="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14">This includes:</span>
                <div className="PriceColumn-featureList flex-container direction-column align-items-flex-start">
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">API calls allowance per month: 5 million</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">API rates: 200 requests per second</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Storage allowance per month: 20 GB</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Number of SDK Seats: 10</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Included Basic SDKs: 2</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Included Support: Basic</span></div>
                </div>
              </div>
            </div>
            <div className="PriceColumn flex-container direction-column justify-content-flex-start align-items-center" data-testid="price-column">
              <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                <div className="PriceColumn-aboutProduct flex-container direction-column">
                  <div className="PriceColumn-badge is-placeholderForHeight flex-container">
                    <div className="Badge is-testModeBadge flex-container align-items-center"><span className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Test Mode</span></div>
                  </div>
                  <div className="PriceColumn-textAndProductImage flex-container">
                    <div className="PriceColumn-textContainerHeightAligner" style={{height: '99px'}}>
                      <div className="PriceColumn-textContainer flex-container direction-column">
                        <div className="PriceColumn-name flex-container"><span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">Enterprise Plan</span></div>
                        <span className="PriceColumn-description Text Text-color--default Text-fontSize--14">For large teams looking for a high degree of customization and premium support. </span></div>
                    </div>
                  </div>
                </div>
                <div className="PriceColumn-priceAndButton flex-container direction-column">
                  <div className="PriceColumn-priceContainerHeightAligner" style={{height: '42px'}}/>
                  <a href="https://ef64x8ctl4h.typeform.com/to/YI3Lmh0N" target={'_blank'} rel="noreferrer">
                    <button className="Button PriceColumn-button Button--primary Button--lg" type="button" style={{backgroundColor: 'rgb(0, 116, 212)', borderColor: 'rgb(0, 116, 212)'}}>
                      <div className="flex-container justify-content-center align-items-center"><span className="Text Text-color--default Text-fontWeight--500 Text--truncate">Contact us</span></div>
                    </button>
                  </a>
                </div>
              </div>
              <div className="PriceColumn-featureListContainer flex-container direction-column"><span className="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14">This includes:</span>
                <div className="PriceColumn-featureList flex-container direction-column align-items-flex-start">
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">API calls allowance per month: Custom, at least 10 million</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">API rates: Custom, at least 200 and at most 500 requests per second</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Storage allowance per month: Custom, at least 20 GB</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Number of SDK Seats (Number of access licenses): 1</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Included Basic SDKs: Custom, at least 3</span></div>
                  <div className="PriceColumn-feature flex-container align-items-flex-start">
                    <div className="PriceColumn-checkContainer flex-container">
                      <svg className="InlineSVG Icon PriceColumn-check Icon--sm" focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
                        <path d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z" fillRule="evenodd"></path>
                      </svg>
                    </div>
                    <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">Included Support: Premium</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
