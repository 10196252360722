import {useEffect, useState} from "react";
import {getUserSession} from "./Login";
import Nav from "./Nav";
import Footer from "./Footer";
import PlanGridMonthly from "./PlanGrids/PlanGridMonthly";
import PlanGridMonthlyMobile from "./PlanGrids/PlanGridMonthlyMobile";
import PlanGridYearlyMobile from "./PlanGrids/PlanGridYearlyMobile";
import PlanGridYearly from "./PlanGrids/PlanGridYearly";
import PricingFakeSDK from "./PricingFakeSDK";
import PricingSDKRedirect from "./PricingSDKRedirect";

export default function PricingSDK(props) {
  const [showFake, setShowFake] = useState('d-none');
  const [showDev, setShowDev] = useState('d-none');
  const [showProd, setShowProd] = useState('d-none');

  useEffect(() => {
    const userObj = JSON.parse(getUserSession()) ?? '';
    if (userObj) {
      if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        setShowProd('d-block');
      } else {
        setShowDev('d-block');
      }
    } else {
      setShowFake('d-block');
    }
  }, []);

  const [monthlyPlan, setMonthlyPlan] = useState({
    bgColor: 'bg-color-aqua',
    fontColor: 'text-white',
    display:''
  });
  const [yearlyPlan, setYearlyPlan] = useState({
    bgColor: '',
    fontColor: 'text-dark',
    display:'d-none'
  });

  const showPlan = (plan) => {
    if (plan === 'yearly') {
      setYearlyPlan({
        bgColor: 'bg-color-aqua',
        fontColor: 'text-white',
        display:''
      });
      setMonthlyPlan({
        bgColor: '',
        fontColor: 'text-dark',
        display:'d-none'
      });
    } else {
      setYearlyPlan({
        bgColor: '',
        fontColor: 'text-dark',
        display:'d-none'
      });
      setMonthlyPlan({
        bgColor: 'bg-color-aqua',
        fontColor: 'text-white',
        display:''
      });
    }
  }

	return (
    <div>
      <header className="">
        <Nav location="pricing/sdk"/>
      </header>

      <div className="p-5" style={{backgroundColor:'#252533'}}>
        <h1 className="text-white text-center">Ready to Get Started?</h1>
        <h3 className="text-center pb-5 color-aqua">Build, Customize, Launch your Next Project</h3>

        <div className={`${showFake}`} >
          <PricingFakeSDK />
        </div>

        <div className={`${showDev}`}>
          <PricingSDKRedirect />
          {/* <stripe-pricing-table
            pricing-table-id="prctbl_1MEeK3CEyK4ObA42z8MBkdO8"
            publishable-key="pk_test_51M4tfZCEyK4ObA42kC327YKnfGckyfGc0GU41evC3GLvuWBOzhuUM0CERBW8hIuez7FQhqK2e6969GL7y7FyG1Nk00tuHwizr5">
          </stripe-pricing-table> */}
        </div>

        <div className={`${showProd}`}>
          <PricingSDKRedirect />
          {/* <stripe-pricing-table
            pricing-table-id="prctbl_1MNPWXGOMzvtCpnTUvmW8IyO"
            publishable-key="pk_test_51M4tk7GOMzvtCpnTToJhFBWwHpqXTNJzJKYM9SVFQbqW6moWIdE08gqLOKPXsWUUVqPRdlCi2UQ4ptUUSa7PoBdu00XkQsPGwf">
          </stripe-pricing-table> */}
        </div>

      </div>

      <div className="row p-5">
        <div className="col-12 col-lg-3 text-center text-lg-start">
          <span className="pt-5 color-fancy-gray fs-6 fw-bolder">COMPARE PLANS & FEATURES</span>
        </div>
        <div className="col-0 col-lg-9 border-bottom-gray"></div>
      </div>

      <div className="row py-5">
        <div className="col-12 text-center">
          <div className="btn-group">
            <button onClick={() => showPlan('monthly')} type="button" className={`btn btn-light fw-bold ${monthlyPlan.fontColor} ${monthlyPlan.bgColor}`}>Monthly</button>
            <button onClick={() => showPlan('yearly')} type="button" className={`btn btn-light fw-bold ${yearlyPlan.fontColor} ${yearlyPlan.bgColor}`}>Yearly</button>
          </div>
        </div>
      </div>

      <div id="gridMonthly" className={`${monthlyPlan.display}`}>
        <PlanGridMonthlyMobile />
        <PlanGridMonthly />
      </div>

      <div id="gridYearly" className={`${yearlyPlan.display}`}>
        <PlanGridYearlyMobile />
        <PlanGridYearly />
      </div>

      <div className="row">
        <div className="py-5"></div>
      </div>

      <Footer/>

    </div>
  );
}
