import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import Spinner from "../Spinner";

const CopiesOfProgress = {
  75: {
    progress: 'Gathering your pups!',
    title: 'Set up your account',
    description1: 'Please execute the following transaction to set up your account.  Do not close this window while the transaction is processing',
    description2: 'This is free of charge and will only take a few seconds.'
  },
  95: {
    progress: 'You\'re almost there. Hang tight!',
    title: 'Finish Creating Your Account',
    description1: 'We are processing your transaction to set up your account.  Do not close this window while the transaction is processing. It will auto-dismiss when done.',
    description2: 'This is free of charge and will only take a few seconds.'
  },
}

export default function SetupModal(props) {
  const [progression, setProgression] = useState(75);

  return (
    <Modal
      size="md"
      name='setupModal'
      show={props.modalShow}
      onHide={() => {}}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName='rounded-modal border-orange'
    >
      <Modal.Body className='m-3'>
        <div className='row'>
          <div className='col-12 text-center'>
            <img width={'50px'} src={'https://krikey-dev-media.s3.ap-south-1.amazonaws.com/krikey-ai/img/small-logo.webp'} alt={'Krikey AI logo'}/>
          </div>
          <div className='col-12 text-center'>
            <p className={'pt-2 font-bold'} style={{fontSize: '2em'}}>{CopiesOfProgress[progression].title}</p>
          </div>
          <div className='col-12 text-center'>
            <p className={'m-0 pb-4'} style={{fontSize: '1.2em'}}>
              {CopiesOfProgress[progression].description1}
              <br/><br/>
              {CopiesOfProgress[progression].description2}
            </p>
            {props.showModalError && (
              <div className="text-danger text-center my-3" style={{fontSize: '1.2em'}}>
                {props.modalError}
              </div>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col text-center'>
            {
              props.setupProcessing ? <Spinner id='setupProcessing'/> :
                <button className='btn-orange w-100 text-white btn-size-md' type='submit'
                        disabled={props.setupProcessing}
                        onClick={async () => {
                          setProgression(95);
                          await props.submitHandler();
                        }}>
                  <strong>Finish Setting Up Account</strong>
                </button>
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
