import {useEffect, useState} from "react";
import Nav from "./Nav";
import Footer from "./Footer";

export default function Faq(props) {
  const [arrows, setArrows] = useState([
    ['','d-none'],
    ['d-none',''],
    ['d-none',''],
    ['d-none',''],
    ['d-none',''],
    ['d-none',''],
    ['d-none',''],
    ['d-none',''],
    ['d-none',''],
    ['d-none',''],
    ['d-none',''],
    ['d-none',''],
    ['d-none',''],
    ['d-none',''],
    ['d-none',''],
  ]);

  const arrow = (index) => {
    let newArrows = [...arrows];
    newArrows[index].push(newArrows[index].shift()); // flip array values to flip arrow
    setArrows(newArrows);
  }

  return (
    <div>
      <header className="">
        <Nav location="faq"/>
      </header>

      <div className="container">
        <div className="row py-3">
          <div className="col-12 text-center">
            <h2 className="pt-5 color-fancy-gray fw-bolder">Frequently Asked Questions</h2>
          </div>
        </div>

        <div className="row text-start text-dark pb-5">
          <div className="col-1"></div>
          <div className="col-12 col-lg-5">
            <div className="row">
              <div className="col-12 py-3">
                  <a className="fw-bold" onClick={() => arrow(0)} data-bs-toggle="collapse" href="#collapse0" role="button" aria-expanded="false" aria-controls="collapse0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[0][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[0][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    What do I need to get started?
                  </a>
                <div class="collapse show" id="collapse0">
                  <div class="p-3">
                    To get started, sign up with your Google account and purchase credits. No additional knowledge required. Happy generating!
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                <a className="fw-bold" onClick={() => arrow(1)} data-bs-toggle="collapse" href="#collapse1" role="button" aria-expanded="false" aria-controls="collapse1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[1][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[1][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    What can I type into the input field?
                  </a>
                <div class="collapse" id="collapse1">
                  <div class="p-3">
                    <p>For Animated Avatars, include a human related noun.</p>
                    <p>For additional best practices, access our guide <a target="_blank" href="https://krikey.gitbook.io/krikey-ai/JBrm9ZNMHNyW43c3S73h/generative-assets/prompt-engineering">here</a>.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                <a className="fw-bold" onClick={() => arrow(2)} data-bs-toggle="collapse" href="#collapse2" role="button" aria-expanded="false" aria-controls="collapse2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[2][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[2][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    I ran out of credits, how can I purchase more?
                  </a>
                <div class="collapse" id="collapse2">
                  <div class="p-3">
                    <p>You can purchase additional credits from the pricing page.</p>
                    <p className="fst-italic">#1 Tap on Pricing from navigation bar and select AI Credits</p>
                    <p className="fst-italic">#2 Select your preferred bundle and checkout</p>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                <a className="fw-bold" onClick={() => arrow(3)} data-bs-toggle="collapse" href="#collapse3" role="button" aria-expanded="false" aria-controls="collapse3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[3][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[3][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    Do I need to be an engineer to use this tool?
                  </a>
                <div class="collapse" id="collapse3">
                  <div class="p-3">
                    <p>You do not need any technical background to use our tools. Krikey.ai tools are meant for anyone, irrespective of their technical expertise.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                <a className="fw-bold" onClick={() => arrow(4)} data-bs-toggle="collapse" href="#collapse4" role="button" aria-expanded="false" aria-controls="collapse4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[4][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[4][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    Where can I access all my generated assets?
                  </a>
                <div class="collapse" id="collapse4">
                  <div class="p-3">
                    <p>Select My Collection from the profile drop down from the top right.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                  <a className="fw-bold" onClick={() => arrow(5)} data-bs-toggle="collapse" href="#collapse5" role="button" aria-expanded="false" aria-controls="collapse5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[5][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[5][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    Can I mint my 2D image?
                  </a>
                <div class="collapse" id="collapse5">
                  <div class="p-3">
                    <p>Yes, you can. After you've generated your 2D image, connect your Blotco wallet to mint the 2D image. Additional wallet support coming soon!</p>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                  <a className="fw-bold" onClick={() => arrow(6)} data-bs-toggle="collapse" href="#collapse6" role="button" aria-expanded="false" aria-controls="collapse6">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[6][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[6][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    What can I do with my generated assets?
                  </a>
                <div class="collapse" id="collapse6">
                  <div class="p-3">
                    <p>Use them to build a game using one of our SDKs.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                  <a className="fw-bold" onClick={() => arrow(7)} data-bs-toggle="collapse" href="#collapse7" role="button" aria-expanded="false" aria-controls="collapse7">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[7][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[7][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    How do I know what prompts will get me the best animation?
                  </a>
                <div class="collapse" id="collapse7">
                  <div class="p-3">
                    <p>Check out our prompt engineering handbook to see commonly used prompts, funny prompts, and more. You can also watch our trailer to see some of the best animations.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>











          <div className="col-12 col-lg-5">
            <div className="row">
              <div className="col-12 py-3">
                  <a className="fw-bold" onClick={() => arrow(8)} data-bs-toggle="collapse" href="#collapse8" role="button" aria-expanded="false" aria-controls="collapse8">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[8][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[8][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    How long does it take to generate an animation?
                  </a>
                <div class="collapse" id="collapse8">
                  <div class="p-3">
                    <p>Typically, wait times are around 5 minutes for 1 animation, but may vary slightly based on the current queue. Be sure to keep your tab open and don't let your computer fall asleep, or you'll lose your spot in the line. </p>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                  <a className="fw-bold" onClick={() => arrow(9)} data-bs-toggle="collapse" href="#collapse9" role="button" aria-expanded="false" aria-controls="collapse9">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[9][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[9][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    What does each SDK do?
                  </a>
                <div class="collapse" id="collapse9">
                  <div class="p-3">
                    <p>
                      View each of your SDKs <a href="/product/sdk">HERE</a> to get more in depth information.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                  <a className="fw-bold" onClick={() => arrow(10)} data-bs-toggle="collapse" href="#collapse10" role="button" aria-expanded="false" aria-controls="collapse10">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[10][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[10][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    Where can I learn more?
                  </a>
                <div class="collapse" id="collapse10">
                  <div class="p-3">
                    <p><a target="_blank" href="https://krikey.gitbook.io/krikey-ai/JBrm9ZNMHNyW43c3S73h/">Documentation</a></p>
                    <p><a href="/use-cases/exporting-animations">Exporting AI Animations</a></p>
                    <p><a href="/use-cases/editing-animations">Editing AI Animations</a></p>
                    <p><a href="/use-cases/mobile-augment-reality">AI Animations in Mobile Augmented Reality</a></p>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                  <a className="fw-bold" onClick={() => arrow(11)} data-bs-toggle="collapse" href="#collapse11" role="button" aria-expanded="false" aria-controls="collapse11">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[11][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[11][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    Does Krikey get a percentage of profits made using the tools?
                  </a>
                <div class="collapse" id="collapse11">
                  <div class="p-3">
                    <p>No, our license allows you to keep 100% of the earnings you make with the games and other products you make with our toolkits.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                  <a className="fw-bold" onClick={() => arrow(12)} data-bs-toggle="collapse" href="#collapse12" role="button" aria-expanded="false" aria-controls="collapse12">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[12][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[12][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    How can I mint the images that I generate?
                  </a>
                <div class="collapse" id="collapse12">
                  <div class="p-3">
                    <p>
                      You can find information on minting in our documentation <a target="_blank" href="https://krikey.gitbook.io/krikey-ai/JBrm9ZNMHNyW43c3S73h/introduction/welcome">HERE</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                  <a className="fw-bold" onClick={() => arrow(13)} data-bs-toggle="collapse" href="#collapse13" role="button" aria-expanded="false" aria-controls="collapse13">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[13][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[13][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    How can non-technical users make the most of the toolkits?
                  </a>
                <div class="collapse" id="collapse13">
                  <div class="p-3">
                    <p>You can view some of our Case Studies on <a href="/use-cases/exporting-animations">Exporting AI Animations</a>, <a href="/use-cases/editing-animations">Editing AI Animations</a> & <a href="/use-cases/mobile-augment-reality">AI Animations in Mobile Augmented Reality</a></p>
                  </div>
                </div>
              </div>
              <div className="col-12 py-3">
                  <a className="fw-bold" onClick={() => arrow(14)} data-bs-toggle="collapse" href="#collapse14" role="button" aria-expanded="false" aria-controls="collapse14">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-down ${arrows[14][0]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-arrow-up ${arrows[14][1]}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    What are credits used for?
                  </a>
                <div class="collapse" id="collapse14">
                  <div class="p-3">
                    <p>They are used for generating AI assets. You can find some examples <a href="/product/ai">HERE</a> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row py-5">
          <div className="col-12 col-lg-9 text-muted text-center text-lg-start pt-3">
            <span className="fs-5">If you don't see your question</span>
          </div>
          <div className="col-12 col-lg-3 text-center text-lg-end pt-3">
            <a href="/contact">
              <button className="btn btn-outline-primary fw-bold">Contact Us</button>
            </a>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
