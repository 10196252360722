export default function BannerBuy(props) {
	return (
    <div className="row text-center text-lg-end">
      <div className="col-0 col-lg-9"></div>
      <div className="col-12 col-lg-2">
        {/* <button type="button" className="btn btn-outline-primary">Add to Cart</button> */}
        {/* <span className="px-2"></span> */}
        <button type="button" className="btn btn-primary" onClick={() => props.runCheckout() }>Buy Now</button>
      </div>
      <div className="col-0 col-lg-1"></div>
    </div>
  );
}