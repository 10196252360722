export default function PlanGridYearlyMobile(props) {
	return (
    <div className="text-center color-fancy-gray mx-4 d-md-none">

      <div className="row">
        <div className="col-5 p-5"></div>
        <div className="col-7 p-5 bg-color-gray border-bottom border-end" style={{borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem'}}>
          <div className="text-dark">Standard</div>
          <div>Annual, paid monthly</div>
          <div className="text-dark fw-bold">Starting at $199.99</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end" style={{borderTopLeftRadius: '0.5rem'}}>
          <span className="fw-bolder fs-6 text-dark">APIS</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
          <p>Upgradable to</p>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div className="text-dark">2M</div>
          <div>4M</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">Rate</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
          <p>(Requests per second)</p>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div className="text-dark">200</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">Storage</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
          <p>(Asset Storage)</p>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div className="text-black">5GB</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">SDK Seats</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div className="text-black">1</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
          <span className="fw-bolder fs-6 text-dark">Basic SDKs</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div className="text-black">1</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">Premium SDK for extra $</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-circle color-blue" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path></svg>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">Support</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div className="text-black">Basic</div>
        </div>
      </div>













      <div className="row pt-5">
        <div className="col-5 p-5"></div>
        <div className="col-7 p-5 bg-color-gray border-bottom border-end" style={{borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem'}}>
          <div className="text-dark">Advanced</div>
          <div>Annual, paid monthly</div>
          <div className="text-dark fw-bold">Starting at $299.99</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end" style={{borderTopLeftRadius: '0.5rem'}}>
          <span className="fw-bolder fs-6 text-dark">APIS</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
          <p>Upgradable to</p>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div className="text-dark">5M</div>
          <div>10M</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">Rate</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
          <p>(Requests per second)</p>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div className="text-dark">200</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">Storage</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
          <p>(Asset Storage)</p>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div className="text-black">20GB</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">SDK Seats</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div className="text-black">10</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
          <span className="fw-bolder fs-6 text-dark">Basic SDKs</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div className="text-black">2</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">Premium SDK for extra $</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-circle color-blue" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path></svg>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">Support</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div className="text-black">Basic</div>
        </div>
      </div>











      <div className="row pt-5">
        <div className="col-5 p-5"></div>
        <div className="col-7 p-5 bg-color-gray border-bottom border-end" style={{borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem'}}>
          <div className="text-dark">Enterprise</div>
          <div>Annual, paid monthly</div>
          <div className="text-dark fw-bold">Starting at $499.99</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end" style={{borderTopLeftRadius: '0.5rem'}}>
          <span className="fw-bolder fs-6 text-dark">APIS</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
          <p>Upgradable to</p>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div>Custom</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">Rate</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
          <p>(Requests per second)</p>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div>Custom</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">Storage</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
          <p>(Asset Storage)</p>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div>Custom</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">SDK Seats</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div>Custom</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
          <span className="fw-bolder fs-6 text-dark">Basic SDKs</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div>Custom</div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">Premium SDK for extra $</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path></svg>
        </div>
      </div>
      <div className="row">
        <div className="col-5 p-4 bg-color-gray p-4 text-start border-bottom border-end">
        <span className="fw-bolder fs-6 text-dark">Support</span>
          <span className="px-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </div>
        <div className="col-7 p-4 border-bottom border-end">
          <div>Premium</div>
        </div>
      </div>


    </div>
  );
}