import Nav from "./Nav";
import Footer from "./Footer";

export default function Home(props) {
  return (
    <div>
      <header className="">
        <Nav location="intro"/>
      </header>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-8 text-center pt-10">
            <img className="img-fluid" style={{height: '7rem'}} src="https://cdn.krikeyapp.com/krikey.xyz/images/logo.png"/>
            <h1 className="pt-3 color-fancy-gray fw-bolder">Build end to end <span className="color-aqua">In-App</span> or <span className="color-aqua">Web Games</span>, in moments.</h1>
            <div className={'row justify-content-center'}>
              <div className={'col-12 mt-3 col-lg-4 text-center'}>
                <button className={'btn-normal btn-size-md text-white font-normal'} onClick={() => window.location = '/product/sdk'}>Gaming SDKs</button>
              </div>
              <div className={'col-12 mt-3 col-lg-4 text-center'}>
                <button className={'btn-normal btn-size-md text-white font-normal'} onClick={() => window.location = '/product/ai'}>AI Generation</button>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-10 pb-1">
          <div className="col-12 col-lg-6 d-flex text-center text-md-start align-items-center">
            <h2 className="fs-1 fw-bolder px-3 pb-5 pb-md-0">Generate <span className="color-aqua">animated avatars</span> with <a className='color-aqua' href={'/product/ai'}>Krikey AI</a> in minutes</h2>
          </div>
          <div className="col-12 col-lg-6 text-center">
            <video className="w-75" autoPlay muted loop playsInline>
              <source src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/video/v1-2.mp4`} poster={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/v1-2.webp`} type="video/mp4"/>
            </video>
          </div>
        </div>

        <div className="row pt-10 pb-1">
          <div className="col-12 col-lg-6 d-flex text-center text-md-start align-items-center">
            <h2 className="fs-1 fw-bolder px-3 pb-5 pb-md-0">Bring your avatar to life, with the <span><a href="/product/sdk" className="color-aqua">Augmented Reality SDK</a></span></h2>
          </div>
          <div className="col-12 col-lg-6 text-center">
            <video className="w-75" autoPlay muted loop playsInline>
              <source src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/video/v2.mp4`} poster={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/v2.webp`} type="video/mp4"/>
            </video>
          </div>
        </div>

        <div className="row pt-10 pb-1">
          <div className="col-12 col-lg-6 d-flex text-center text-md-start align-items-center">
            <h2 className="fs-1 fw-bolder px-3 pb-5 pb-md-0">Bring your avatar to play with the <span><a href="/product/sdk" className="color-aqua">Casual Gaming SDK</a></span></h2>
          </div>
          <div className="col-12 col-lg-6 text-center">
            <video className="w-75" autoPlay muted loop playsInline>
              <source src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/video/v3.mp4`} poster={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/v3.webp`} type="video/mp4"/>
            </video>
          </div>
        </div>

        <div className="row pt-10 pb-1">
          <div className="col-0 col-lg-3"></div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <h2 className="fs-1 fw-bolder text-center">Reach out if you have any questions!</h2>
          </div>
          <div className="col-0 col-lg-3"></div>

          <div className="col-12 text-center pt-3 pb-5">
            <a href="/contact">
              <button type="button" className="btn btn-primary fw-bold">
                <span className="px-2">Ask Support</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                </svg>
              </button>
            </a>
          </div>
        </div>

      </div>

      <Footer/>
    </div>
  );
}
