import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {getUserSession} from "./components/Login";
import {useAuth} from "./components/OnFlow/AuthContext";
import './assets/css/index.css';
import Gallery from "./Gallery";
import Collection from "./Collection";
import Images2D from "./Images2D";
import Home from "./components/Home";
import About from './components/About';
import PricingAI from './components/PricingAI';
import PricingSDK from './components/PricingSDK';
import ProductAI from './components/ProductAI';
import ProductSDK from './components/ProductSDK';
import NotFound from './components/NotFound';
import Faq from './components/Faq';
import BlogPosts from './components/BlogPosts.jsx';
import BlogPage from './components/BlogPage.jsx';
import Contact from './components/Contact';
import DashboardMain from './components/Dashboard/Main';
import DashboardSDK from './components/DashboardSDK';
import DashboardCollection from './components/DashboardCollection';
import ExportingAnimations from './pages/use-cases/ExportingAnimations.jsx';
import EditingAnimations from './pages/use-cases/EditingAnimations.jsx';
import MobileAugmentedReality from './pages/use-cases/MobileAugmentedReality.jsx';
import Terms from './pages/Terms.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';
import Animation from "./Animation";
import Assets3D from "./Assets3D";
import PlayAnimation from "./PlayAnimation";
import AvatarCreator from "./AvatarCreator";
import ReadyPlayerMe from "./ReadyPlayerMe";

require('dotenv').config();

export default function App() {

  const {logOut} = useAuth();
  const userObj = JSON.parse(getUserSession());
  if (userObj) {
    const currentDate = new Date();
    const expireDate = new Date(userObj.expire_date);
    // console.log(`${currentDate} > ${expireDate}`);
    if (currentDate.getTime() > expireDate.getTime()) {
      logOut();
    }
  }

  return (
    <div className="App">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_KEY}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          useEnterprise="true"
          scriptProps={{
            async: true, // optional, default to false,
            defer: true, // optional, default to false
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Animation/>}/>
              <Route path="/animation" element={<Navigate to={'/'}/>}/>
              <Route path="/intro" element={<Home/>}/>
              <Route path="/2d-image" element={<Images2D/>}/>
              <Route path="/avatar-creator" element={<AvatarCreator/>}/>
              <Route path="/3d-assets" element={<Assets3D/>}/>
              <Route path="/animation/:assetId" element={<PlayAnimation/>}/>
              <Route path="/gallery" element={<Gallery/>}/>
              <Route path="/collection" element={<Collection/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/pricing/sdk" element={<PricingSDK/>}/>
              <Route path="/pricing/ai" element={<PricingAI/>}/>
              <Route path="/product/sdk" element={<ProductSDK/>}/>
              <Route path="/product/ai" element={<ProductAI/>}/>
              <Route path="/use-cases/exporting-animations" element={<ExportingAnimations/>}/>
              <Route path="/use-cases/editing-animations" element={<EditingAnimations/>}/>
              <Route path="/use-cases/mobile-augment-reality" element={<MobileAugmentedReality/>}/>
              <Route path="/contact" element={<Contact/>}/>
              <Route path="/faq" element={<Faq/>}/>
              <Route path="/dashboard/:page" element={<DashboardMain/>}/>
              <Route path="/dashboard/collection" element={<DashboardCollection/>}/>
              <Route path="/dashboard/sdk" element={<DashboardSDK/>}/>
              <Route path="/terms" element={<Terms/>}/>
              <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
              <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
              <Route path="/blog" element={<BlogPosts/>}/>
              <Route path="/blog/:page" element={<BlogPage/>}/>
              <Route path="/readyplayerme" element={<ReadyPlayerMe/>}/>

            </Routes>
          </BrowserRouter>
        </GoogleReCaptchaProvider>
      </GoogleOAuthProvider>
    </div>
  );
}
