import React, {useEffect, useState} from "react";
import {GetUserBalance, getUserSession} from "./Login";
import axios from "axios";

require('dotenv').config();

export default function CreditButton(props) {
  const [userCredits, setUserCredits] = useState(0);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (getUserSession()) {
      setIsLogin(true);
      GetUserBalance().then(result => {
        if (result && result.credits) setUserCredits(result.credits);
      });
    }
  }, []);

  useEffect(() => {
    if (props.refreshCredit) {
      const GetUserBalance = async () => {
        axios.get(process.env.REACT_APP_API_ADDRESS + `/accounts/${process.env.REACT_APP_ACCOUNT_ID}/balance`,
          {withCredentials: true,}).then(res => {
            const {data} = res;
            const {credits} = data;
            setUserCredits(credits);
        }).catch(err => {
          return err;
        });
      }
      GetUserBalance().catch(console.error);
      props.setRefreshCredit(false);
    }
  }, [props.refreshCredit])

  return (
    <>
      {isLogin && <div className={'row justify-content-end py-2'}>
        <div className={'col-12 col-lg-3 text-center'}>
          <button className={'btn-credit px-2'}><strong>{userCredits} Credits</strong></button>
        </div>
      </div>}
    </>
  )
}
