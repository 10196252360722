import axios from "axios";

export const searchProducts = async (search) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/admin/stripe/products/search/${search}`, {withCredentials: true});
  let products = [];
  for (let product of data) {
    product.image = product.images.pop();
    products.push(product);
  }
  return products;
}

export const getProduct = async (id) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/admin/stripe/products/${id}`, {withCredentials: true});
  data.image = data.images.pop();

  return data;
}

export const getUserSubscriptions = async (search) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/admin/stripe/subscriptions`, {withCredentials: true});

  return data;
}

export const checkout = async (prices) => {
  let purchases = [];
  for (let price of prices) {
    if (price.recurring.usage_type !== 'metered') {
      purchases.push({
        price: price.id,
        quantity: 1, // non metered billing requires a quantity
      });
    } else {
      purchases.push({
        price: price.id,
      });
    }
  }

  console.log('purchases???', purchases);

  const {data} = await axios.post(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/admin/stripe/create-checkout-session`,
    {prices: purchases},
    {withCredentials: true}
  );

  return data.url;
}

export const getAdvancedPlan = async () => {
  const plans = await searchProducts("Advanced Plan")
  const advancedPlan = plans.filter((plan) => plan.name === 'Advanced Plan').pop();

  return advancedPlan;
}

export const getStandardPlan = async () => {
  const plans = await searchProducts("Standard Plan")
  const standardPlan = plans.filter((plan) => plan.name === 'Standard Plan').pop();

  return standardPlan;
}

export const cancelSubscription = async (subscriptionId) => {
  const { data } = await axios.delete(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/admin/stripe/subscriptions/${subscriptionId}`, {withCredentials: true});

  return data;
}