import Nav from "./Nav";
import Footer from "./Footer";
import AssetCardBlog from "./AssetCardBlog";
import content from "../assets/data/content";

  /*
  * Pull json posts exported from Krikey.com GHOST blog. Format post data correctly before serving.
  */
export const getPosts = () => {
  let posts =  content.db[0].data.posts.filter((p)=> {
    return (p.type === "post" && p.status === "published" && p.visibility === "public"); // only use public blog posts that have been published
  });
  for (let p of posts) {
    let d = new Date(p.created_at);
    let image_name = p.feature_image.split('/').pop();
    p.feature_image = `${process.env.REACT_APP_CDN_PATH}/krikey-ai/blog/${image_name}`;
    p.html = p.html.replace(/__GHOST_URL__\/content\/images\/2022\/(0*[1-9][0-9]*)/g,`${process.env.REACT_APP_CDN_PATH}/krikey-ai/blog`);
    p.html = p.html.replace(/__GHOST_URL__\/content\/images\/size\/w(0*[1-9][0-9]*)\/2022\/(0*[1-9][0-9]*)/g,`${process.env.REACT_APP_CDN_PATH}/krikey-ai/blog`);
    p.html = p.html.replace(/kg-image/g,"kg-image img-fluid");
    p.html = p.html.replace(/pride2.webp" width="1469"/g,`pride2.webp" class="img-fluid"`); // custom because image did not have class to add img-fluid
    p.html = p.html.replace(/pride3.webp" width="1199"/g,`pride3.webp" class="img-fluid"`); // custom because image did not have class to add img-fluid
    p.created_at_formatted = d.toLocaleString('default', { month: 'long' })+", "+d.getDay()+" " +d.getFullYear();
  }
  posts = posts.reverse(); // newest blogs first

  return posts;
}

export default function BlogPosts(props) {
  const posts = getPosts();
  console.log(posts);

  return (
    <div>
      <header className="">
        <Nav/>
      </header>

      <div className="container">
        <div className="row">
          <div className="col-12 fw-bolder px-5 pb-5 fs-5">
            <div className="container py-5">

              <div className="row">
                {posts.map((post, index) => (
                  <AssetCardBlog key={post.id} {...post} />
                ))}
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
