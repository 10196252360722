import Nav from "../../components/Nav";
import Footer from "../../components/Footer";

export default function ExportingAnimations(props) {
  return (
    <div>
      <header className="">
        <Nav location="usecases"/>
      </header>

      <div className="container text-center text-lg-start">
        <div className="row p-5">
          <div className="col-12">
            <h1 className="fw-bolder text-center">Exporting AI Animations</h1>
          </div>
          <div className="col-12 p-1 p-lg-5">

            <p className="fs-3 text-secondary pb-2">Our animation tool takes 5 minutes to generate a single animation. These can be instantly exported to build out 3D human characters for games or animated films.</p>
            <div className="row border-heavy-left-green bg-color-gray rounded-5 py-4 pe-4">
              <div className="col-1 text-end" style={{paddingRight:'0rem'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-circle color-green" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                </svg>
              </div>
              <div className="col-11 fs-6 text-start">
                <div className="pb-2">
                  To begin using Krikey's tools, please visit krikey.ai. You can sign up for an account and will receive 5 free credits to start. These can be used towards asset generation.
                </div>
                <div className="pb-2">
                  Our SDKs & APIs can also be accessed via krikey.ai. There is a 7-day free trial for all Krikey tools.
                </div>
                <div>
                  Questions? Please email us at support@krikey.com!
                </div>
              </div>
            </div>
          
            <h3 className="fw-bolder pt-5">Generating Assets</h3>
            <p className="fs-5 pb-3">Our tool generates animations from text prompts. Pleae see our detailed guide for how to create text prompts that will yield great animations. </p>
            
            <div className="row border-left-green rounded-5 py-4 pe-4" style={{border:'1px solid #75818f'}}>
              <div className="col-1 text-end" style={{paddingRight:'0rem'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-gear color-fancy-gray" viewBox="0 0 16 16">
                  <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
                  <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
                </svg>
              </div>
              <div className="col-11 fs-6 text-center text-lg-start ">
                <a className="fs-5 fw-bolder color-fancy-gray" target="_target" href="https://krikey.gitbook.io/krikey-ai/JBrm9ZNMHNyW43c3S73h/generative-assets/prompt-engineering">
                  Prompt Engineering
                </a>
              </div>
            </div>
            <p className="fs-5 py-3">Once you have generated your animations, they can easily be download for use in any 3D engine or editing tool. </p>
            
            <h3 className="fw-bolder py-3">Step 1: Log In. Visit your collection from the top navigation bar.</h3>
            <img className="img-fluid py-3" src="https://cdn.krikeyapp.com/krikey.xyz/images/use-cases/MyCollection.gif" />

            <h3 className="fw-bolder py-3">Step 2: Head to the avatar animations section. You should see all your animations. The video of the animation can be played from your collection</h3>
            <img className="img-fluid py-3" src="https://cdn.krikeyapp.com/krikey.xyz/images/use-cases/AvatarsDashboard.gif" />

            <h3 className="fw-bolder py-3">Step 3: Click on the two dots on the animation of interest. You can download the fbx file + animated rig, avatar video, or both.</h3>
            <img className="img-fluid py-3 pb-5" src="https://cdn.krikeyapp.com/krikey.xyz/images/use-cases/DownloadRig.gif" />

            <div className="row border-heavy-left-blue  bg-color-gray rounded-5 py-4 pe-4">
              <div className="col-1 text-end" style={{paddingRight:'0rem'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-circle color-blue" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                </svg>
              </div>
              <div className="col-11 fs-6 text-start">
                <div className="pb-2">
                  Once downloaded, the fbx file can be imported into any 3d engine. The rig can be separated from it and added onto another human character. Please see the Editing AI Animations page for more details on this. 
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
