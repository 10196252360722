export default function AssetCardAnimation(props) {
  return (
    <div className="col-12 col-lg-3 p-lg-3 p-5">
      <a className="btn" href={`/animation/${props.id}`} >
      <div className="card nft-card">
        <div className="video-rounded-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" style={{zIndex:100}} class="bi bi-play-circle-fill tile-play color-blue" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
          </svg>
          <img className={`card-img-top img-fluid tile`} src='https://cdn.krikey.ai/krikey-ai/img/placeholder3.webp'  />
        </div>
        <div className="card-body" style={{backgroundColor: 'rgb(255, 255, 255)'}}>
          <div className="row">
            <div className="col-9 text-secondary text-start">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
              </svg>
              {props.name}
            </div>
            <div className={`col-3 text-end`}>
            </div>
          </div>
        </div>
      </div>
      </a>
    </div>
  );
}
