import Nav from './Nav';
import Footer from './Footer';
import '../index.css';
import '../assets/css/home.css';
import '../assets/css/flip-countdown.css';
import React from "react";
import {Image} from "react-bootstrap";
import CreditButton from "./CreditButton";

export default function Home() {

  return (
    <div className={'bg-black'}>
      <header className="bg-cover-solar-pups">
        <Nav location="product/ai" openAuth={false}/>
      </header>

      <div className='container p-3'>
        <div className='container-fluid py-4 px-3 bg-black' style={{minHeight: '8vh'}}/>

        <CreditButton/>

        <div className={'row justify-content-center'}>
          <div className={'col-12 text-center pt-2'}>
            <p className={'font-bold text-white home-banner-title-1'}>Generative Gaming Assets</p>
            <p className={'text-white home-banner-title-2'}>Use AI to generate 2d, 3d, and animated game assets on or off-chain</p>
          </div>
          <div className={'col-12 text-center'}>
            <div className={'row justify-content-center'}>
              <div className={'col-7 col-lg-3 mt-3'} onClick={() => window.location = '/2d-image'} style={{cursor: 'pointer'}}>
                <Image src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/images-2d.webp`} fluid={true}/>
              </div>
              <div className={'col-7 col-lg-3 mt-3'} onClick={() => window.location = '/avatar-creator'} style={{cursor: 'pointer'}}>
                <Image src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/avatar-customization.webp`} fluid={true}/>
              </div>
              <div className={'col-7 col-lg-3 mt-3'} onClick={() => window.location = '/'} style={{cursor: 'pointer'}}>
                <Image src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/animated-avatars.webp`} fluid={true}/>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid py-4 px-3 bg-black' style={{minHeight: '13vh'}}/>
      </div>
      <Footer/>
    </div>
  );
}
