import {useEffect, useState} from "react";
import {getStandardPlan, getAdvancedPlan, searchProducts} from "../../utils/Stripe";
import BannerBuy from "./BannerBuy";
import BannerCart from "./BannerCart";
import ShopCard from "./ShopCard";

export default function Api(props) {
  useEffect(() => {
    const loadProducts = async () => {
      let products = [];
      const plan = JSON.parse(localStorage.getItem('plan'));
      let purchasedIds = props.purchasedProducts.map((purchased)=> purchased.id);
      let advancedPlan = await getAdvancedPlan();

      if (plan.type === 'advanced' || purchasedIds.includes(advancedPlan.id)) {
        products = await searchProducts("Advanced Plan API Upgrade (5M to 10M)");
      } else {
        products = await searchProducts("Standard Plan API Upgrade (2M to 4M)");
      }

      products = products.filter((p) => p.name.search("Metering") === -1); // remove Metering product
      for (let product of products) {
        product.prices = product.prices.filter((price) => price.recurring.interval === plan.interval); // remove prices that don't match interval
        product.alreadyPurchased = purchasedIds.includes(product.id);
      }

      console.log('>> api', products);
      props.setProducts(products);
    }
    loadProducts();
  }, [props.purchasedProducts]);

	return (
    <div className="row">
      <div className="col-0 col-md-2"></div>
      <div className="col-12 col-md-10">
        <BannerCart title="" cartCount={props.cartCount} />
        <h6 className="pt-3 px-3">Upgrade API Calls</h6>
        <div className="row">
          {props.products.map((product) =>
            <ShopCard
              key={product.id}
              image={product.image}
              title={product.name}
              description={product.description}
              prices={product.prices}
              hideSDK="d-none"
              addToCart={props.addToCart}
              removeFromCart={props.removeFromCart}
              discountedCount={props.discountedCount}
              isDiscountable={false}
              alreadyPurchased={product.alreadyPurchased}
            />
          )}
        </div>
      </div>
      <BannerBuy runCheckout={props.runCheckout} />
    </div>
  );
}