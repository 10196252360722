import '../assets/css/blog.css';

export default function AssetCardBlog(props) {
    return (
      <div className="col-12 col-lg-4 py-3 p-lg-3">
        <div className="card nft-card shadow">
          <a href={`/blog/${props.slug}`}>
            <img className="card-img-top nft-image blog-image" src={props.feature_image} />
            <div className="card-body" style={{backgroundColor: 'rgb(255, 255, 255)'}}>
              <div className="row">
                <div className="col-9 text-secondary text-start" style={{minHeight:'6rem'}}>
                  {props.title}
                </div>
                <div className="col-3 text-end">
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }
