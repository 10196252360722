import {useParams} from "react-router-dom";
import {getPosts} from "./BlogPosts.jsx"
import Nav from "./Nav";
import Footer from "./Footer";

export default function BlogPage(props) {
  let post = {
    title: "Blog Post Not Found",
    url: "",
    description: "",
    body: "<h3 class='p-5 text-center'>Blog Post Not Found</h3>"
  }

  const posts = getPosts();
  const { page } = useParams();
  for (let p of posts) {
    if (p.slug === page) {
      post = p; 
    }
  }

  return (
    <div>
      <header className="">
        <Nav/>
      </header>

      <div className="row p-4 p-lg-5">
        <div className="col-0 col-lg-2"></div>
        <div className="col-12 col-lg-8">
          <h1 className="fw-bolder color-aqua">{post.title}</h1>
          <p>{post.created_at_formatted}</p>
          <img className="py-3 img-fluid" src={post.feature_image} />
          <div style={{fontSize:'1.4rem'}} dangerouslySetInnerHTML={{ __html: post.html }}></div>
        </div>
        <div className="col-0 col-lg-2"></div>
      </div>

      <Footer/>
    </div>
  );
}
