import {useEffect, useState} from "react";
import NavAuthDropdown from "./NavAuthDropdown";
import Login, {getUserSession} from "../Login";
import '../../assets/css/nav.css';

export default function NavDashboard(props) {
  const [user, setUser] = useState({'email':''});
  const [showLogin, setShowLogin] = useState('');

  useEffect(() => {
    const userObj = JSON.parse(getUserSession()) ?? '';
    if (userObj) {
      setUser(userObj);
      setShowLogin('d-none');
    }
  }, []);

  return (
    <header className="sticky-top shadow-sm bg-light" style={{borderBottom: '1px solid #dadbe9', paddingBottom: '0.050rem', paddingTop:'0.250rem'}}>
      <div className="row">
        <div className="col-7 col-lg-3 pt-2">
          <span className={`navbar-light ps-1 d-md-none`}>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </span>

          <a className="navbar-brand px-1 px-lg-3" href="/">
            <img src="https://cdn.krikeyapp.com/krikey.xyz/images/logo.png" alt="" width="30" height="30" />
            <span className="fw-bolder" style={{color:'#349FCE',fontSize:'1.5rem'}}>rikey.ai</span>
          </a>
        </div>
        <div className="col-5 col-lg-9 text-end py-2">
          <div className="dropdown ms-auto">
            <span className={`${showLogin}`}>
              <Login id='navLogin' {...props}/>
            </span>
            <NavAuthDropdown email={user.email} location={props.location} />
          </div>
        </div>
      </div>
    </header>
  );
}
