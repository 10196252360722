import Nav from './components/Nav';
import Footer from './components/Footer';
import './index.css';
import './assets/css/home.css';
import './assets/css/flip-countdown.css';
import React, {useEffect, useRef, useState} from "react";
import {GetUserBalance, getUserSession, isDeveloper} from "./components/Login";
import {FaFacebookF, FaPaperPlane, FaTwitter, FiLink2, RiListSettingsFill} from "react-icons/all";
import {Form, Image, Overlay, Tooltip} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import axios from "axios";
import ProgressBar from 'react-bootstrap/ProgressBar';

import FormData from "form-data";
import PaymentModal from "./components/Modals/PaymentModal";
import PurchaseCreditsModal from "./components/Modals/PurchaseCreditsModal";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CreditButton from "./components/CreditButton";
import ErrorModal from "./components/Modals/ErrorModal";
import {EmailShareButton, FacebookShareButton, TwitterShareButton} from "react-share";
import {ReplaceCDNURL} from "./utils/CDN";

const DIFFUSIONS = ['ddim', 'plms', 'k_euler', 'k_euler_ancestral', 'k_heun', 'k_dpm_2', 'k_dpm_2_ancestral', 'k_lms'];
const ENGINES = ['stable-diffusion-v1', 'stable-diffusion-v1-5'];
const ARTSTYLES = ['impressionism'];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 5
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 4
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1
  }
};
const text = `Check out my AI generated art from Krikey AI, and try your own now on krikey.ai!`;

export default function Images2D(props) {
  const [openAuth, setOpenAuth] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [width, setWidth] = useState(512);
  const [height, setHeight] = useState(512);
  const [cfg_scale, setCfg_scale] = useState(7);
  const [steps, setSteps] = useState(10);
  const [samples, setSamples] = useState(1);
  const [artStyle, setArtStyle] = useState('');
  const [diffusion, setDiffusion] = useState(DIFFUSIONS[6]);
  const [engine, setEngine] = useState(ENGINES[2]);
  const [seed, setSeed] = useState('');
  const [disableSeed, setDisableSeed] = useState(true);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentModalError, setPaymentModalError] = useState({isShow: false, description: ''});
  const [showPurchaseCreditsModal, setShowPurchaseCreditsModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showProgressBar, setShowProgressbar] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  const [refreshCredit, setRefreshCredit] = useState(false);

  const target = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  // For adding on purpose error occurring
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const isDeveloperMode = queryParameters.get("isDeveloperMode");
    if (isDeveloperMode) {
      localStorage.setItem('isDeveloperMode', isDeveloperMode);
    }
  }, []);

  const StabilityRequest = async () => {
    const payload = {
      prompt,
      width,
      height,
      cfg_scale,
      steps,
      samples,
      // numberOfImages,
      // artStyle,
      diffusion,
      engine,
      is_nft: false,
      chain_id: process.env.REACT_APP_ONFLOW_NETWORK === 'mainnet' ? 90001 : 90002,
    };
    if (seed) {
      payload.seed = seed;
    }

    const data = new FormData();
    data.append('data', JSON.stringify(payload));
    return await axios.post(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/stability`, data, {
      headers: {'Content-Type': 'multipart/form-data'},
      withCredentials: true,
    }).then(function (res) {
      return res;
    }).catch(function (err) {
      return err.response.data;
    });
  }

  const Minting = async () => {
    // To show the progress bar
    setShowPaymentModal(false);
    setShowImage(false);
    setShowProgressbar(true);

    if (isDeveloper() === 'true') {
      setShowErrorModal(true);
      setShowProgressbar(false);
      setShowImage(false);
      return;
    }

    try {
      const results = await StabilityRequest();
      const {data} = results;
      setShowProgressbar(false);
      setShowImage(true);
      setRefreshCredit(true);
      setImageUrl(ReplaceCDNURL(data[0].image_url));
      setPreviewUrl(`${process.env.REACT_APP_API_ADDRESS}/stability/${data[0].id}`);
    } catch (e) {
      setShowErrorModal(true);
      setShowProgressbar(false);
      setShowImage(false);
    }
  }

  const HandleCreateButton = async () => {
    if (getUserSession()) {
      if (!prompt) {
        alert('You should put prompt!');
        return;
      }

      let {credits} = await GetUserBalance();
      if (credits > 0) {
        setShowPaymentModal(true);
      } else {
        setShowPurchaseCreditsModal(true);
      }
    } else {
      setOpenAuth(true);
    }
  }

  const ClosePurchaseCreditsModal = () => {
    setShowPurchaseCreditsModal(false);
  }

  const ClosePaymentModal = () => {
    setShowPaymentModal(false);
  }

  const CloseErrorModal = () => {
    setShowErrorModal(false);
  }

  const ClickCollection = () => {
    window.location = '/dashboard/collection?to=2d';
  }

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShowTooltip(false)
    }, 1100)
    return () => clearTimeout(timeout)
  }, [showTooltip])

  return (
    <div className="bg-cover-solar-pups">
      <header>
        <Nav location="2d-image" openAuth={openAuth}/>
      </header>

      <PurchaseCreditsModal modalShow={showPurchaseCreditsModal} closeHandler={ClosePurchaseCreditsModal} redirect={'2d-image'}/>
      <PaymentModal modalShow={showPaymentModal} error={paymentModalError} closeHandler={ClosePaymentModal} cost={samples} submitHandler={Minting}/>
      <ErrorModal modalShow={showErrorModal} title={'Asset Generation Error'} closeHandler={CloseErrorModal}/>

      <div className='container-fluid pt-0 px-3 bg-black' style={{minHeight: '85vh'}}>
        <div className='container-fluid py-4 px-3 bg-black' style={{minHeight: '9vh'}}/>

        <CreditButton refreshCredit={refreshCredit} setRefreshCredit={setRefreshCredit}/>

        <div className={'row w-100 justify-content-center align-self-center'}>
          <div className={'col-12 text-center pt-lg-5'}>
            <p className={'font-bold text-white image-banner-title-1'}>2D Images</p>
            <p className={'text-white image-banner-title-2'}>Use AI to generate 2D images on or off chain</p>
          </div>

          {!showProgressBar && <div className={'col-10'}>
            <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={3000} infinite={true}>
              <div><Image src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/2d-images/slide-1.png`} fluid={true}/></div>
              <div><Image src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/2d-images/slide-2.png`} fluid={true}/></div>
              <div><Image src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/2d-images/slide-3.png`} fluid={true}/></div>
              <div><Image src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/2d-images/slide-4.png`} fluid={true}/></div>
            </Carousel>
          </div>}

          {showProgressBar && <div className={'col-6 mt-3 pt-2'}>
            <ProgressBar animated now={100}/>
          </div>}

          {showImage && <div className={'col-10 col-lg-6 my-3 py-3 text-center'}>
            <Image src={imageUrl} alt={'Generated image'} fluid={true}/>
            <div className={'text-white mt-2'}>
              <button ref={target} style={{backgroundColor: 'transparent', border: 'none', padding: 0, font: 'inherit', color: 'inherit', cursor: 'pointer'}}>
                <FiLink2 style={{cursor: 'pointer'}} className={'mx-2'} size={25} onClick={() => {
                  setShowTooltip(true);
                  navigator.clipboard.writeText(imageUrl)
                }}/>
              </button>
              <Overlay target={target.current} show={showTooltip} placement="bottom">
                {(props) => (
                  <Tooltip id="overlay-example" {...props}>
                    Copied!
                  </Tooltip>
                )}
              </Overlay>
              <FacebookShareButton
                url={previewUrl}
                quote={text}>
                <FaFacebookF className={'mx-2'} size={25}/>
              </FacebookShareButton>
              <TwitterShareButton
                url={previewUrl}
                title={text}>
                <FaTwitter className={'mx-2'} size={25}/>
              </TwitterShareButton>
              <EmailShareButton
                subject={text}
                body={text}
                url={previewUrl}
                openShareDialogOnClick={true} onClick={e => e.preventDefault()}
              >
                <FaPaperPlane className={'mx-2'} size={25}/>
              </EmailShareButton>

              <button onClick={ClickCollection} className={'text-white font-normal'} style={{background: 'black', border: '1px solid', borderRadius: '5px'}}>VIEW IN COLLECTION</button>
            </div>
          </div>}

          <div className={'col-12 text-center mt-3 pt-2'}>
            <p className={'text-white home-banner-title-3'}>Describe what you want to see</p>
            <div className={'row justify-content-center'}>
              <div className={'col-10 col-md-10 col-lg-6 ms-2 pe-0'}>
                <input type="text" className="form-control home-banner-input text-white" placeholder={'E.g. Dog dressed for Indian Wedding'} value={prompt} disabled={showProgressBar} onChange={(e) => setPrompt(e.target.value)}/>
              </div>
              <div className={'col-1'} style={{width: '10px'}}>
                <RiListSettingsFill style={{color: '#FFFFFF', cursor: 'pointer'}} size={35} onClick={() => {
                  setOpenSettings(!openSettings);
                }}/>
              </div>
            </div>
          </div>

          <div className={'row justify-content-center pt-2 pb-lg-5'}>
            <div className={'col-10 col-lg-6 p-0 pt-2 pt-lg-0 pw-lg-2 text-center'}>
              <button className={`${showProgressBar ? 'btn-disabled' : ''} btn-normal btn-size-lg text-white font-normal`} disabled={showProgressBar} onClick={HandleCreateButton}>Generate</button>
            </div>
          </div>

          {openSettings && <div className={'py-3 my-3'}>
            <div className={`row w-100 justify-content-center`}>
              <div className={'col-10 col-lg-3'}>
                <Form>
                  <Form.Label className={'text-white'}>
                    <strong>Width: {width} px</strong>
                    <p className={'my-2'}>The width of the generated image</p>
                  </Form.Label>
                  <RangeSlider
                    value={width}
                    onChange={e => setWidth(parseInt(e.target.value))}
                    size={'lg'}
                    step={64}
                    min={512}
                    max={1024}
                  />
                </Form>
              </div>
              <div className={'col-10 col-lg-3'}>
                <Form>
                  <Form.Label className={'text-white'}>
                    <strong>Height: {height} px</strong>
                    <p className={'my-2'}>The height of the generated image</p>
                  </Form.Label>
                  <RangeSlider
                    value={height}
                    onChange={e => setHeight(parseInt(e.target.value))}
                    size={'lg'}
                    step={64}
                    min={512}
                    max={1024}
                  />
                </Form>
              </div>
            </div>
            <div className={`row w-100 justify-content-center mt-3`}>
              <div className={'col-10 col-lg-3'}>
                <Form>
                  <Form.Label className={'text-white'}>
                    <strong>Cfg Scale: {cfg_scale}</strong>
                    <p className={'my-2'}>Cfg scale adjusts how much the image will be like your prompt. Higher values keep your image closer to your prompt.</p>
                  </Form.Label>
                  <RangeSlider
                    value={cfg_scale}
                    onChange={e => setCfg_scale(parseInt(e.target.value))}
                    size={'lg'}
                    step={1}
                    min={0}
                    max={20}
                  />
                </Form>
              </div>
              <div className={'col-10 col-lg-3 my-auto'}>
                <Form>
                  <Form.Label className={'text-white'}>
                    <strong>Steps: {steps}</strong>
                    <p className={'my-2'}>How many steps to spend generating (diffusing) your image</p>
                  </Form.Label>
                  <RangeSlider
                    value={steps}
                    onChange={e => setSteps(parseInt(e.target.value))}
                    size={'lg'}
                    step={1}
                    min={35}
                    max={150}
                  />
                </Form>
              </div>
            </div>
            <div className={`row w-100 justify-content-center mt-3`}>
              <div className={'col-10 col-lg-3'}>
                <Form>
                  <Form.Label className={'text-white'}>
                    <strong>Number of Images: {samples}</strong>
                    <p className={'my-2'}>To generate multiple images from one prompt.</p>
                  </Form.Label>
                  <RangeSlider
                    value={samples}
                    onChange={e => setSamples(parseInt(e.target.value))}
                    size={'lg'}
                    step={1}
                    min={1}
                    max={1}
                  />
                </Form>
              </div>
              <div className={'col-10 col-lg-3 my-auto'}>
                <Form>
                  <Form.Label className={'text-white'}>
                    <strong>Art Style</strong>
                  </Form.Label>
                  <Form.Select size="lg" value={artStyle} onChange={(e) => setArtStyle(e.target.value)}>
                    {ARTSTYLES.map(artStyle => {
                      return (<option key={artStyle} value={artStyle}>{artStyle}</option>)
                    })}
                  </Form.Select>
                </Form>
              </div>
            </div>
            <div className={`row w-100 justify-content-center mt-3`}>
              <div className={'col-10 col-lg-3 my-auto'}>
                <Form>
                  <Form.Label className={'text-white'}>
                    <strong>Model</strong>
                  </Form.Label>
                </Form>
                <Form.Select size="lg" value={engine} onChange={(e) => setEngine(e.target.value)}>
                  {ENGINES.map(engine => {
                    return (<option key={engine} value={engine}>{engine}</option>)
                  })}
                </Form.Select>
              </div>
              <div className={'col-10 col-lg-3'}>
                <Form>
                  <Form.Label className={'text-white'}>
                    <strong>Sampler</strong>
                    <p className={'my-2'}>The diffusion sampling method. Cannot be modified if CLIP Guidance or an inpainting model is used.</p>
                  </Form.Label>
                </Form>
                <Form.Select size="lg" value={diffusion} onChange={(e) => setDiffusion(e.target.value)}>
                  {DIFFUSIONS.map(diffusion => {
                    return (<option key={diffusion} value={diffusion}>{diffusion}</option>)
                  })}
                </Form.Select>
              </div>
            </div>
            <div className={`row w-100 justify-content-center mt-3`}>
              <div className={'col-10 col-lg-3 my-auto'}>
                <Form>
                  <Form.Label className={'text-white'} style={{width: '300px'}}>
                    <strong>{'Seed  '}<Form.Check className={'d-inline'} type="switch" id="custom-switch" onChange={(e) => {
                      setDisableSeed(!disableSeed);
                    }}/></strong>
                  </Form.Label>
                  <input type="text" placeholder={'Random Seed'} className="form-control home-banner-input text-white"
                         disabled={disableSeed}
                         onChange={(e) => setSeed(e.target.value)}
                         onKeyPress={(event) => {
                           if (!/[0-9]/.test(event.key)) event.preventDefault();
                         }}/>
                </Form>
              </div>
              <div className={'col-10 col-lg-3'}/>
            </div>
          </div>}

          <div className='container-fluid py-4 px-3 bg-black' style={{minHeight: '9vh'}}/>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
