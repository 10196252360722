export default function Spinner(props) {
  return (
    <div id={props.id} className="d-flex justify-content-center p-5">
      <div className="spinner-grow krikey-green" role="status">
        <span className="sr-only"/>
      </div>
      <div className="spinner-grow krikey-pink" role="status">
        <span className="sr-only"/>
      </div>
      <div className="spinner-grow krikey-yellow" role="status">
        <span className="sr-only"/>
      </div>
      <div className="spinner-grow krikey-blue" role="status">
        <span className="sr-only"/>
      </div>
      <div className="spinner-grow text-light" role="status">
        <span className="sr-only"/>
      </div>
    </div>
  );
}
