import {useEffect, useState} from "react";
import Login, {getUserSession} from "./Login";
import NavAuthDropdown from "./Nav/NavAuthDropdown";
import '../assets/css/nav.css';

export default function Nav(props) {
  const activeIntro = (props.location === "intro") ? "fw-bolder" : "";
  const activeAbout = (props.location === "about") ? "fw-bolder" : "";
  const activePricing = (props.location === "pricing/sdk" || props.location === "pricing/ai") ? "fw-bolder" : "";
  const activeProduct = (props.location === "product/sdk" || props.location === "product/ai") ? "fw-bolder" : "";
  const activeUseCases = (props.location === "usecases") ? "fw-bolder" : "";
  const activeFaq = (props.location === "faq") ? "fw-bolder" : "";
  const activeBlog = (props.location === "blog") ? "fw-bolder" : "";

  const [isLogin, setIsLogin] = useState(!!getUserSession());
  const [user, setUser] = useState({'email':''});
  const [showLogin, setShowLogin] = useState('');

  useEffect(() => {
    const userObj = JSON.parse(getUserSession()) ?? '';
    if (userObj) {
      setUser(userObj);
      setShowLogin('d-none');
    }
  }, []);

  useEffect(() => {
    setIsLogin(true);
  }, [isLogin]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm py-0">
      <div className="container-fluid ps-1 ps-lg-3 pe-0">

        <button className="navbar-toggler mt-2 me-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"/>
        </button>

        <a className="navbar-brand" href="/">
          <img src="https://cdn.krikeyapp.com/krikey.xyz/images/logo.png" alt="" width="30" height="30"/>
          <span className="fw-bolder" style={{color: '#349FCE', fontSize: '1.5rem'}}>rikey.ai</span>
        </a>

        <div className="dropdown ms-auto pt-1 d-lg-none">
          <NavAuthDropdown email={user.email} location={props.location} />
        </div>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item px-3">
              <a className="nav-link color-link" href="/intro">
                <span className={`fs-6 ${activeIntro}`}>Intro</span>
              </a>
            </li>
            <li className="nav-item px-3 dropdown">
              <a className="nav-link color-link dropdown-toggle" href="#" id="productDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span className={`fs-6 ${activeProduct}`}>Product</span>
              </a>
              <ul className="dropdown-menu" aria-labelledby="productDropdown">
                <li><a className="dropdown-item" href="/product/sdk">SDKs</a></li>
                <li><a className="dropdown-item" href="/product/ai">AI</a></li>
              </ul>
            </li>
            <li className="nav-item px-3 dropdown">
              <a className="nav-link color-link dropdown-toggle" href="#" id="pricingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span className={`fs-6 ${activePricing}`}>Pricing</span>
              </a>
              <ul className="dropdown-menu" aria-labelledby="pricingDropdown">
                <li><a className="dropdown-item" href="/pricing/sdk">SDKs</a></li>
                <li><a className="dropdown-item" href="/pricing/ai">AI Credits</a></li>
              </ul>
            </li>
            <li className="nav-item ps-3">
              <a className="nav-link color-link" target="_blank" href="https://krikey.gitbook.io/krikey-ai/JBrm9ZNMHNyW43c3S73h/">
                <span className={`fs-6`}>Documentation</span>
              </a>
            </li>
            <li className="nav-item px-3 dropdown">
              <a className="nav-link color-link dropdown-toggle" href="#" id="productDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span className={`fs-6 ${activeUseCases}`}>Use Cases</span>
              </a>
              <ul className="dropdown-menu" aria-labelledby="productDropdown">
                <li><a className="dropdown-item" href="/use-cases/exporting-animations">Exporting AI Animations</a></li>
                <li><a className="dropdown-item" href="/use-cases/editing-animations">Editing AI Animations</a></li>
                <li><a className="dropdown-item" href="/use-cases/mobile-augment-reality">AI Animations in Mobile Augmented Reality</a></li>
              </ul>
            </li>
            <li className="nav-item px-3">
              <a className="nav-link color-link" href="/about">
                <span className={`fs-6 ${activeAbout}`}>About Us</span>
              </a>
            </li>
            <li className="nav-item ps-3">
              <a className="nav-link color-link" href="/faq">
                <span className={`fs-6 ${activeFaq}`}>FAQs</span>
              </a>
            </li>
          </ul>
          <ul className={`navbar-nav ms-auto ${showLogin} pb-2`}>
            <li className="nav-item nav-krikey justify-content-end py-2 py-md-0 ps-3" id="loginSection">
              <Login id='navLogin' {...props}/>
            </li>
          </ul>
        </div>

        <div className="dropdown ms-auto pt-1 d-none d-lg-block">
          <NavAuthDropdown email={user.email} location={props.location} />
        </div>

      </div>
    </nav>
  );
}
