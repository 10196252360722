import {useEffect, useState} from "react";

export default function PurchasedCard(props) {
  return (
    <div className="col-12 col-lg-4 py-3" key={props.id}>
      <div className="p-3 rounded shadow-sm shop-card" style={{minHeight:'15rem'}}>
        <div className="row px-1">
          <div className="col-2">
          <img className="img-fluid" src={props.image} />
          </div>
          <div className="col-9">
            <span className="fw-bold ps-1" style={{fontSize:'1.1rem'}}>
              {props.title}<sup className={`p-1 ${props.hideSDK}`} style={{fontSize:'0.7rem'}}>SDK</sup>
            </span>
          </div>
          <div className="col-1 text-end" style={{width:'1%'}}>
          </div>
        </div>
        <div className="text-end">
        </div>
        <div className="pt-3">
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
}