import {Modal} from "react-bootstrap";
import React from "react";

export default function RedirectModalWithButtons(props) {
  return (
    <Modal
      size="md"
      name='redirectModalWithButtons'
      show={props.modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={()=>{}}
      centered
      contentClassName='rounded-modal border-blue'
    >
      <Modal.Header className={'border-0 pb-0'} closeButton={false}/>
      <Modal.Body className='m-1 py-2'>
        <div className='row'>
          <div className='col-12 text-center'>
            <p className={'m-0 pb-4'} style={{fontSize: '1.6em'}}>
              Do you want to animate your avatar?
            </p>
          </div>
          <div className='col-6 text-center'>
            <button className={'btn-normal btn-size-md text-white font-normal'} onClick={() => window.location.href=props.yesRedirect}>Yes, Please!</button>
          </div>
          <div className='col-6 text-center'>
            <button className={'btn-normal btn-size-md text-white font-normal'} onClick={() => window.location.href=props.noRedirect}>No, Thanks</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
