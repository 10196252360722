import Nav from "../components/Nav";
import Footer from "../components/Footer";

export default function Terms(props) {
  return (
    <div>
      <header className="">
        <Nav/>
      </header>

      <div className="container">
        <div className="row">
          <div className="col-12 p-5">
            <h1 className="text-center fw-bolder color-aqua">Terms of Service</h1>
          </div>
          <div className="col-12 fw-bolder px-5 pb-5 fs-5">
            <div className="container py-5">
              <div className="row">
                <div className="col-1"/>
                <div className="col-10" style={{fontSize: '1.2rem'}}>
                  <p><strong>Last Updated: January 16, 2023</strong></p><p>Please read these Terms of Service (“<strong>Terms</strong>”) and our Privacy Policy (“<strong>Privacy Policy</strong>”) carefully because they govern your use of our website located at [www.krikey.ai] (the “<strong>Site</strong>”) and our AI-powered animation generative services accessible via the Site offered by Krikey, Inc. (“<strong>Krikey</strong>”). To make these Terms easier to read, the Site, our services are
                  collectively called the “<strong>Services</strong>.”</p><p><strong>IMPORTANT NOTICE REGARDING ARBITRATION FOR U.S. USERS: WHEN YOU AGREE TO THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND KRIKEY THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 16 “DISPUTE RESOLUTION” BELOW FOR DETAILS REGARDING ARBITRATION.</strong></p><p><strong>1.</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <strong>Agreement
                  to Terms.</strong> By using our Services, you agree to be bound by these Terms. If you don’t agree to be bound by these Terms, do not use the Services. If you are accessing and using the Services on behalf of a company (such as your employer) or other legal entity, you represent and warrant that you have the authority to bind that entity to these Terms. In that case, “you” and “your” will refer to that entity.</p><p><strong>2.</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <strong>Privacy Policy.</strong> Please review our Privacy Policy, which also governs your use of the Services, for information on how we collect, use and share your information.</p><p><strong>3.</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <strong>Changes to these Terms or the Services.</strong> We may update the Terms from time to time in our sole discretion. If we do, we’ll let you know by posting the updated Terms on the Site and/or may also send other communications. It’s
                  important that you review the Terms whenever we update them or you use the Services. If you continue to use the Services after we have posted updated Terms it means that you accept and agree to the changes. If you don’t agree to be bound by the changes, you may not use the Services anymore. Because our Services are evolving over time we may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.</p><p>
                  <strong>4.</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <strong>Use of the Services.</strong> Subject to your compliance with these Terms (including the payment of all applicable fees), Krikey grants you a non-exclusive right to access and use the Services in accordance with these Terms. &nbsp;Our Services provide a proprietary platform that allows users to enter a text prompt (a “<strong>User Prompt</strong>”) to either generate a 2D image (“<strong>Generated 2D Image</strong>”)
                  or create ann real-time animation of a Krikey Avatar or a Krikey Model (“<strong>Service Animation</strong>”). &nbsp;For the purposes these Terms, “<strong>Krikey Avatar</strong>” means an avatar comprised of an avatar image from Krikey’s character catalog and the 3D model (each a “<strong>Krikey Model</strong>”); and we also refer to the Service Animation and the Krikey Avatar together as “<strong>Krikey Content</strong>”.</p><p>
                  <strong>5.</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <strong>Registration and Access</strong>. You may use the Services only if you are at least 18 years of age or older and capable of forming a binding contract with Krikey and not otherwise barred from using the Services under applicable law. To use certain features of the Services, you’ll need to register and create an account through certain third-party services such as Google (an “<strong>Third-Party Account</strong>”).
                  We’ll create your account by extracting from your Third-Party Account certain personal information such as your name and email address and other personal information that your privacy settings on the Third-Party Account permit us to access. It’s important that you provide us with accurate, complete and current account information and keep this information up to date. If you don’t, we might have to suspend or terminate your account. To protect your account, keep the account
                  details and password confidential, and notify us right away of any unauthorized use. You’re responsible for all activities that occur under your account.</p><p><strong>6.</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <strong>Fees and Payment.</strong></p><p><strong>(a)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  <strong>AI Asset Credits.</strong> Payments to generate a Generated 2D Asset or Service Animation are made by using the virtual currency purchased and redeemed on the Site
                  (each, an “<strong>AI Asset Credit</strong>”). You can purchase AI Asset Credits in packages on the Site (“<strong>Purchased Credits</strong>”), or you may be granted AI Asset Credits without payment of a fee when you register on the Site, purchase a package containing “bonus” AI Asset Credits or engage in certain other actions at Krikey’s sole discretion (“<strong>Free Credits</strong>”). All AI Asset Credits are saved in your account and automatically debited from your
                  account when you generate a Generated 2D Asset or Service Animation. [AI Asset Credits are non-transferable. As further described below, purchases of AI Asset Credits are non-refundable whether or not they are used.</p><p><strong>(b)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  <strong>Purchasing Credits. </strong>When you purchase AI Asset Credits (each, a “<strong>Transaction</strong>”), you &nbsp;expressly authorize us (or our third-party payment processor) to charge you for such
                  Transaction. We may ask you to supply additional information relevant to your Transaction, including your credit card number, the expiration date of your credit card and your email and postal addresses for billing and notification (such information, “<strong>Payment Information</strong>”). You represent and warrant that you have the legal right to use all payment method(s) represented by any such Payment Information. When you initiate a Transaction, you authorize us to provide
                  your Payment Information to third parties so we can complete your Transaction and to charge your payment method for the type of Transaction you have selected (plus any applicable taxes and other charges). You may need to provide additional information to verify your identity before completing your Transaction (such information is included within the definition of Payment Information). By initiating a Transaction, you agree to the pricing, payment and billing policies applicable
                  to such fees and charges, as posted or otherwise communicated to you. All payments for Transactions are non-refundable and non-transferable except as expressly provided in these Terms. All fees and applicable taxes, if any, are payable in U.S. dollars.</p><p><strong>(c)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  <strong>No Refunds; Cancelling Transactions; Escheat and Expiration.</strong> SUBJECT TO APPLICABLE LAW, YOUR PURCHASE OF AI ASSET CREDITS IS FINAL AND YOU WILL NOT BE ABLE
                  TO CANCEL THE PURCHASE AND/OR RECEIVE A REFUND OF YOUR ONE-TIME PAYMENT AT ANY TIME. But if something unexpected happens in the course of completing a Transaction, we reserve the right to cancel your Transaction for any reason; if we cancel your Transaction, we’ll refund any payment you have already remitted to us for such Transaction. Unused Purchased Credits may be subject to applicable U.S. state escheat law. Unused Free Credits expire one year after being granted to you by
                  Krikey. If you contact us to exercise your rights to terminate your account and delete your personal data, the AI Asset Credits remaining in your account will no longer be redeemable.</p><p><strong>7.</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <strong>Content.</strong></p><p><strong>(a)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  <strong>User Content</strong>. As used in these Terms, the term “<strong>User Content</strong>” means your User Prompts and the Generated 2D Images. As
                  between you and Krikey, and to the extent permitted by applicable law, &nbsp;you own your User Prompts and, subject to your compliance with these Terms, Krikey hereby assigns to you all of its right, title and interest in and to the Generated 2D Images(to the extent there is an ownable interest in such Generated 2D Images). &nbsp;You will ensure that the use of User Content will not violate any applicable law or the terms of Section 8. You are solely responsible for the
                  content, operation, maintenance, and use of your User Content.</p><p><strong>(b)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  <strong>Similarity of Content.</strong> Please note that User Prompts, and the Generated 2D Images and Service Animation generated from your User Prompts may not be unique across users and the Services may generate the same or similar output for Krikey or another user. For example, you may provide a prompt to generate a 2D image of a green apple on a beach and
                  receive a certain 2D image or provide a prompt to generate a Service Animation of an exercise combination. Other users may also enter similar prompts and receive similar or same 2D image or Service Animation. You understand and agree that similar or the same responses to input provided by you may be delivered to other users and in any event such output from the Services generated for other users is not considered part your User Content.</p><p>
                  <strong>(c)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  <strong>Permissions to Your User Content</strong>. You hereby grant to Krikey a non-exclusive, transferable, worldwide, royalty-free, license, with the right to sublicense, to use, copy, modify, create derivative works based upon, distribute, publicly display, and publicly perform (i) your User Prompts in connection with operating and providing the Services; and (ii) &nbsp;Generated 2D Images [TR1] for the purposes of marketing
                  and promotion of Krikey and its Services. By way of example, but not limitation, the rights you grant us under this Section include the right to watermark or otherwise tag all Generated 2D Images with our brand-identifying logos or other brand-marking to assist viewers of such images to identify the technology (our Services) used to generate such images.</p><p><strong>(d)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  <strong>Krikey Content.</strong> &nbsp;[TR2]</p><p>
                  <strong>(i)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  As between you and Krikey, Krikey retains all right, title, and interest in and to the Krikey Content. Subject to your compliance with these Terms, Krikey hereby grants to you a limited, non-exclusive, worldwide, royalty-free, revocable license, to use, copy, distribute, modify, creative derivative works of, perform and display the Krikey Content for (i) your own personal, non-commercial use (e.g., your own personal display or
                  use); and (ii) for your Commercial Use subject to Section 7(d)(ii). As used herein “<strong>Commercial Use</strong>” means any purpose that is commercial in nature, including without limitation, the creation, sale, and marketing of goods and services and tangible or intangible media, each that feature the Krikey Content. For example, as limited by these Terms, the rights granted to you in this Section 7(d)(i) allow you to post video clips of the Krikey Avatar on your social
                  media and use the 3D Model part of the Krikey Content within your game or other content experience.</p><p><strong>(ii)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  You may engage in Commercial Use of the Krikey Content until the amount of Accrued Revenue reaches one hundred thousand dollars ($100,000 (USD)) the (“<strong>Revenue Maximum</strong>”). Within ten (10) days of reaching the Revenue Maximum you must contact Krikey at support@krikey.com to request its commercial license
                  agreement and agree to the same in order for you to continue Commercial Use of the Krikey Content. As used herein, “<strong>Accrued Revenue</strong>” means the total amount of revenue generated in connection with, or as a result of, your use or exploitation of the Krikey Content, starting from the date of your first Commercial Use of the Krikey Content. Upon reaching the Revenue Maximum you will cease all Commercial Use of the Krikey Content and any Commercial Use of the Krikey
                  Content beyond the Revenue Maximum will be deemed a material breach of these Terms.</p><p><strong>(e)</strong></p><p><strong>(f)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  <strong>Related Rights. </strong>You understand and agree that the limited license granted to you in 7(d)(i) does not authorize you to remove, alter or obscure any digital watermarks, any coding or proprietary rights notices incorporated on or accompanying the Krikey Content. To the extent that you own or control
                  (presently or in the future) any intellectual property rights that may be necessary for Krikey to exercise of any ownership rights in the Krikey Content (“<strong>Related Rights</strong>”), you agree to and do hereby grant to Krikey, or will cause to be granted to Krikey, a worldwide, perpetual, irrevocable, non-exclusive, royalty-free and fully-paid up, assignable, sublicensable right and license to use, reproduce, modify, distribute, prepare derivative works of, perform
                  publicly, display publicly and otherwise exploit any such Related Rights to the extent necessary to enable Krikey’s exercise of any ownership rights in the Krikey Content.</p><p><strong>8.</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <strong>General Prohibitions</strong>. You agree not to do any of the following:</p><p><strong>(a)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Submit User Prompts or otherwise use the Services to generate any Krikey Content or Generated 2D Images that:
                  (i) infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would violate, any applicable national, federal, state, local or international law or regulation or would give rise to civil liability; (iii) generates or disseminates fraudulent, verifiably false, misleading or deceptive information and/or
                  content with the purpose of harming others; (iv) is defamatory, disparaging, harassing, threatening, obscene, pornographic, vulgar or offensive; (v) is intended to or has the effect of discriminating against or harming individuals or groups based on online or offline social behavior, known or predicted personal or personality characteristics, or legally protected characteristics or categories; (vi) is violent or threatening or promotes violence or actions that are threatening
                  to any person or entity; (vii) is for the purpose of exploiting, harming or attempting to exploit or harm minors in any way; (viii) exploits any of the vulnerabilities of a specific group of persons based on their age, social, physical or mental characteristics, in order to materially distort the behavior of a person pertaining to that group in a manner that causes or is likely to cause that person or another person physical or psychological harm; (ix) promotes illegal or
                  harmful activities or substances; (x) generates or disseminates personal identifiable information that can be used to harm an individual; (xi) is for fully automated decision making that adversely impacts an individual’s legal rights or otherwise creates or modifies a binding, enforceable obligation; (xii) provides medical advice and medical results interpretation; or (xiii) generates or disseminates information to be used for administration of justice, law enforcement,
                  immigration or asylum processes, such as predicting an individual will commit fraud/crime (e.g., by text profiling, drawing causal relationships between assertions made in documents, or indiscriminate and arbitrarily targeted use).</p><p><strong>(b)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Use, display, mirror or frame the Services or any individual element within the Services, Krikey’s name, any Krikey trademark, logo or other proprietary information, or the layout and design of
                  any page or form contained on a page, without Krikey’s express written consent;</p><p><strong>(c)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Access, tamper with, or use non-public areas of the Services, Krikey’s computer systems, or the technical delivery systems of Krikey’s providers;</p><p><strong>(d)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Attempt to probe, scan or test the vulnerability of any Krikey system or network or breach any security or authentication measures;</p><p>
                  <strong>(e)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by Krikey or any of Krikey’s providers or any other third party (including another user) to protect the Services;</p><p><strong>(f)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Attempt to access or search the Services or download content from the Services using any engine, software, tool, agent, device or mechanism
                  (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by Krikey or other generally available third-party web browsers;</p><p><strong>(g)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Use the Services, or any portion thereof, for any commercial purpose or for the benefit of any third party or in any manner not permitted by these Terms;</p><p><strong>(h)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Attempt to decipher,
                  decompile, disassemble, reverse engineer, engage in model extraction or stealing attacks or otherwise attempt to discover the source code or underlying components of models, algorithms, and systems of any of the software used to provide the Services (except to the extent such restrictions are contrary to applicable law or the licenses applicable to the open source software components that are incorporated into the Services);</p><p>
                  <strong>(i)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;</p><p><strong>(j)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Impersonate or misrepresent your affiliation with any person or entity;</p><p><strong>(k)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Violate any applicable law or regulation; or</p>
                  <p><strong>(l)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Encourage or enable any other individual to do any of the foregoing.</p><p><strong>9.</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <strong>Feedback</strong>. We appreciate feedback, comments, ideas, proposals and suggestions for improvements to the Services (“<strong>Feedback</strong>”). If you choose to submit Feedback, you agree that we are free to use it without any restriction or compensation to you.</p><p>
                  <strong>10.</strong> &nbsp; &nbsp; &nbsp;  <strong>Links to Third Party Websites or Resources.</strong> The Services may allow you to access third-party websites or other resources. We provide access only as a convenience and are not responsible for the content, products or services on or available from those resources or links displayed on such websites. You acknowledge sole responsibility for and assume all risk arising from, your use of any third-party resources.</p><p>
                  <strong>11.</strong> &nbsp; &nbsp; &nbsp;  <strong>Termination.</strong> We may suspend or terminate your access to and use of the Services, including suspending access to or terminating your account, at our sole discretion, at any time and without notice to you. You may cancel your account at any time by sending us an email at [support@krikey.com]. Upon any termination, discontinuation or cancellation of the Services or your account, the following Sections will survive: this
                  sentence on Termination, Fees and Payment (for payments due and owing to Krikey prior to the termination), User Content, Permission to Use User Content, Krikey Content, Related Rights, Feedback, Warranty Disclaimers, Indemnity, Limitation of Liability, Governing Law and Forum Choice, Dispute Resolution , General Terms, and the meaning of any defined terms.</p><p><strong>12.</strong> &nbsp; &nbsp; &nbsp;  <strong>Warranty Disclaimers.</strong> THE SERVICES AND ANY GENERATED 2D
                  IMAGES AND KRIKEY CONTENTARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT THE SERVICES OR ANY GENERATED 2D IMAGES OR KRIKEY CONTENTWILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR
                  ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY INFORMATION OR CONTENT ON THE SERVICES.</p><p><strong>13.</strong> &nbsp; &nbsp; &nbsp;  <strong>Indemnity.</strong> You agree to release, indemnify and hold Krikey and its officers, directors, employees and agents, harmless from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without
                  limitation, reasonable legal and accounting fees arising out of or in any way connected with (a) your access to or use of the Services and any Krikey Content, (b) your User Content, or (c) your violation of these Terms.</p><p><strong>14.</strong> &nbsp; &nbsp; &nbsp;  <strong>Limitation of Liability.</strong></p><p><strong>(a)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER KRIKEY NOR ITS SERVICE PROVIDERS INVOLVED IN CREATING, PRODUCING,
                  OR DELIVERING THE SERVICES AND ANY GENERATED 2D IMAGES OR KRIKEY CONTENTWILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED
                  ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT KRIKEY OR ITS SERVICE PROVIDERS HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</p><p><strong>(b)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL &nbsp;KRIKEY’S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE
                  TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES EXCEED THE AMOUNTS YOU HAVE PAID OR ARE PAYABLE BY YOU TO KRIKEY FOR USE OF THE SERVICES OR ONE HUNDRED DOLLARS ($100), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO KRIKEY, AS APPLICABLE.</p><p><strong>(c)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN KRIKEY AND YOU.</p><p>
                  <strong>15.</strong> &nbsp; &nbsp; &nbsp;  <strong>Governing Law and Forum Choice.</strong> These Terms and any action related thereto will be governed by the Federal Arbitration Act, federal arbitration law, and the laws of the State of California, without regard to its conflict of laws provisions. Except as otherwise expressly set forth in Section 16 &nbsp;“Dispute Resolution,” the exclusive jurisdiction for all Disputes (defined below) that you and Krikey are not required to
                  arbitrate will be the state and federal courts located in the County of San Mateo, California and you and Krikey each waive any objection to jurisdiction and venue in such courts.</p><p><strong>16.</strong> &nbsp; &nbsp; &nbsp;  <strong>Dispute Resolution.</strong></p><p><strong>(a)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Mandatory Arbitration of Disputes. We each agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination,
                  enforcement, interpretation or validity thereof or the use of the Services (collectively, “<strong>Disputes</strong>”) will be resolved <strong>solely by binding, individual arbitration and not in a class, representative or consolidated action or proceeding</strong>. You and Krikey agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of these Terms, and that you and Krikey are each waiving the right to a trial by jury or to participate in a
                  class action. This arbitration provision shall survive termination of these Terms.</p><p><strong>(b)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Exceptions. As limited exceptions to Section 16 &nbsp;above: (i) we both may seek to resolve a Dispute in small claims court if it qualifies; and (ii) we each retain the right to seek injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of our intellectual property rights.</p><p>
                  <strong>(c)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Conducting Arbitration and Arbitration Rules. The arbitration will be conducted by the American Arbitration Association (“<strong>AAA</strong>”) under its Consumer Arbitration Rules (the “<strong>AAA Rules</strong>”) then in effect, except as modified by these Terms. The AAA Rules are available at<a href="http://www.adr.org"> www.adr.org</a> or by calling 1-800-778-7879. A party who wishes to start arbitration must submit a
                  written Demand for Arbitration to AAA and give notice to the other party as specified in the AAA Rules. The AAA provides a form Demand for Arbitration at<a href="http://www.adr.org"> www.adr.org</a>.</p><p>Any arbitration hearings will take place in the county (or parish) where you live, unless we both agree to a different location. The parties agree that the arbitrator shall have exclusive authority to decide all issues relating to the
                  interpretation, applicability, enforceability and scope of this arbitration agreement.</p><p><strong>(d)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Arbitration Costs. Payment of all filing, administration and arbitrator fees will be governed by the AAA Rules, and we won’t seek to recover the administration and arbitrator fees we are responsible for paying, unless the arbitrator finds your Dispute frivolous. If we prevail in arbitration we’ll pay all of our attorneys’ fees and costs
                  and won’t seek to recover them from you. If you prevail in arbitration you will be entitled to an award of attorneys’ fees and expenses to the extent provided under applicable law.</p><p><strong>(e)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Injunctive and Declaratory Relief. Except as provided in Section 16(b) above, the arbitrator shall determine all issues of liability on the merits of any claim asserted by either party and may award declaratory or injunctive relief only in favor
                  of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party’s individual claim. To the extent that you or we prevail on a claim and seek public injunctive relief (that is, injunctive relief that has the primary purpose and effect of prohibiting unlawful acts that threaten future injury to the public), the entitlement to and extent of such relief must be litigated in a civil court of competent jurisdiction and not in
                  arbitration. The parties agree that litigation of any issues of public injunctive relief shall be stayed pending the outcome of the merits of any individual claims in arbitration.</p><p><strong>(f)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Class Action Waiver. <strong>YOU AND KRIKEY</strong> <strong>AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
                  PROCEEDING.</strong> Further, if the parties’ Dispute is resolved through arbitration, the arbitrator may not consolidate another person’s claims with your claims, and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is found to be unenforceable, then the entirety of this Dispute Resolution section shall be null and void.</p><p><strong>(g)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Severability. With the exception of any of
                  the provisions in Section 16(f) of these Terms (“<strong>Class Action Waiver</strong>”), if an arbitrator or court of competent jurisdiction decides that any part of these Terms is invalid or unenforceable, the other parts of these Terms will still apply.</p><p><strong>17.</strong> &nbsp; &nbsp; &nbsp;  <strong>General Terms.</strong></p><p><strong>(a)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Reservation of Rights. Except as expressly stated herein, Krikey and its licensors
                  exclusively own all right, title and interest in and to the Services, including all associated intellectual property rights. You acknowledge that the Services are protected by copyright, trademark, and other laws of the United States and foreign countries. You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services.</p><p><strong>(b)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Entire
                  Agreement. These Terms constitute the entire and exclusive understanding and agreement between Krikey and you regarding the Services, and these Terms supersede and replace all prior oral or written understandings or agreements between Krikey and you regarding the Services. If any provision of these Terms is held invalid or unenforceable by an arbitrator or a court of competent jurisdiction, that provision will be enforced to the maximum extent permissible and the other
                  provisions of these Terms will remain in full force and effect. Except where provided by applicable law in your jurisdiction, you may not assign or transfer these Terms, by operation of law or otherwise, without Krikey’s prior written consent. Any attempt by you to assign or transfer these Terms absent our consent or your statutory right, without such consent, will be null and void. Krikey may freely assign or transfer these Terms without restriction. Subject to the foregoing,
                  these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.</p><p><strong>(c)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Notices. Any notices or other communications provided by Krikey under these Terms will be given: (i) via email; or (ii) by posting to the Services. For notices made by email, the date of receipt will be deemed the date on which such notice is transmitted.</p><p><strong>(d)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  Waiver of
                  Rights. Krikey’s failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Krikey. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.</p><p>
                  <strong>18.</strong> &nbsp; &nbsp; &nbsp;  <strong>Acknowledgment of Beta Services</strong>. You acknowledge and agree that: (a) the Services may not operate properly, be in final form or be fully functional; (b) the Services may contain errors, design flaws or other problems; (c) it may not be possible to make the Services fully functional; (d) the information obtained using the Services may not be accurate; (e) use of the Services may result in unexpected results, loss of
                  data or communications, project delays or other unpredictable damage or loss; (f) Krikey is under no obligation to release any updates to, or to support or otherwise maintain, the Services; and (g) Krikey has the right to unilaterally to abandon development of the Services, at any time and without any obligation or liability to you including with respect to any User Content or otherwise.</p><p><strong>19.</strong> &nbsp; &nbsp; &nbsp;  <strong>Contact Information.</strong> If
                  you have any questions about these Terms or the Services, please contact Krikey at Krikey, Inc., 3000 Sand Hill Road, 4-100, Menlo Park, CA 94025, (650) 257-0835, or support@krikey.com.</p><p><strong>20.</strong> &nbsp; &nbsp; &nbsp;  <strong>Additional Terms Regarding Third Party Licenses</strong>.</p><p><strong>(a)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  The Services contain derivatives of Stable Diffusion, a machine-learning generative model, whose distribution is subject to
                  the use-based restrictions in paragraph 5 and Attachment A of the Stable Diffusion license released under the CreativeML Open RAIL-M license found at<a href="https://github.com/CompVis/stable-diffusion/blob/main/LICENSE"> https://github.com/CompVis/stable-diffusion/blob/main/LICENSE</a>. You agree to be bound by the terms contained in paragraph 5 and Attachment A of the CreativeML Open RAIL-M license.</p><p><strong>(b)</strong> &nbsp; &nbsp; &nbsp; &nbsp;  The Services contain
                  Autodesk® FBX® code developed by Autodesk, Inc. Copyright 2019 Autodesk, Inc. All rights, reserved. Such code is provided “as is” and Autodesk, Inc. disclaims any and all warranties, whether express or implied, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. In no event shall Autodesk, Inc. be liable for any direct, indirect, incidental, special, exemplary, or consequential
                  damages (including, but not limited to, procurement of substitute goods or services; loss of use, data, or profits; or business interruption) however caused and on any theory of liability, whether in contract, strict liability, or tort (including negligence or otherwise) arising in any way out of such code.</p>
                </div>
                <div className="col-1"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
