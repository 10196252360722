import {download} from "../utils/DownloadHandler";

export default function AssetCardImage(props) {
    return (
      <div className="col-12 col-lg-3 p-lg-3 p-5">
        <div className="card nft-card">
          <img className="card-img-top nft-image" src={props.img} alt="nft_image" />
          <div className="card-body" style={{backgroundColor: 'rgb(255, 255, 255)'}}>
            <div className="row">
              <div className="col-9 text-secondary text-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
                  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                </svg>
                {props.name}
              </div>
              <div className="col-3 text-end">
              <span className="dropdown-toggle remove-toggle-arrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
               :
              </span>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => download(props.img, `${props.name.replace(" ", "_")}.png`)}>Download</a>
                </li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
