import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import Spinner from "../Spinner";
import {RiErrorWarningFill} from "react-icons/all";

export default function ErrorModal(props) {
  return (
    <Modal
      size="md"
      name='errorModal'
      show={props.modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={props.closeHandler}
      centered
      contentClassName='rounded-modal border-blue'
    >
      <Modal.Header className={'border-0 pb-0'} closeButton={true}/>
      <Modal.Body className='m-1 py-2'>
        <div className='row'>
          <div className='col-12 text-center'>
            <RiErrorWarningFill size={35}/>
          </div>
          <div className='col-12 text-center'>
            <p className={'pt-2 font-bold'} style={{fontSize: '2em'}}>{props.title}</p>
          </div>
          <div className='col-12 text-center'>
            <p className={'m-0 pb-4'} style={{fontSize: '1.2em'}}>
              Oops something went wrong, you have not been charged for this generation. Please try your generation again. Reach out to us at <a href="mailto:email@example.com">support@krikey.com</a> with any questions!
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
