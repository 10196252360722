import {useEffect, useState} from "react";

export default function BannerCart(props) {
  const [showPill, setShowPill] = useState('d-none');
  useEffect(() => {
      props.cartCount > 0
        ? setShowPill('')
        : setShowPill('d-none');
  }, [props.cartCount]);

	return (
    <div className="row p-2" style={{backgroundColor:'#ffffff'}}>
    <div className="col-10">
      <h5>{props.title}</h5>
    </div>
    <div className="col-2 text-end">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cart color-blue pt-1" viewBox="0 0 16 16">
        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
      </svg>
      <span className={`translate-middle badge rounded-pill bg-danger ${showPill}`}>
        {props.cartCount}
      </span>
    </div>
  </div>
  );
}