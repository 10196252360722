import {cancelSubscription} from "../../utils/Stripe";

export default function Billing(props) {
  const cancel = async (subscriptionId, confirm) => {
    if (confirm) {
      const res = await cancelSubscription(subscriptionId);
      if (res.id === subscriptionId) {
        props.setLoadProducts([]); // refresh product loader
      }
    }
  }

	return (
    <div className="row">
      <div className="col-0 col-md-2"></div>
      <div className="col-12 col-md-10">

        <h4 className="p-3 color-fancy-gray">Your Subscriptions</h4>
        {props.subscriptions.map((s, i) =>
          <div key={s.id} className="p-2 color-fancy-gray">
            <div className="p-3 rounded shadow-sm shop-card">

              {s.status === 'canceled'
                ? <h5 className="text-decoration-line-through">Subscription #{props.subscriptions.length-i} CANCELED</h5>
                : <h5>Subscription #{props.subscriptions.length-i}</h5>
              }

              <div className="p-3">
                <h6>Products</h6>
                {s.products.map((p, i) =>
                  <div key={p.id} className="py-1">
                    <div>#{i+1} {p.name}</div>
                    <div>${p.price.unit_amount/100} / {p.price.recurring.interval}</div>
                  </div>
                )}
              </div>
              <div>
                <span className="pe-2 fw-bold">Created:</span>
                {(new Date(s.created*1000)).toLocaleString("en-us",{month: "long", day: "2-digit", hour: "numeric", minute: "2-digit"})}
              </div>
              <div>
                <span className="pe-2 fw-bold">Current Period:</span>
                {(new Date(s.current_period_start*1000)).toLocaleString("en-us", {month: "long", day: "2-digit"})}
                <span className="px-2">-</span>
                {(new Date(s.current_period_end*1000)).toLocaleString("en-us", {month: "long", day: "2-digit"})}
              </div>
              <div className="row text-center p-3">
                {s.trial_end > 0 &&
                <div className="col-12 p-3 rounded fw-bold" style={{backgroundColor:'#ededfb'}}>
                  Your trial ends on {(new Date((s.trial_end)*1000)).toLocaleString("en-us", {month: "long", day: "2-digit", year:"numeric"})}
                </div>
                }
              </div>
              <div className="row text-end pt-1">
                <div className="col-6"></div>
                  <div className="col-6">
                    {s.status === 'canceled'
                      ? <button href="#" className="btn color-blue fw-bold text-decoration-line-through" disabled>Cancel Subscription</button>
                      : <button href="#" className="btn color-blue fw-bold" onClick={()=>cancel(s.id, window.confirm('Are you sure you want to cancel this subscription?'))}>Cancel Subscription</button>
                    }
                  </div>
              </div>
            </div>
          </div>
        )}

        <div className={`text-center color-blue ${props.showLoader}`}>
          <strong className="px-3 fs-6">Fetching Subscriptions</strong>
          <div className="spinner-border spinner-border-sm" role="status">
          </div>
        </div>

      </div>
    </div>
  );
}