import {Modal} from "react-bootstrap";
import React, {useState, useEffect} from "react";

export default function CancelModal(props) {
  return (
    <Modal
      size="md"
      name='cancelModal'
      show={props.modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      // onHide={props.closeHandler}
      centered
      contentClassName='rounded-modal border-blue'
    >
      <Modal.Body className='m-1 py-2'>

        <div className="d-flex justify-content-center py-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 text-center'>
            <p className={'pt-2 font-bold'} style={{fontSize: '2em'}}>{props.title}</p>
          </div>
          <div className='col-12 text-center'>
            <p className={'m-0 pb-4'} style={{fontSize: '1.2em'}}>
              Your generation starts in {props.seconds} seconds
            </p>
          </div>
          <div className='col-3'></div>
          <div className='col-6 text-center'>
            <button className='btn-blue w-100 text-white btn-size-md' type='submit' onClick={() => {
              props.cancelRef.current = true;
            }} >
            <strong>Cancel</strong>
          </button>
          </div>
          <div className='col-3'></div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
