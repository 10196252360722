export const SetupAccountTx = `
import NonFungibleToken from 0xNonFungibleToken
import KrikeyAINFT from 0xKrikeyAINFT
import FungibleToken from 0xFungibleToken
import FlowToken from 0xFlowToken
import MetadataViews from 0xMetadataViews

transaction() {
  let capability1: Capability<&{NonFungibleToken.Receiver}>
  let capability2: Capability<&{NonFungibleToken.Provider}>

  prepare(acct: AuthAccount) {

      // setup collector
      // ***************
      let Public  = KrikeyAINFT.KrikeyAINFTPublicPath
      let Private = KrikeyAINFT.KrikeyAINFTPrivatePath
      let Storage = KrikeyAINFT.KrikeyAINFTStoragePath

      if acct.borrow<&KrikeyAINFT.Collection>(from: Storage) == nil {
          let collection <- KrikeyAINFT.createEmptyCollection()
          acct.save(<-collection, to: Storage)
          acct.link<&{NonFungibleToken.Receiver, KrikeyAINFT.CollectionPublic, MetadataViews.ResolverCollection}>(Public, target: Storage)
          acct.link<&{NonFungibleToken.Provider, KrikeyAINFT.CollectionPublic, MetadataViews.ResolverCollection}>(Private, target: Storage)
      }

      self.capability1 = acct.getCapability<&{NonFungibleToken.Receiver}>(Public)
      self.capability2 = acct.getCapability<&{NonFungibleToken.Provider}>(Private)
  }

  execute {
    if !self.capability1.check() {
      panic("no public capability found")
    }
    if !self.capability2.check() {
      panic("no private capability found")
    }
  }
}`;
