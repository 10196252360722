import Nav from './components/Nav';
import Footer from './components/Footer';
import './index.css';
import './assets/css/home.css';
import './assets/css/flip-countdown.css';
import React, {useEffect, useRef, useState} from "react";
import {GetUserBalance, getUserSession, isDeveloper} from "./components/Login";
import {Form, Image, Toast, ToastContainer} from "react-bootstrap";
import axios from "axios";
import ProgressBar from 'react-bootstrap/ProgressBar';
import MobileErrorModal from "./components/Modals/MobileErrorModal";
import PaymentModal from "./components/Modals/PaymentModal";
import CreditButton from "./components/CreditButton";

import {Unity, useUnityContext} from 'react-unity-webgl';
import CancelModal from "./components/Modals/CancelModal";
import ErrorModal from "./components/Modals/ErrorModal";
import Carousel from "react-multi-carousel";
import {BiRightArrowAlt, BsLightningCharge, MdOutlineLightMode, RiChatOffLine, RiErrorWarningLine, TfiReload} from "react-icons/all";
import Countdown from "react-countdown";
import PurchaseCreditsModal from "./components/Modals/PurchaseCreditsModal";
import RedirectModalWithButton from "./components/Modals/RedirectModalWithButton";
import RangeSlider from "react-bootstrap-range-slider";
import {getWebGLPath} from "./utils/WebGLHandler";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 3
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 3
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1
  }
};

const EXAMPLES = [
  'kickball_darkbg.gif',
  'cartwheel_darkbg.gif',
  'dance_darkbg.gif',
  'dribble_darkbg.gif',
  'handstand_darkbg.gif',
  'jumpingjacks_darkbg.gif',
  'kick_darkbg.gif',
  'moonwalk_darkbg.gif',
  'pitch_darkbg.gif',
  'pushup_darkbg.gif',
  'sit_darkbg.gif',
  'spin_darkbg.gif',
  'stretch_darkbg.gif',
  'swimming_darkbg.gif',
  'taichi_darkbg.gif',
  'walkcircle_darkbg.gif',
  'wave_darkbg.gif',
  'yoga_darkbg.gif',
  'zombie_darkbg.gif'
];

const PROGRESS_INFOS = {
  'ANIMATION_TRIGGERED': {percentage: 2, text: 'Capturing your prompt'},
  'GENERATION_STARTING': {percentage: 5, text: 'Generating your rig, this may take just a few moments'},
  'ANIMATION_ANIMATED': {percentage: 25, text: 'Polishing the animation, this may take a little bit'},
  'ANIMATION_SMPLIFIED': {percentage: 60, text: 'Converting the animation'},
  'FBX_CONVERTED': {percentage: 65, text: 'Re-exporting the animation'},
  'ANIMATION_GENERATED': {percentage: 70, text: 'Passing animation to the website'},
  'JENKINS_TRIGGERED': {percentage: 75, text: 'Uploading animation to website…this could take a little while!'},
  'DONE': {percentage: 100, text: 'Last step... happy building!'},
}

const WEBGL_PATH = '2023.03.14';
const GAME_ID = '3b10c79f-9658-4b08-95c6-e99597d2c645';

export default function Animation() {
  const {unityProvider, initialisationError, isLoaded, loadingProgression, addEventListener, sendMessage} = useUnityContext({
    loaderUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${WEBGL_PATH}/Build/AnimationViewer.loader.js?v=1.0.1`,
    dataUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${WEBGL_PATH}/Build/AnimationViewer.data?v=1.0.1`,
    frameworkUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${WEBGL_PATH}/Build/AnimationViewer.framework.js?v=1.0.1`,
    codeUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${WEBGL_PATH}/Build/AnimationViewer.wasm?v=1.0.1`,
    streamingAssetsUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${WEBGL_PATH}/StreamingAssets`,
  });

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const [openAuth, setOpenAuth] = useState(false);

  const [prompt, setPrompt] = useState('');

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showMobileErrorModal, setShowMobileErrorModal] = useState(false);
  const [paymentModalError, setPaymentModalError] = useState({isShow: false, description: ''});

  const [showWebGL, setShowWebGL] = useState(false);
  const [showProgressBar, setShowProgressbar] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [assetId, setAssetId] = useState('');
  const [statusEndpoint, setStatusEndpoint] = useState('');
  const [isCheckStatus, setIsCheckStatus] = useState(false);

  const [progression, setProgression] = useState(0);
  const [progressionText, setProgressionText] = useState('Capturing your prompt');

  const loadingPercentage = Math.round(loadingProgression * 100);

  const DEFAULT_TOTAL_SECONDS = 5;
  const timerRef = useRef(DEFAULT_TOTAL_SECONDS);
  const cancelRef = useRef(false);
  const [seconds, setSeconds] = useState(DEFAULT_TOTAL_SECONDS);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [refreshCredit, setRefreshCredit] = useState(false);

  const [showSleepToast, setShowSleepToast] = useState(false);
  const [showAvailableToast, setShowAvailableToast] = useState(false);
  const [showHighDemandToast, setShowHighDemandToast] = useState(false);

  const [numberOfAnimations, setNumberOfAnimations] = useState(0);

  const [estimatedTime, setEstimatedTime] = useState(0);
  const [waitTime, setWaitTime] = useState(0);
  const [showPurchaseCreditsModal, setShowPurchaseCreditsModal] = useState(false);

  const [disableGenerateButton, setDisableGenerateButton] = useState(true);

  const [showCollectionRedirectModal, setShowCollectionRedirectModal] = useState(false);
  const [showAvatarCreatorRedirectModal, setShowAvatarCreatorRedirectModal] = useState(false);

  const [examplePrompts, setExamplePrompts] = useState(['Wave left hand', 'Dribble ball', 'Jump on one leg', 'Jumping jacks']);
  const [topPrompts, setTopPrompts] = useState(['Walk in a circle', 'Baseball pitch', 'Spin clockwise', 'Throw ball']);

  const promptReference = useRef(null);

  const [iterations, setIterations] = useState(50);
  const [isReduced, setIsReduced] = useState(false);

  const [webglPath, setWebglPath] = useState('');

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const prompt = localStorage.getItem('animationPrompt');
    const isDeveloperMode = queryParameters.get("isDeveloperMode");
    const clickHighDemand = localStorage.getItem('clickHighDemand');

    if (isDeveloperMode) {
      localStorage.setItem('isDeveloperMode', isDeveloperMode);
    }

    if (clickHighDemand === 'true') {
      setShowHighDemandToast(false);
    } else {
      setShowHighDemandToast(true);
    }

    if (prompt) {
      setPrompt(prompt);
      setDisableGenerateButton(false);
      localStorage.removeItem('animationPrompt');
      SubmitPaymentModal(prompt)
    }

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, value) => searchParams.get(value),
    });

    if (params.unityPath) {
      unityProvider.unityConfig = {
        loaderUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${params.unityPath}/Build/AnimationViewer.loader.js?v=1.0.1`,
        dataUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${params.unityPath}/Build/AnimationViewer.data?v=1.0.1`,
        frameworkUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${params.unityPath}/Build/AnimationViewer.framework.js?v=1.0.1`,
        codeUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${params.unityPath}/Build/AnimationViewer.wasm?v=1.0.1`,
        streamingAssetsUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${params.unityPath}/StreamingAssets`,
      }
    } else {
      getWebGLPath(GAME_ID).then((result) => {
        if (result.status === 200) {
          const {location} = result.data;
          let path = WEBGL_PATH;
          if (location) {
            path = location;
            setWebglPath(location);
          } else {
            setWebglPath(WEBGL_PATH);
          }
          unityProvider.unityConfig = {
            loaderUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${path}/Build/AnimationViewer.loader.js?v=1.0.1`,
            dataUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${path}/Build/AnimationViewer.data?v=1.0.1`,
            frameworkUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${path}/Build/AnimationViewer.framework.js?v=1.0.1`,
            codeUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${path}/Build/AnimationViewer.wasm?v=1.0.1`,
            streamingAssetsUrl: `${process.env.REACT_APP_CDN_PATH}/krikey-ai/WebGL/AnimationViewer/${path}/StreamingAssets`,
          }
        }
      });
    }
  }, []);

  // Check the number of animations
  useEffect(() => {
    const fetchAssetsAnimated = async () => {
      const url = `${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-assets`;
      axios.get(url, {
        withCredentials: true,
      }).then(res => {
        setNumberOfAnimations(res.data.length);
      });
    }
    if (getUserSession()) {
      fetchAssetsAnimated();
    }
  }, [])

  const initializeResult = (type) => {
    return {type: `Result${type}`, data: {}, code: 200, status: '', message: ''};
  }

  const BrowserCommunication = (result) => {
    console.log('FE:', result);
    const string_result = {
      type: result.type,
      data: JSON.stringify(result.data),
      code: result?.code,
      result: result?.status,
      message: result?.message,
    };
    console.log('FE -> Unity:', JSON.stringify(string_result));
    sendMessage('BrowserCommunication', 'SendToUnity', JSON.stringify(string_result));
  }

  const RequestInformation = (type, data, user) => {
    const result = initializeResult(type);
    result.data = {
      id: assetId,
      status: 'success',
      cdn_url: `${process.env.REACT_APP_CDN_PATH}/ai_assets/${assetId}/asset`,
      error: '',
      name: prompt,
    }
    BrowserCommunication(result);
  }

  const RequestAvatars = async (type, data, user) => {
    const result = initializeResult(type);
    const avatars = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-animation-avatars`, {withCredentials: true});
    const krikeyAvatars = [];
    if (avatars.data && avatars.data.length > 0) {
      avatars.data = avatars.data.sort((a, b) => {
        const aNum = a.avatar_id && a.avatar_id.includes(' ') ? parseInt(a.avatar_id.split(' ')[1]) : 0;
        const bNum = b.avatar_id && b.avatar_id.includes(' ') ? parseInt(b.avatar_id.split(' ')[1]) : 0;
        if (aNum < bNum) return -1;
        else if (aNum > bNum) return 1;
        return 0;
      });
      avatars.data.forEach((avatar) => {
        const {content} = avatar;
        content.id = avatar.avatar_id;
        krikeyAvatars.push(JSON.stringify(avatar.content));
      });
    }
    result.data.krikeyAvatars = krikeyAvatars;

    const rpmAvatars = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-assets/readyplayerme`, {withCredentials: true});
    const readyPlayerMeAvatars = [];
    if (rpmAvatars.data && rpmAvatars.data.length > 0) {
      rpmAvatars.data.forEach((avatar, idx) => readyPlayerMeAvatars.push(JSON.stringify({
        id: `Ready Player Me ${idx + 1}`,
        url: avatar.avatar_url
      })));
    }
    result.data.readyPlayerMeAvatars = readyPlayerMeAvatars;
    BrowserCommunication(result);
  }

  useEffect(() => {
    if (isLoaded) {
      console.log('initialisationError', initialisationError ? initialisationError : 'No issue');
      addEventListener('Communication', async function (result) {
        const payload = JSON.parse(result);
        payload.data = JSON.parse(payload.data);
        const type = payload.type;
        const data = payload.data;
        const user = JSON.parse(getUserSession());
        switch (type) {
          case 'RequestInformation':
            RequestInformation(type, data, user);
            break;
          case 'RequestAvatars':
            await RequestAvatars(type, data, user);
            break;
        }
      });
    }
  }, [isLoaded]);

  const HandleCreateButton = async () => {
    if (getUserSession()) {
      if (!prompt) {
        alert('You should put prompt!');
        return;
      }

      if (isMobile) {
        setShowMobileErrorModal(true);
        return;
      }

      const {credits} = await GetUserBalance();

      if (credits >= 5) {
        setShowPaymentModal(true);
      } else {
        localStorage.setItem('animationPrompt', prompt);
        setShowPurchaseCreditsModal(true);
      }
    } else {
      setOpenAuth(true);
    }
  }

  const GenerateAnimation = async (cachedPrompt) => {
    setShowCancelModal(true);
    cancelRef.current = false; // reset cancel ref

    let generatePrompt = prompt
    if (typeof cachedPrompt === 'string' && cachedPrompt !== '') {
      generatePrompt = cachedPrompt;
    }

    setSeconds(timerRef.current--);
    const interval = setInterval(()=> {
      if (timerRef.current < 0 || cancelRef.current === true) {
        timerRef.current = DEFAULT_TOTAL_SECONDS;  // reset timer
        clearInterval(interval);
        setShowCancelModal(false);
        setSeconds(DEFAULT_TOTAL_SECONDS); // reset seconds
      } else {
        setSeconds(timerRef.current--);
      }
    }, 1000);

    await new Promise(r => setTimeout(r, (DEFAULT_TOTAL_SECONDS+1) * 1000));

    if (cancelRef.current === false) {
      return await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/accounts/${process.env.REACT_APP_ACCOUNT_ID}/ai-assets/generate`,
        {prompt: generatePrompt, iterations, model_index: isReduced ? 1 : 0},
        {withCredentials: true})
        .then(function (res) {
          return res;
        })
        .catch(function (err) {
          return err.response;
        });
    } else {
      return {
        status: 204 // do nothing user canceled
      }
    }
  }

  const RequestStatusEndpoint = async () => {
    return await axios.get(statusEndpoint, {
      withCredentials: true,
    }).then((res) => {
      return res;
    }).catch((err) => {
      return err.response.data;
    });
  }

  useEffect(() => {
    if (isCheckStatus) {
      RequestStatusEndpoint().then((result) => {
        if (result.status === 200) {
          const {status} = result.data;
          if (PROGRESS_INFOS[status]) {
            setProgression(PROGRESS_INFOS[status].percentage);
            setProgressionText(PROGRESS_INFOS[status].text);
          }
        }
      });
      setRefreshCredit(true);
      let interval = setInterval(() => {
        RequestStatusEndpoint().then((result) => {
          if (result.status === 200) {
            const {status} = result.data;
            if (PROGRESS_INFOS[status]) {
              setProgression(PROGRESS_INFOS[status].percentage);
              setProgressionText(PROGRESS_INFOS[status].text);
            }

            if (status === 'GENERATION_STARTING') {
              setShowAvailableToast(false);
            }

            if (status === 'DONE') {
              setTimeout(() => {
                setShowSleepToast(false);
                setShowProgressbar(false);
                setShowWebGL(true);
                setIsCheckStatus(false);
                setIsProcessing(false);
                if (numberOfAnimations === 0) {
                  setTimeout(() => {
                    setShowAvatarCreatorRedirectModal(true);
                  }, 25000);
                }
              }, 2000);
            }
          }
        });
      }, 5000);
      return () => {
        clearInterval(interval);
      }
    }
  }, [isCheckStatus]);

  const ErrorHandler = (msg, req) => {
    console.log(msg);
    console.log(req);
    setShowErrorModal(true);
    setProgression(0);
    setShowProgressbar(false);
    setIsProcessing(false);
  }

  const SubmitPaymentModal = async (cachedPrompt = '') => {
    setShowHighDemandToast(false);
    ClosePaymentModal();
    let result;
    if (isDeveloper() === 'true') {
      ErrorHandler('Developer Modal', {'msg': 'developer mode'});
      return;
    }

    try {
      result = await GenerateAnimation(cachedPrompt);
      if (result.status === 200) {
        const {status_endpoint, estimated_wait_time} = result.data;
        let {assetId} = result.data;
        if (!assetId) {
          const parts = status_endpoint.split('/');
          assetId = parts[parts.length - 2];
          setAssetId(assetId);
        }

        setShowSleepToast(true);
        setShowProgressbar(true);
        setIsProcessing(true);
        setStatusEndpoint(status_endpoint);
        setIsCheckStatus(true);

        if (estimated_wait_time && estimated_wait_time > 0) {
          setWaitTime(estimated_wait_time);
          setEstimatedTime(Date.now() + estimated_wait_time);
          setShowAvailableToast(true);
        }

        // Add 480,000 ms = 8 min
        setTimeout(() => {
          if (isProcessing) {
            setShowCollectionRedirectModal(true);
          }
        }, 480000);

      } else if (result.status === 204) {
        console.log('FE:', 'status 204 - do nothing user canceled generate');
      } else if (result.status === 402) {
        setShowPurchaseCreditsModal(true);
      } else {
        ErrorHandler('', result);
      }
    } catch (e) {
      ErrorHandler(e, result);
    }
  }

  const ClosePaymentModal = () => {
    setShowPaymentModal(false);
  }

  const CloseMobileErrorModal = () => {
    setShowMobileErrorModal(false);
  }

  const CloseCancelModal = () => {
    setShowCancelModal(false);
  }

  const CloseErrorModal = () => {
    setShowErrorModal(false);
  }

  const SleepToggleToast = () => {
    setShowSleepToast(!showSleepToast);
  }

  const HighDemandToggleToast = () => {
    localStorage.setItem('clickHighDemand', 'true');
    setShowHighDemandToast(!showHighDemandToast);
  }

  const ClosePurchaseCreditsModal = () => {
    setShowPurchaseCreditsModal(false);
  }

  const CloseCollectionRedirectModal = () => {
    setShowCollectionRedirectModal(false);
  }

  const CloseAvatarCreatorRedirectModal = () => {
    setShowAvatarCreatorRedirectModal(false);
  }

  const ExampleComponents = () => {
    return (<div className={'col-10 col-md-10 col-lg-6 my-3 py-2'}>
      <div className={'row text-white'}>
        <div className={'col-12 col-md-4 col-lg-4 text-center mt-2'}>
          <div><MdOutlineLightMode size={30}/></div>
          <h4 className={'py-3'}>Examples</h4>
          {examplePrompts.map((examplePrompt) => {
            return <button className={`btn w-100 mb-2 text-white prompt-btn`} key={examplePrompt} disabled={isMobile} onClick={() => {
              setDisableGenerateButton(false);
              setPrompt(examplePrompt);
              promptReference.current.focus();
            }}>
              <div className={'row'}>
                <div className={'col-10 text-start'}>
                  “{examplePrompt}”
                </div>
                <div className={'col-2 ps-0 text-center'}>
                  <BiRightArrowAlt size={25}/>
                </div>
              </div>
            </button>
          })}
        </div>
        <div className={'col-12 col-md-4 col-lg-4 text-center mt-2'}>
          <div><BsLightningCharge size={30}/></div>
          <h4 className={'py-3'}>Top Prompts</h4>
          {topPrompts.map((topPrompt) => {
            return <button className={`btn w-100 mb-2 text-white prompt-btn`} key={topPrompt} disabled={isMobile} onClick={() => {
              setDisableGenerateButton(false);
              setPrompt(topPrompt);
              promptReference.current.focus();
            }}>
              <div className={'row'}>
                <div className={'col-10 text-start'}>
                  “{topPrompt}”
                </div>
                <div className={'col-2 ps-0 text-center'}>
                  <BiRightArrowAlt size={25}/>
                </div>
              </div>
            </button>
          })}
        </div>
        <div className={'col-12 col-md-4 col-lg-4 text-center mt-2'}>
          <div><RiChatOffLine size={30}/></div>
          <h4 className={'py-3'}>Limitations</h4>
          <button className={`btn w-100 mb-2 text-white prompt-btn`} style={{height: 'auto'}}>
            <div className={'row justify-content-center mb-1'}>
              <div className={'col-10 text-center'}>
                May occasionally create inaccurate animations
              </div>
            </div>
          </button>
          <button className={`btn w-100 mb-2 my-lg-2 text-white prompt-btn`} style={{height: 'auto'}}>
            <div className={'row justify-content-center mb-1'}>
              <div className={'col-10 text-center'}>
                Can only create action verbs
              </div>
            </div>
          </button>
          <button className={`btn w-100 mb-2 my-lg-2 text-white prompt-btn`} style={{height: 'auto'}}>
            <div className={'row justify-content-center mb-1'}>
              <div className={'col-10 text-center'}>
                Does not generate 3D props to go with animations
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>)
  }

  return (
    <div className="bg-cover-solar-pups">
      <header>
        <Nav location="animation" openAuth={openAuth}/>
      </header>

      <PurchaseCreditsModal modalShow={showPurchaseCreditsModal} closeHandler={ClosePurchaseCreditsModal} redirect={'animation'}/>
      <MobileErrorModal modalShow={showMobileErrorModal} closeHandler={CloseMobileErrorModal}/>
      <CancelModal seconds={seconds} cancelRef={cancelRef} modalShow={showCancelModal} title={'Thanks for generating...'} closeHandler={CloseCancelModal}/>
      <ErrorModal modalShow={showErrorModal} title={'Asset Generation Error'} closeHandler={CloseErrorModal}/>
      <PaymentModal modalShow={showPaymentModal} error={paymentModalError} closeHandler={ClosePaymentModal} cost={5} submitHandler={SubmitPaymentModal}/>
      <RedirectModalWithButton modalShow={showCollectionRedirectModal} closeHandler={CloseCollectionRedirectModal} redirect={'/dashboard/collection'} description={'It looks like the line is busier than usual, so will be adding your animation in your collection when it is complete. Head over to check it out!'} redirectDescription={'Visit Your Collection'}/>
      <RedirectModalWithButton modalShow={showAvatarCreatorRedirectModal} closeHandler={CloseAvatarCreatorRedirectModal} redirect={'/avatar-creator'} description={'Congrats on your first animation! Would you like to customize your avatar for an even better experience?'} redirectDescription={'Customize Avatar'}/>

      <ToastContainer position={'top-center'} className={'pt-5 mt-5'}>
        <Toast className={'toast-bg'} show={showHighDemandToast}>
          <Toast.Body>
            <div className={'row text-white'}>
              <div className={'col-1 px-2 text-center my-auto'}>
                <RiErrorWarningLine size={20}/>
              </div>
              <div className={'col-9 ps-1'}>
                <p className={'m-0'} style={{fontSize: '11px'}}>Due to high demand, you may experience some waiting time while generating your animation. Thank you!</p>
              </div>
              <div className={'col-2 p-0 my-auto'}>
                <p className={'m-0'} onClick={HighDemandToggleToast}><strong>OK</strong></p>
              </div>
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <ToastContainer position={'bottom-center'} className={'pb-5 mb-4'}>
        <Toast className={'toast-bg'} show={showSleepToast}>
          <Toast.Body>
            <div className={'row text-white'}>
              <div className={'col-1 px-2 text-center my-auto'}>
                <RiErrorWarningLine size={20}/>
              </div>
              <div className={'col-9 ps-1'}>
                <p className={'m-0'} style={{fontSize: '11px'}}>Don't let your computer go to sleep or close the tab while your animation is processing</p>
              </div>
              <div className={'col-2 p-0 my-auto'}>
                <p className={'m-0'} onClick={SleepToggleToast}><strong>OK</strong></p>
              </div>
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <ToastContainer position={'top-center'} className={'pt-5 mt-5'}>
        <Toast className={'toast-bg'} show={showAvailableToast}>
          <Toast.Body>
            <div className={'row text-white'}>
              <div className={'col-1 px-2 text-center my-auto'}>
                <TfiReload size={20}/>
              </div>
              <div className={'col-11 ps-1'}>
                <p className={'m-0'} style={{fontSize: '11px'}}>Our server is busy right now! Your animation generation will begin in {Math.floor(waitTime / 60000)} minutes</p>
                <p className={'m-0'} style={{fontSize: '11px'}}><strong>Available in <Countdown date={estimatedTime}/></strong></p>
              </div>
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <div className='container-fluid py-4 px-3 bg-black'>
        <div className='container-fluid py-4 px-3 bg-black' style={{minHeight: '9vh'}}/>

        <CreditButton refreshCredit={refreshCredit} setRefreshCredit={setRefreshCredit}/>

        <div className={'row w-100 justify-content-center align-self-center'}>
          {!showWebGL && !isLoaded && <div className={'col-12 text-center'}>
            <p className={'font-bold text-white image-banner-title-1'}>Animated Avatars</p>
            <p className={'text-white image-banner-title-2'}>Use AI to generate avatar animations</p>
          </div>}

          {showProgressBar && <div className={'col-10 my-3 py-2'}>
            <ProgressBar animated now={progression}/>
            <p className={'text-center text-white'} style={{fontSize: '1.2em'}}>{progressionText}&nbsp;&nbsp;{progression}%</p>
          </div>}

          {showWebGL && <div className={'col-10 my-3 py-2'}>
            {isLoaded === false && (
              <div className="text-white">
                <p>Loading... ({loadingPercentage}%)</p>
              </div>
            )}
            <Unity tabIndex='1' unityProvider={unityProvider} style={{width: '100%'}}/>
          </div>}

          <div className={'col-12 text-center mt-3 pt-2'}>
            {!showWebGL && <p className={'text-white home-banner-title-3'}>Describe the action you want your avatar to do</p>}
            <div className={'row justify-content-center'}>
              <div className={'col-10 col-md-10 col-lg-6'}>
                <input ref={promptReference} type="text" className="form-control home-banner-input text-white" disabled={isProcessing || showWebGL || showAvailableToast} placeholder={'E.g. throw ball'} value={prompt} onChange={(e) => {
                  if (e.target.value === '') {
                    setDisableGenerateButton(true);
                  } else {
                    setDisableGenerateButton(false);
                  }
                  setPrompt(e.target.value)
                }}/>
              </div>
            </div>
          </div>

          {!showWebGL && <div className={'row justify-content-center pt-2 mb-3'}>
            <div className={'col-10 col-lg-6 p-0 pt-2 pt-lg-0 pw-lg-2 text-center'}>
              <button className={`${isProcessing || showWebGL || showAvailableToast || disableGenerateButton || isMobile ? 'btn-disabled' : ''} btn-normal btn-size-lg text-white font-normal`} disabled={disableGenerateButton || isProcessing || showWebGL || showAvailableToast} onClick={HandleCreateButton}>Generate</button>
            </div>
          </div>}

          {showWebGL && <div className={'row justify-content-center pt-2 mb-3'}>
            <div className={'col-10 col-lg-6 p-0 pt-2 pt-lg-0 pw-lg-2 text-center'}>
              <button className={'btn-normal btn-size-lg text-white font-normal'} onClick={() => window.location.reload()}>Generate New Animation</button>
            </div>
          </div>}


          {/*{!disableGenerateButton && <div className={'row justify-content-center'}>*/}
          {/*  <div className={'col-10 col-lg-6 px-0'}>*/}
          {/*    <Form>*/}
          {/*      <Form.Label className={'text-white w-100 my-2'}>*/}
          {/*        <div className={'row'}>*/}
          {/*          <div className={'col-9 text-start'}>*/}
          {/*            <strong>Iterations: {iterations}</strong>*/}
          {/*          </div>*/}
          {/*          <div className={'col-3 text-end'}>*/}
          {/*            <strong>{'Reduce size '}*/}
          {/*              <Form.Check className={'d-inline'} type="switch" id="custom-switch"*/}
          {/*                          disabled={isProcessing || showWebGL || showAvailableToast || disableGenerateButton || isMobile}*/}
          {/*                          onChange={(e) => {*/}
          {/*                            setIsReduced(!isReduced);*/}
          {/*                          }}/>*/}
          {/*            </strong>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </Form.Label>*/}
          {/*      <RangeSlider*/}
          {/*        value={iterations}*/}
          {/*        onChange={e => setIterations(parseInt(e.target.value))}*/}
          {/*        disabled={isProcessing || showWebGL || showAvailableToast || disableGenerateButton || isMobile}*/}
          {/*        size={'lg'}*/}
          {/*        step={5}*/}
          {/*        min={10}*/}
          {/*        max={150}*/}
          {/*      />*/}
          {/*    </Form>*/}
          {/*  </div>*/}
          {/*</div>}*/}

          {!showProgressBar && <ExampleComponents/>}

        </div>

        {!showProgressBar && <div className={'row w-100 justify-content-center align-self-center mt-3'}>
          <div className={'col-10 col-lg-6 mt-3 pt-2 px-0 text-start'}>
            <p className={'font-bold text-white image-banner-title-2 horizon-line m-0'}>PUBLIC GALLERY</p>
          </div>
          <div className={'col-10 col-lg-10'}>
            <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={5000} infinite={true}>
              {EXAMPLES.map((example) => <div key={example} className={'me-2 text-center'}><Image alt={example} src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/3d-assets/${example}`} fluid={true} loading={'lazy'}/></div>)}
            </Carousel>
          </div>
        </div>}

        <div className='container-fluid py-4 px-3 bg-black' style={{minHeight: '9vh'}}/>
      </div>
      <Footer/>
    </div>
  );
}
