import './config';
import * as fcl from "@onflow/fcl";
import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {config} from "@onflow/fcl";
import {SHA3} from "sha3";
import {getUserSession} from "../Login";
const EC = require('elliptic').ec;
const ec = new EC('p256');

export const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export default function AuthProvider({children}) {
  const [currentUser, setUser] = useState({loggedIn: false, addr: undefined});
  let krikeyKeyId = 0;
  const krikeyAddress = process.env.REACT_APP_CREATOR_ADDRESS;
  const krikeyPrivateKey = process.env.REACT_APP_CREATOR_PRIVATEKEY;

  const hash = (message) => {
    const sha = new SHA3(256);
    sha.update(Buffer.from(message, 'hex'));
    return sha.digest();
  };

  const sign = (message, privateKey) => {
    const key = ec.keyFromPrivate(Buffer.from(privateKey, 'hex'));
    const sig = key.sign(hash(message)); // hashMsgHex -> hash
    const n = 32;
    const r = sig.r.toArrayLike(Buffer, 'be', n);
    const s = sig.s.toArrayLike(Buffer, 'be', n);
    return Buffer.concat([r, s]).toString('hex');
  };

  // Refer helpers folder for documentation
  const authorizationFunctionKrikey = async (account) => ({
    ...account,
    tempId: `${krikeyAddress}-${krikeyKeyId}`,
    addr: fcl.sansPrefix(krikeyAddress),
    keyId: Number(krikeyKeyId),
    signingFunction: async (signable) => ({
      addr: fcl.withPrefix(krikeyAddress),
      keyId: Number(krikeyKeyId),
      signature: sign(signable.message, krikeyPrivateKey),
    }),
  });

  useEffect(() => {
    if (fcl.currentUser) {
      fcl.currentUser.subscribe(setUser)
    }
  }, []);

  const logOutOnFlow = async () => {
    try {
      await fcl.unauthenticate();
    } catch (e) {
      console.log(e)
    }
    setUser({addr: undefined, loggedIn: false});
  }

  const logOut = async (isRedirect = true, redirectURL = '/') => {
    await logOutOnFlow();
    localStorage.removeItem('user');
    localStorage.removeItem('isDeveloperMode');
    //TODO remove krikeyToken in Cookies
    if (isRedirect) window.location.href = redirectURL;
  };

  const logIn = async () => {
    return await fcl.logIn();
  };

  const authenticate = async (provider) => {
    try {
      return await fcl.authenticate([{provider}]);
    } catch (e) {
      console.log(e)
    }
  };

  const signUp = () => {
    fcl.signUp();
  };

  const getFCL = () => {
    const userSession = getUserSession();
    if (userSession) {
      const userObj = JSON.parse(userSession);
      if (userObj.wid_provider === 'blocto') {
        config().put('discovery.wallet', process.env.REACT_APP_BLOCTO_DISCOVERY_WALLET);
      } else if (userObj.wid_provider === 'dapper') {
        config().put('discovery.wallet', process.env.REACT_APP_DAPPER_DISCOVERY_WALLET);
      }
      return fcl;
    }
    return null;
  }

  const getSetupFCL = (provider) => {
    if (provider === process.env.REACT_APP_BLOCTO_ADDRESS) {
      config().put('discovery.wallet', process.env.REACT_APP_BLOCTO_DISCOVERY_WALLET);
    } else if (provider === process.env.REACT_APP_DAPPER_ADDRESS) {
      config().put('discovery.wallet', process.env.REACT_APP_DAPPER_DISCOVERY_WALLET);
    }
    return fcl
  }

  const value = {
    getFCL,
    getSetupFCL,
    currentUser,
    logOutOnFlow,
    logOut,
    logIn,
    signUp,
    authenticate,
    authorizationFunctionKrikey,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
