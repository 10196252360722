import { config } from "@onflow/fcl";
require('dotenv').config();

config({
  "app.detail.icon": process.env.REACT_APP_DETAIL_ICON,
  "app.detail.title": process.env.REACT_APP_DETAIL_TITLE,
  "accessNode.api": process.env.REACT_APP_ONFLOW_NODE_API,
  "flow.network": process.env.REACT_APP_ONFLOW_NETWORK,
  "discovery.wallet": process.env.REACT_APP_ONFLOW_DISCOVERY_WALLET,
  "discovery.authn.endpoint": process.env.REACT_APP_PUBLIC_DISCOVERY_AUTHN_ENDPOINT,
  "discovery.authn.include": [process.env.REACT_APP_BLOCTO_ADDRESS], // Service account address
  "0xNonFungibleToken": process.env.REACT_APP_PUBLIC_CONTRACT_NONFUNGIBLETOKEN,
  "0xFungibleToken": process.env.REACT_APP_PUBLIC_CONTRACT_FUNGIBLETOKEN,
  "0xKrikeyAINFT": process.env.REACT_APP_PUBLIC_CONTRACT_KRIKEYAINFT,
  "0xFlowToken": process.env.REACT_APP_PUBLIC_CONTRACT_FLOWTOKEN,
  "0xMetadataViews": process.env.REACT_APP_PUBLIC_CONTRACT_METADATAVIEWS,
})
