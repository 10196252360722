import {Modal} from "react-bootstrap";
import React from "react";
import {getUserSession} from "../Login";

export default function PurchaseCreditsModal(props) {
  const {redirect} = props; // 2d-image or animation
  const user = JSON.parse(getUserSession());

  const ShowPriceTable = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      if (redirect === '2d-image') {
        return (<stripe-pricing-table pricing-table-id="prctbl_1MWpLZCEyK4ObA42MAxSG91j" publishable-key="pk_test_51M4tfZCEyK4ObA42kC327YKnfGckyfGc0GU41evC3GLvuWBOzhuUM0CERBW8hIuez7FQhqK2e6969GL7y7FyG1Nk00tuHwizr5" client-reference-id={`${user.user_id}`}/>);
      } else if (redirect === 'animation') {
        return (<stripe-pricing-table pricing-table-id="prctbl_1MWppICEyK4ObA42EWHOPPzi" publishable-key="pk_test_51M4tfZCEyK4ObA42kC327YKnfGckyfGc0GU41evC3GLvuWBOzhuUM0CERBW8hIuez7FQhqK2e6969GL7y7FyG1Nk00tuHwizr5" client-reference-id={`${user.user_id}`}/>);
      } else if (redirect === 'avatar-creator') {
        return (<stripe-pricing-table pricing-table-id="prctbl_1MYwH7CEyK4ObA429OVJqdKn" publishable-key="pk_test_51M4tfZCEyK4ObA42kC327YKnfGckyfGc0GU41evC3GLvuWBOzhuUM0CERBW8hIuez7FQhqK2e6969GL7y7FyG1Nk00tuHwizr5" client-reference-id={`${user.user_id}`}/>);
      } else if (redirect === 'dashboard/animation') {
        return (<stripe-pricing-table pricing-table-id="prctbl_1MYwIaCEyK4ObA42sW3mNx5I" publishable-key="pk_test_51M4tfZCEyK4ObA42kC327YKnfGckyfGc0GU41evC3GLvuWBOzhuUM0CERBW8hIuez7FQhqK2e6969GL7y7FyG1Nk00tuHwizr5" client-reference-id={`${user.user_id}`}/>);
      }
    } else if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      if (redirect === '2d-image') {
        return (<stripe-pricing-table pricing-table-id="prctbl_1MWpwaGOMzvtCpnT8wujdiAo" publishable-key="pk_live_51M4tk7GOMzvtCpnTNwOf8Hi5k3fjWwxp09vCOkFcGFKKa4s0yEpOWb1ic011hCWoo37NkIn8sfHrzfMTwBKrjjo600Si96Ia2f" client-reference-id={`${user.user_id}`}/>);
      } else if (redirect === 'animation') {
        return (<stripe-pricing-table pricing-table-id="prctbl_1MWpy5GOMzvtCpnTsC3UvByH" publishable-key="pk_live_51M4tk7GOMzvtCpnTNwOf8Hi5k3fjWwxp09vCOkFcGFKKa4s0yEpOWb1ic011hCWoo37NkIn8sfHrzfMTwBKrjjo600Si96Ia2f" client-reference-id={`${user.user_id}`}/>);
      } else if (redirect === 'avatar-creator') {
        return (<stripe-pricing-table pricing-table-id="prctbl_1MYwMPGOMzvtCpnTiccowITl" publishable-key="pk_live_51M4tk7GOMzvtCpnTNwOf8Hi5k3fjWwxp09vCOkFcGFKKa4s0yEpOWb1ic011hCWoo37NkIn8sfHrzfMTwBKrjjo600Si96Ia2f" client-reference-id={`${user.user_id}`}/>);
      } else if (redirect === 'dashboard/animation') {
        return (<stripe-pricing-table pricing-table-id="prctbl_1MYwNmGOMzvtCpnTD9NOKmUQ" publishable-key="pk_live_51M4tk7GOMzvtCpnTNwOf8Hi5k3fjWwxp09vCOkFcGFKKa4s0yEpOWb1ic011hCWoo37NkIn8sfHrzfMTwBKrjjo600Si96Ia2f" client-reference-id={`${user.user_id}`}/>);
      }
    }
  }

  const ModalTitle = () => {
    if (redirect === '2d-image') {
      return 'Purchase Credits to Generate AI Asset';
    } else if (redirect === 'animation') {
      return 'Purchase Credits to Generate AI Asset';
    } else if (redirect === 'avatar-creator') {
      return 'Purchase Credits to Save Your Custom Avatar';
    } else if (redirect === 'dashboard/animation') {
      return 'Purchase Credits to View AI Asset';
    }
    return 'Purchase Credits To View AI Asset';
  }

  return (
    <Modal
      size="md"
      name='purchaseCreditsModal'
      show={props.modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={props.closeHandler}
      centered
      contentClassName='rounded-modal border-blue bg-color-blue-gray'
    >
      <Modal.Body className='m-3'>
        <div className='row'>
          <h4 className="text-center py-2 color-aqua"><ModalTitle/></h4>
          <script async src="https://js.stripe.com/v3/pricing-table.js"/>
          <ShowPriceTable/>
        </div>
      </Modal.Body>
    </Modal>
  )
}
