import {useAuth} from "../OnFlow/AuthContext";
export default function NavAuthDropdown(props) {
  const {logOut} = useAuth();
  const bgCollection = (props.location === "collection" )? "bg-color-gray": "";
  const bgSDK = (props.location === "sdk" )? "bg-color-gray": "";
  const isDropdownVisible = (props.email !== "" )? "visible": "invisible";

  return (
    <span className={`${isDropdownVisible}`}>
      <button className="btn circle-profile" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span className="text-center text-uppercase text-white">{props.email[0]}</span>
      </button>
      <button className="btn px-0 pe-1 pe-lg-3 dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      </button>

      <ul className="dropdown-menu dropdown-menu-user" aria-labelledby="dropdownMenuButton1" style={{minWidth:'20rem'}}>
        <li>
          <a className="dropdown-item current" aria-current="true" href="#">
            <div className="row">
              <div className="col-1 text-end ps-2 pe-3">
                <button className="btn circle-profile-mini" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="text-center text-uppercase text-white">{props.email[0]}</span>
                </button>
              </div>
              <div className="col-10">
                <span className="ps-1 fw-bolder color-fancy-gray">{props.email}</span>
              </div>
            </div>
          </a>
        </li>
        <li className={`${bgSDK}`}>
          <a className="dropdown-item current" aria-current="true" href="/dashboard/projects">
            <div className="row">
              <div className="col-1 text-end">
                <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-columns-gap color-fancy-gray" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M6 1v3H1V1h5zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 12v3h-5v-3h5zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5zM6 8v7H1V8h5zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H1zm14-6v7h-5V1h5zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-5z"/>
                </svg>
              </div>
              <div className="col-10">
                <span className="ps-1 fw-bolder color-fancy-gray">SDK Dashboard</span>
              </div>
            </div>
          </a>
        </li>
        <li className={`${bgCollection}`}>
          <a className="dropdown-item current" aria-current="true" href="/dashboard/collection">
            <div className="row">
              <div className="col-1 text-end">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-robot color-fancy-gray" viewBox="0 0 16 16">
                <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z"/>
                <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z"/>
              </svg>
              </div>
              <div className="col-10">
                <span className="ps-1 fw-bolder color-fancy-gray">My Collection</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a className="dropdown-item current" aria-current="true" href="/pricing/ai">
            <div className="row">
              <div className="col-1 text-end">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-credit-card pt-1 color-fancy-gray" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
                  <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
                </svg>
              </div>
              <div className="col-10">
                <span className="ps-1 fw-bolder color-fancy-gray">Purchase Credits</span>
              </div>
            </div>
          </a>
        </li>
        <li><hr className="dropdown-divider" /></li>
        <li>
          <a className="dropdown-item current" aria-current="true" href="#" onClick={logOut}>
            <div className="row">
              <div className="col-1 text-end">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-arrow-right-circle color-fancy-gray" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
              </svg>
              </div>
              <div className="col-10">
                <span className="ps-1 fw-bolder color-fancy-gray">Logout</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </span>
  );
}
