import {Button, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {GoogleLogin, googleLogout} from '@react-oauth/google';
import {FcGoogle} from "react-icons/all";

export default function LoginConfirmModal(props) {
  const [confirm, setConfirm] = useState(false);
  const [showConfirmError, setShowConfirmError] = useState(false);
  return (
    <Modal
      size="md"
      name='loginModal'
      show={props.modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={props.closeHandler}
      centered
      contentClassName='rounded-modal border-blue'
    >
      <Modal.Body className='m-3'>
        <div className='row justify-content-center'>
          <div className='col-12 text-center'>
            <img width={'50px'} src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/small-logo.webp`} alt={'Krikey AI logo'}/>
          </div>
          <div className='col-12 text-center'>
            <p className={'pt-2 font-bold'} style={{fontSize: '2em'}}>Create Your Account</p>
          </div>
          <div className='col-12 text-center'>
            <p className={'m-0 pb-4'} style={{fontSize: '1.2em'}}>
              Already have an account? <span className={'text-blue'} style={{cursor: 'pointer'}} onClick={() => {
              props.closeHandler();
              props.submitHandler();
            }}>Login</span>
            </p>
          </div>

          <div className={'col-12 text-center pb-4'}>
            <button className='btn btn-primary' disabled={true}>
            <FcGoogle size={35}/> <p className={'m-0 text-white'} style={{fontSize: '1.2em'}}><strong>Google</strong></p>
            </button>

          </div>
        </div>
        <div className='row justify-content-center'>
          <div className={'col-1 text-end p-0'}>
            <input type='checkbox' checked={confirm} onChange={(e) => {
              if (!confirm) {
                setShowConfirmError(false);
              }
              setConfirm(!confirm);
            }}/>
          </div>
          <div className='col-11 text-center'>
            <p style={{fontSize: '1em'}}>I accept the <a target={'_blank'} rel="noreferrer" href={'/terms'}>Terms of Service</a> and <a target={'_blank'} rel="noreferrer" href={'/privacy-policy'}>Privacy Policy</a></p>
          </div>
          {showConfirmError && <div className={'col-12 text-center'}>
            <p className={'text-danger'}><small>You must agree to Terms of Service and Privacy Policy</small></p>
          </div>}
          <div className='col-12 text-center'>
            <button className='btn-blue w-100 text-white btn-size-md' type='submit' onClick={() => {
              if (confirm) {
                props.closeHandler();
                props.submitHandler();
              } else {
                setShowConfirmError(true);
              }
            }}>
              <strong>Sign up</strong>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
