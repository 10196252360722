import Nav from "./Nav";
import Footer from "./Footer";
import '../assets/css/product.css';
import {useEffect, useState} from "react";
import {getUserSession} from "./Login";
import AnimatedAsset from "./Animations/AnimatedAsset";
import AnimatediPhone from "./Animations/AnimatediPhone";
import AnimatediPad from "./Animations/AnimatediPad";

export default function Pricing(props) {
  const [openAuth, setOpenAuth] = useState(false);
  const [buttonText, setButtonText] = useState('Start Free Trial');

  const HandlerFreeTrial = async (location) => {
    if (getUserSession()) {
      window.location = location;
    } else {
      (openAuth === true) ? setOpenAuth(1) : setOpenAuth(true); // flipping openAuth between 1 & true allows the modal pop everytime because state changed
    }
  }

  useEffect(() => {
    if (getUserSession()) {
      setButtonText('Generate Your Assets');
    }
  }, []);

  return (
    <div>
      <header className="">
        <Nav location="product/sdk" openAuth={openAuth}/>
      </header>

      <div className="container-fluid px-lg-5">
        <div className="row text-lg-start text-center">
          <div className="col-12 col-lg-6 pt-10">
            <h4 className="fw-bolder color-fancy-gray ">GENERATIVE GAMING TOOLS</h4>
            <h1 className="fw-bolder">Build your <span className="color-gradient-aqua">Mobile</span> or <span  className="color-gradient-aqua">Web3 Games</span> today.</h1>
            <p className="fs-4 py-3">Krikey.ai powers you to build the next generation of gaming. <br />Build - Integrate - Play</p>

            <div className="row text-lg-start text-center">
              <div className="col-12">
                <button type="button" className="btn text-white fw-bold py-2 px-4 gradient-blue-aqua" onClick={() => HandlerFreeTrial('/pricing/sdk')}>Start Free Trial</button>
              </div>
              <div className="col-0 col-lg-4">
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 p-5">
            <video className="w-100" autoPlay muted loop playsInline>
              <source src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/video/v3.mp4`} type="video/mp4"/>
            </video>
          </div>
        </div>

        <div className="row pb-3">
          <div className="col-12 col-lg-3 text-center text-lg-start">
            <span className="pt-5 color-fancy-gray fs-6 fw-bolder">MORE ABOUT OUR TOOLS</span>
          </div>
          <div className="col-0 col-lg-9 border-bottom-gray"/>
        </div>

        <div className="row p-2 p-lg-5">
          <div className="col-12 col-lg-6 text-white">
            <div className="bg-color-blue-gray p-3 shadow" style={{borderRadius: '1rem'}}>
              <div className="row pb-3">
                <div className="col-12 col-md-8 col-lg-8">
                  <h5 className="fw-bold">AI Asset Generation<sup className="px-1" style={{fontSize: "0.8rem"}}>SDK</sup></h5>
                  <p className="fw-bold fs-6 pt-1">Use AI to generate assets on or off-chain</p>
                  <div className="py-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill color-aqua pt-1" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                    2D Images
                  </div>
                  <div className="py-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill color-aqua pt-1" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                    3D Assets
                  </div>
                  <div className="py-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill color-aqua pt-1" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                    Animated Characters
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 text-center text-lg-end text-md-end ">
                  <button type="button" className="btn btn-outline-light fw-bold py-0 px-2" onClick={() => HandlerFreeTrial('/product/ai')}>{buttonText}</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 pt-2">
                  <img className="img-fluid" src="https://cdn.krikeyapp.com/krikey.xyz/images/product/asset1.webp"/>
                </div>
                <div className="col-12 col-md-4 col-lg-4 pt-2">
                  <img className="img-fluid" src="https://cdn.krikeyapp.com/krikey.xyz/images/product/asset2.webp"/>
                </div>
                <div className="col-12 col-md-4 col-lg-4 pt-2">
                  <img className="img-fluid" src="https://cdn.krikeyapp.com/krikey.xyz/images/product/asset3.webp"/>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pt-3 pt-lg-0">
            <div className="row text-white">

              <div className="col-12 pb-3">
                <div className="bg-color-blue-gray shadow-sm p-3" style={{borderRadius: '1rem'}}>
                  <div className="row">
                    <div className="col-12 col-md-8 col-lg-8">
                      <h5 className="fw-bold">Character Builder<sup className="px-1" style={{fontSize: "0.8rem"}}>SDK</sup></h5>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 text-center text-lg-end text-md-end ">
                      <button type="button" className="btn btn-outline-light fw-bold py-0 px-2" onClick={() => HandlerFreeTrial('/pricing/sdk')}>Start Free Trial</button>
                    </div>
                  </div>
                  <div className="py-2">
                    <div className="row">
                      <div className="col-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill color-aqua pt-1" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        Avatar Editor, SDKs and 3D Kits
                      </div>
                      <div className="col-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill color-aqua pt-1" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        Dynamic 3D NFT minting Tool
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill color-aqua pt-1" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        Animations SDKs
                      </div>
                      <div className="col-6">
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="col-12 pb-3">
                <div className="bg-color-blue-gray shadow-sm p-3" style={{borderRadius: '1rem'}}>
                  <div className="row">
                    <div className="col-12 col-md-8 col-lg-8">
                      <h5 className="fw-bold">Advanced Camera<sup className="px-1" style={{fontSize: "0.8rem"}}>SDK</sup></h5>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 text-center text-lg-end text-md-end ">
                      <button type="button" className="btn btn-outline-light fw-bold py-0 px-2" onClick={() => HandlerFreeTrial('/pricing/sdk')}>Start Free Trial</button>
                    </div>
                  </div>
                  <div className="py-2">
                    <div className="row">
                      <div className="col-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill color-aqua pt-1" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        Augmented Reality SDKs
                      </div>
                      <div className="col-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill color-aqua pt-1" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        Multiplane Detection
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill color-aqua pt-1" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        Hand Tracking
                      </div>
                      <div className="col-6">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="bg-color-blue-gray shadow-sm p-3" style={{borderRadius: '1rem'}}>
                  <div className="row">
                    <div className="col-12 col-md-8 col-lg-8">
                      <h5 className="fw-bold">Interactive Gaming<sup className="px-1" style={{fontSize: "0.8rem"}}>SDK</sup></h5>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 text-center text-lg-end text-md-end ">
                      <button type="button" className="btn btn-outline-light fw-bold py-0 px-2" onClick={() => HandlerFreeTrial('/pricing/sdk')}>Start Free Trial</button>
                    </div>
                  </div>
                  <div className="py-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill color-aqua pt-1" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                    Casual Gaming SDKs
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row pb-3">
          <div className="col-12 col-lg-4 text-center text-lg-end text-md-end  text-lg-start">
            <span className="pt-5 color-fancy-gray fs-6 fw-bolder">GET STARTED WITH OUR AI ASSET GENERATOR</span>
          </div>
          <div className="col-0 col-lg-8 border-bottom-gray"/>
        </div>

        <div className="row py-2 py-lg-5">
          <div className="col-12">
            <img className="img-fluid" src="https://cdn.krikeyapp.com/krikey.xyz/images/product/gaming-laptop-v2.webp"/>
          </div>
        </div>

        <div className="row pt-3 pb-5">
          <div className="col-12 col-lg-3 text-center text-lg-start">
            <span className="pt-5 color-fancy-gray fs-6 fw-bolder">BUILD GAMES USING OUR SDKs</span>
          </div>
          <div className="col-0 col-lg-9 border-bottom-gray"/>
        </div>

        <nav className="pt-3">
          <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
            <button className="nav-link product-tab active px-5 fw-bold" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="true" style={{minWidth: '15rem'}}>AI Assets</button>
            <span className="px-0 px-lg-2"/>
            <button className="nav-link product-tab px-5 fw-bold" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false" style={{minWidth: '15rem'}}>Character Builder</button>
            <span className="px-0 px-lg-2"/>
            <button className="nav-link product-tab px-5 fw-bold" id="nav-3-tab" data-bs-toggle="tab" data-bs-target="#nav-3" type="button" role="tab" aria-controls="nav-3" aria-selected="false" style={{minWidth: '15rem'}}>Interactive Gaming</button>
          </div>
        </nav>
        <div className="tab-content fw-bold" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
            <div className="row bg-color-blue-gray py-5 text-center text-lg-start justify-content-center">
              <div className="col-0 col-lg-2"/>
              <div className="col-12 col-lg-4 text-white">
                <h5 className="fw-bold pb-3">AI Assets <sup>SDK</sup></h5>
                <p className="pb-3">Use AI to generate assets on or off-chain</p>
                <div className="row">
                  <div className="col-12 pb-3">
                    <img className="img-fluid pe-1" style={{'width': '1.3rem'}} src="https://cdn.krikeyapp.com/krikey.xyz/images/product/checkbox.webp"/>
                    2D Images
                  </div>
                  <div className="col-12 pb-3">
                    <img className="img-fluid pe-1" style={{'width': '1.3rem'}} src="https://cdn.krikeyapp.com/krikey.xyz/images/product/checkbox.webp"/>
                    3D Assets
                  </div>
                  <div className="col-12 pb-3">
                    <img className="img-fluid pe-1" style={{'width': '1.3rem'}} src="https://cdn.krikeyapp.com/krikey.xyz/images/product/checkbox.webp"/>
                    Animated Characters
                  </div>
                </div>
                <div className="row text-center text-lg-start">
                  <div className="col-12 ps-lg-5 py-lg-5">
                    <button className="btn btn-primary fw-bold m-2" onClick={() => HandlerFreeTrial('/product/ai ')}>Generate Your Assets</button>
                    <a href="https://ef64x8ctl4h.typeform.com/to/YI3Lmh0N" target={'_blank'} rel="noreferrer">
                      <button className="btn btn-primary fw-bold m-2" style={{backgroundColor: '#252533', border: '2px solid white'}}>Contact Us</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-10 col-lg-4 text-lg-end pt-3">
                <div className={'mx-auto text-center justify-content-center'}>
                  <video
                    className={'w-100'}
                    src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/video/tai_chi.mp4`}
                    autoPlay
                    muted
                    loop
                    playsInline
                  />
                </div>
              </div>
              <div className="col-0 col-lg-2"/>
            </div>
          </div>

          <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
            <div className="row bg-color-blue-gray py-5 text-center text-lg-start">
              <div className="col-0 col-lg-2"/>
              <div className="col-12 col-lg-4 text-white">
                <h5 className="fw-bold pb-3">Character Builder <sup>SDK</sup></h5>
                <p className="pb-3">Bring to life your own characters with Krikees tools</p>
                <div className="row">
                  <div className="col-12 pb-3">
                    <img className="img-fluid pe-1" style={{'width': '1.3rem'}} src="https://cdn.krikeyapp.com/krikey.xyz/images/product/checkbox.webp"/>
                    Animations SDK
                  </div>
                </div>
                <div className="row text-center text-lg-start">
                  <div className="col-12 ps-4 py-5">
                    <button className="btn btn-primary fw-bold" onClick={() => HandlerFreeTrial('/pricing/sdk')}>Start Free Trial</button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 text-center text-lg-end text-md-end mx-auto">
                <div className={'iphone-frame mx-auto'}>
                  <video
                    src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/video/f3759434cd636dcd6611ce0b77582e86.mp4`}
                    className={'w-100 p-3'}
                    autoPlay
                    muted
                    loop
                    playsInline
                  />
                </div>
              </div>
              <div className="col-0 col-lg-2"/>
            </div>
          </div>


          <div className="tab-pane fade" id="nav-3" role="tabpanel" aria-labelledby="nav-3-tab">
            <div className="row bg-color-blue-gray py-5 text-center text-lg-start">
              <div className="col-0 col-lg-2"/>
              <div className="col-12 col-lg-4 text-white">
                <h5 className="fw-bold pb-3">Interactive Gaming <sup>SDK</sup></h5>
                <p className="pb-3">Deploy games faster with our templates</p>
                <div className="row">
                  <div className="col-12 pb-3">
                    <img className="img-fluid pe-1" style={{'width': '1.3rem'}} src="https://cdn.krikeyapp.com/krikey.xyz/images/product/checkbox.webp"/>
                    Casual Gaming SDK
                  </div>
                </div>
                <div className="row text-center text-lg-start">
                  <div className="col-12 ps-4 py-5">
                    <button className="btn btn-primary fw-bold" onClick={() => HandlerFreeTrial('/pricing/sdk')}>Start Free Trial</button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 text-center text-lg-end text-md-end mx-auto">
                <div className={'ipad-frame mx-auto'}>
                <video
                  src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/video/RJR_iPad.mp4`}
                  className={'w-100'}
                  autoPlay
                  muted
                  loop
                  playsInline
                />
              </div>
              </div>
              <div className="col-0 col-lg-2"/>
            </div>
          </div>
        </div>

        <div className="row py-5">
          <div className="col-12 col-lg-3 text-center text-lg-start">
            <span className="pt-5 color-fancy-gray fs-6 fw-bolder">KEY FEATURES</span>
          </div>
          <div className="col-0 col-lg-9 border-bottom-gray"/>
        </div>

        <div className="row text-center">
          <div className="col-12 col-lg-4">
            <div className="shadow p-3 mb-5 bg-body rounded" style={{minHeight: '13rem'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-globe color-blue" viewBox="0 0 16 16">
                <path
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
              </svg>
              <h3 className="fw-bolder py-2">Web</h3>
              <p>Character Builder SDKs | Advanced Camera SDKs | Interactive Gaming SDKs</p>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="shadow p-3 mb-5 bg-body rounded" style={{minHeight: '13rem'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-phone-vibrate color-blue" viewBox="0 0 16 16">
                <path d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4zM6 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6z"/>
                <path
                  d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM1.599 4.058a.5.5 0 0 1 .208.676A6.967 6.967 0 0 0 1 8c0 1.18.292 2.292.807 3.266a.5.5 0 0 1-.884.468A7.968 7.968 0 0 1 0 8c0-1.347.334-2.619.923-3.734a.5.5 0 0 1 .676-.208zm12.802 0a.5.5 0 0 1 .676.208A7.967 7.967 0 0 1 16 8a7.967 7.967 0 0 1-.923 3.734.5.5 0 0 1-.884-.468A6.967 6.967 0 0 0 15 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 0 1 .208-.676zM3.057 5.534a.5.5 0 0 1 .284.648A4.986 4.986 0 0 0 3 8c0 .642.12 1.255.34 1.818a.5.5 0 1 1-.93.364A5.986 5.986 0 0 1 2 8c0-.769.145-1.505.41-2.182a.5.5 0 0 1 .647-.284zm9.886 0a.5.5 0 0 1 .648.284C13.855 6.495 14 7.231 14 8c0 .769-.145 1.505-.41 2.182a.5.5 0 0 1-.93-.364C12.88 9.255 13 8.642 13 8c0-.642-.12-1.255-.34-1.818a.5.5 0 0 1 .283-.648z"/>
              </svg>
              <h3 className="fw-bolder py-2">Mobile</h3>
              <p>Character Builder SDKs | Advanced Camera SDKs | Interactive Gaming SDKs</p>
            </div>
          </div>
          <div className="col-12 col-lg-4">

            <div className="shadow p-3 mb-5 bg-body rounded" style={{minHeight: '13rem'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-robot color-blue" viewBox="0 0 16 16">
                <path
                  d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z"/>
                <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z"/>
              </svg>
              <h3 className="fw-bolder py-2">AI Asset Generation</h3>
              <p>2D Images | 3D Assets | Humanoid Animations</p>
            </div>
          </div>
        </div>

      </div>

      <Footer/>
    </div>
  );
}
