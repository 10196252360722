import {useEffect, useState} from "react";
import {googleSuccessFlow} from "../utils/GoogleLoginHandler";
import {useGoogleLogin} from '@react-oauth/google';
import '../assets/css/stripe-table.css';

export default function PricingFakeAI(props) {
  const [showTestMode, setShowTestMode] = useState('d-none');
  const GoogleLogin = useGoogleLogin(googleSuccessFlow());

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        setShowTestMode('d-none');
    } else {
        setShowTestMode('');
    }
  }, []);

  return (
  <div>
    <div id="root">
      <div>
          <div className="PricingTable is-whiteButtonText has-fourItems flex-container direction-column justify-content-center align-items-center" data-testid="pricing-table-container" style={{'--pt-color-primary':'#0074d4',backgroundColor: 'rgb(37, 37, 51)'}}>
              <div className="IntervalTabs-refContainer"></div>
              <div className="PricingTable-grid" style={{lineHeight:'1.15rem'}}>
                <div className="PriceColumn flex-container direction-column justify-content-flex-start align-items-center" data-testid="price-column" style={{'--pt-animate-in-order':2}}>
                      <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                          <div className="PriceColumn-aboutProduct flex-container direction-column">
                              <div className="PriceColumn-badge flex-container">
                                  <div className={`${showTestMode} Badge is-testModeBadge flex-container align-items-center`}><span className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Test Mode</span></div>
                              </div>
                              <div className="PriceColumn-textAndProductImage flex-container">
                                  <div className="PriceColumn-textContainerHeightAligner" style={{height: '106px'}}>
                                      <div className="PriceColumn-textContainer flex-container direction-column">
                                          <div className="PriceColumn-name flex-container"><span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600" style={{fontSize:'18px'}}>AI Asset Credits: 5 Credit Bundle</span></div><span className="PriceColumn-description Text Text-color--default Text-fontSize--14" style={{fontSize:'13px'}}>These credits can generate up to 1 animation OR up to 2 character customizations.</span></div>
                                  </div>
                              </div>
                          </div>
                          <div className="PriceColumn-priceAndButton flex-container direction-column">
                              <div className="PriceColumn-priceContainerHeightAligner" style={{height: '42px'}}>
                                  <div className="PriceColumn-priceContainer">
                                      <div className="flex-container spacing-4 direction-column align-items-flex-start">
                                          <div className="flex-container align-items-center"><span className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"><span>$4.99</span></span><span className="PriceColumn-interval Text Text-color--default Text-fontSize--13"></span></div>
                                      </div>
                                  </div>
                              </div><button className="Button PriceColumn-button Button--primary Button--lg" onClick={GoogleLogin} type="button" style={{backgroundColor: 'rgb(0, 116, 212)', borderColor: 'rgb(0, 116, 212)'}}><div className="flex-container justify-content-center align-items-center"><span className="Text Text-color--default Text-fontWeight--500 Text--truncate">Buy Now</span></div></button></div>
                      </div>
                  </div>
                  <div className="PriceColumn flex-container direction-column justify-content-flex-start align-items-center" data-testid="price-column" style={{'--pt-animate-in-order':2}}>
                      <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                          <div className="PriceColumn-aboutProduct flex-container direction-column">
                              <div className="PriceColumn-badge flex-container">
                                  <div className={`${showTestMode} Badge is-testModeBadge flex-container align-items-center`}><span className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Test Mode</span></div>
                              </div>
                              <div className="PriceColumn-textAndProductImage flex-container">
                                  <div className="PriceColumn-textContainerHeightAligner" style={{height: '106px'}}>
                                      <div className="PriceColumn-textContainer flex-container direction-column">
                                          <div className="PriceColumn-name flex-container"><span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600" style={{fontSize:'18px'}}>AI Asset Credits: 10 Credit Bundle + 1 Bonus</span></div><span className="PriceColumn-description Text Text-color--default Text-fontSize--14" style={{fontSize:'13px'}}>These credits can generate up to 2 animations OR up to 5 character customizations.</span></div>
                                  </div>
                              </div>
                          </div>
                          <div className="PriceColumn-priceAndButton flex-container direction-column">
                              <div className="PriceColumn-priceContainerHeightAligner" style={{height: '42px'}}>
                                  <div className="PriceColumn-priceContainer">
                                      <div className="flex-container spacing-4 direction-column align-items-flex-start">
                                          <div className="flex-container align-items-center"><span className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"><span>$9.99</span></span><span className="PriceColumn-interval Text Text-color--default Text-fontSize--13"></span></div>
                                      </div>
                                  </div>
                              </div><button className="Button PriceColumn-button Button--primary Button--lg" onClick={GoogleLogin} type="button" style={{backgroundColor: 'rgb(0, 116, 212)', borderColor: 'rgb(0, 116, 212)'}}><div className="flex-container justify-content-center align-items-center"><span className="Text Text-color--default Text-fontWeight--500 Text--truncate">Buy Now</span></div></button></div>
                      </div>
                  </div>
                  <div className="PriceColumn flex-container direction-column justify-content-flex-start align-items-center" data-testid="price-column" style={{'--pt-animate-in-order':2}}>
                      <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                          <div className="PriceColumn-aboutProduct flex-container direction-column">
                              <div className="PriceColumn-badge flex-container">
                                  <div className={`${showTestMode} Badge is-testModeBadge flex-container align-items-center`}><span className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Test Mode</span></div>
                              </div>
                              <div className="PriceColumn-textAndProductImage flex-container">
                                  <div className="PriceColumn-textContainerHeightAligner" style={{height: '106px'}}>
                                      <div className="PriceColumn-textContainer flex-container direction-column">
                                          <div className="PriceColumn-name flex-container"><span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600" style={{fontSize:'18px'}}>AI Asset Credits: 25 Credit Bundle + 2 Bonus</span></div><span className="PriceColumn-description Text Text-color--default Text-fontSize--14" style={{fontSize:'13px'}}>These credits can generate up to 5 animations OR up to 13 character customizations.</span></div>
                                  </div>
                              </div>
                          </div>
                          <div className="PriceColumn-priceAndButton flex-container direction-column">
                              <div className="PriceColumn-priceContainerHeightAligner" style={{height: '42px'}}>
                                  <div className="PriceColumn-priceContainer">
                                      <div className="flex-container spacing-4 direction-column align-items-flex-start">
                                          <div className="flex-container align-items-center"><span className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"><span>$24.99</span></span><span className="PriceColumn-interval Text Text-color--default Text-fontSize--13"></span></div>
                                      </div>
                                  </div>
                              </div><button className="Button PriceColumn-button Button--primary Button--lg" onClick={GoogleLogin} type="button" style={{backgroundColor: 'rgb(0, 116, 212)', borderColor: 'rgb(0, 116, 212)'}}><div className="flex-container justify-content-center align-items-center"><span className="Text Text-color--default Text-fontWeight--500 Text--truncate">Buy Now</span></div></button></div>
                      </div>
                  </div>
                  <div className="PriceColumn flex-container direction-column justify-content-flex-start align-items-center" data-testid="price-column" style={{'--pt-animate-in-order':3}}>
                      <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                          <div className="PriceColumn-aboutProduct flex-container direction-column">
                              <div className="PriceColumn-badge flex-container">
                                  <div className={`${showTestMode} Badge is-testModeBadge flex-container align-items-center`}><span className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Test Mode</span></div>
                              </div>
                              <div className="PriceColumn-textAndProductImage flex-container">
                                  <div className="PriceColumn-textContainerHeightAligner" style={{height: '106px'}}>
                                      <div className="PriceColumn-textContainer flex-container direction-column">
                                          <div className="PriceColumn-name flex-container"><span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600" style={{fontSize:'18px'}}>AI Asset Credits: 50 Credit Bundle + 4 Bonus</span></div><span className="PriceColumn-description Text Text-color--default Text-fontSize--14" style={{fontSize:'13px'}}>These credits can generate up to 10 animations OR up to 27 character customizations.</span></div>
                                  </div>
                              </div>
                          </div>
                          <div className="PriceColumn-priceAndButton flex-container direction-column">
                              <div className="PriceColumn-priceContainerHeightAligner" style={{height: '42px'}}>
                                  <div className="PriceColumn-priceContainer">
                                      <div className="flex-container spacing-4 direction-column align-items-flex-start">
                                          <div className="flex-container align-items-center"><span className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"><span>$49.99</span></span><span className="PriceColumn-interval Text Text-color--default Text-fontSize--13"></span></div>
                                      </div>
                                  </div>
                              </div><button className="Button PriceColumn-button Button--primary Button--lg" onClick={GoogleLogin} type="button" style={{backgroundColor: 'rgb(0, 116, 212)', borderColor: 'rgb(0, 116, 212)'}}><div className="flex-container justify-content-center align-items-center"><span className="Text Text-color--default Text-fontWeight--500 Text--truncate">Buy Now</span></div></button></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div className="row py-3"></div>

    <div id="root">
      <div>
          <div className="PricingTable is-whiteButtonText has-fourItems flex-container direction-column justify-content-center align-items-center" data-testid="pricing-table-container" style={{'--pt-color-primary':'#0074d4', backgroundColor: 'rgb(37, 37, 51)'}}>
              <div className="IntervalTabs-refContainer"></div>
              <div className="PricingTable-grid">
                <div className="PriceColumn flex-container direction-column justify-content-flex-start align-items-center" data-testid="price-column" style={{'--pt-animate-in-order':0}}>
                      <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                          <div className="PriceColumn-aboutProduct flex-container direction-column">
                              <div className="PriceColumn-badge flex-container">
                                  <div className={`${showTestMode} Badge is-testModeBadge flex-container align-items-center`}><span className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Test Mode</span></div>
                              </div>
                              <div className="PriceColumn-textAndProductImage flex-container">
                                  <div className="PriceColumn-textContainerHeightAligner" style={{height: '129px'}}>
                                      <div className="PriceColumn-textContainer flex-container direction-column">
                                          <div className="PriceColumn-name flex-container"><span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600" style={{fontSize:'18px'}}>AI Asset Credits: 100 Credit Bundle + 9 Bonus</span></div><span className="PriceColumn-description Text Text-color--default Text-fontSize--14" style={{fontSize:'13px'}}>These credits can generate up to 20 animations OR up to 54 character customizations.</span></div>
                                  </div>
                              </div>
                          </div>
                          <div className="PriceColumn-priceAndButton flex-container direction-column">
                              <div className="PriceColumn-priceContainerHeightAligner" style={{height: '42px'}}>
                                  <div className="PriceColumn-priceContainer">
                                      <div className="flex-container spacing-4 direction-column align-items-flex-start">
                                          <div className="flex-container align-items-center"><span className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"><span>$99.99</span></span><span className="PriceColumn-interval Text Text-color--default Text-fontSize--13"></span></div>
                                      </div>
                                  </div>
                              </div><button className="Button PriceColumn-button Button--primary Button--lg" onClick={GoogleLogin} type="button" style={{backgroundColor: 'rgb(0, 116, 212)', 'borderColor': 'rgb(0, 116, 212)'}}><div className="flex-container justify-content-center align-items-center"><span className="Text Text-color--default Text-fontWeight--500 Text--truncate">Buy Now</span></div></button></div>
                      </div>
                  </div>
                  <div className="PriceColumn flex-container direction-column justify-content-flex-start align-items-center" data-testid="price-column" style={{'--pt-animate-in-order':1}}>
                      <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                          <div className="PriceColumn-aboutProduct flex-container direction-column">
                              <div className="PriceColumn-badge flex-container">
                                  <div className={`${showTestMode} Badge is-testModeBadge flex-container align-items-center`}><span className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Test Mode</span></div>
                              </div>
                              <div className="PriceColumn-textAndProductImage flex-container">
                                  <div className="PriceColumn-textContainerHeightAligner" style={{height: '129px'}}>
                                      <div className="PriceColumn-textContainer flex-container direction-column">
                                          <div className="PriceColumn-name flex-container"><span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600" style={{fontSize:'18px'}}>AI Asset Credits: 250 Credit Bundle + 21 Bonus</span></div><span className="PriceColumn-description Text Text-color--default Text-fontSize--14" style={{fontSize:'13px'}}>These credits can generate up to 50 animations OR up to 135 character customizations.</span></div>
                                  </div>
                              </div>
                          </div>
                          <div className="PriceColumn-priceAndButton flex-container direction-column">
                              <div className="PriceColumn-priceContainerHeightAligner" style={{height: '42px'}}>
                                  <div className="PriceColumn-priceContainer">
                                      <div className="flex-container spacing-4 direction-column align-items-flex-start">
                                          <div className="flex-container align-items-center"><span className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"><span>$249.99</span></span><span className="PriceColumn-interval Text Text-color--default Text-fontSize--13"></span></div>
                                      </div>
                                  </div>
                              </div><button className="Button PriceColumn-button Button--primary Button--lg" onClick={GoogleLogin} type="button" style={{backgroundColor: 'rgb(0, 116, 212)', 'borderColor': 'rgb(0, 116, 212)'}}><div className="flex-container justify-content-center align-items-center"><span className="Text Text-color--default Text-fontWeight--500 Text--truncate">Buy Now</span></div></button></div>
                      </div>
                  </div>
                  <div className="PriceColumn flex-container direction-column justify-content-flex-start align-items-center" data-testid="price-column" style={{'--pt-animate-in-order':2}}>
                      <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                          <div className="PriceColumn-aboutProduct flex-container direction-column">
                              <div className="PriceColumn-badge flex-container">
                                  <div className={`${showTestMode} Badge is-testModeBadge flex-container align-items-center`}><span className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Test Mode</span></div>
                              </div>
                              <div className="PriceColumn-textAndProductImage flex-container">
                                  <div className="PriceColumn-textContainerHeightAligner" style={{height: '129px'}}>
                                      <div className="PriceColumn-textContainer flex-container direction-column">
                                          <div className="PriceColumn-name flex-container"><span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600" style={{fontSize:'18px'}}>AI Asset Credits: 500 Credit Bundle + 45 Bonus</span></div><span className="PriceColumn-description Text Text-color--default Text-fontSize--14" style={{fontSize:'13px'}}>These credits can generate up to 100 animations OR up to 272 character customizations.</span></div>
                                  </div>
                              </div>
                          </div>
                          <div className="PriceColumn-priceAndButton flex-container direction-column">
                              <div className="PriceColumn-priceContainerHeightAligner" style={{height: '42px'}}>
                                  <div className="PriceColumn-priceContainer">
                                      <div className="flex-container spacing-4 direction-column align-items-flex-start">
                                          <div className="flex-container align-items-center"><span className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"><span>$499.99</span></span><span className="PriceColumn-interval Text Text-color--default Text-fontSize--13"></span></div>
                                      </div>
                                  </div>
                              </div><button className="Button PriceColumn-button Button--primary Button--lg" onClick={GoogleLogin} type="button" style={{backgroundColor: 'rgb(0, 116, 212)', 'borderColor': 'rgb(0, 116, 212)'}}><div className="flex-container justify-content-center align-items-center"><span className="Text Text-color--default Text-fontWeight--500 Text--truncate">Buy Now</span></div></button></div>
                      </div>
                  </div>
                  <div className="PriceColumn flex-container direction-column justify-content-flex-start align-items-center" data-testid="price-column" style={{'--pt-animate-in-order':3}}>
                      <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
                          <div className="PriceColumn-aboutProduct flex-container direction-column">
                              <div className="PriceColumn-badge flex-container">
                                  <div className={`${showTestMode} Badge is-testModeBadge flex-container align-items-center`}><span className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">Test Mode</span></div>
                              </div>
                              <div className="PriceColumn-textAndProductImage flex-container">
                                  <div className="PriceColumn-textContainerHeightAligner" style={{height: '129px'}}>
                                      <div className="PriceColumn-textContainer flex-container direction-column">
                                          <div className="PriceColumn-name flex-container"><span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600" style={{fontSize:'18px'}}>AI Asset Credits: 1000 Credit Bundle + 100 Bonus</span></div><span className="PriceColumn-description Text Text-color--default Text-fontSize--14" style={{fontSize:'13px'}}>These credits can generate up to 200 animations OR up to 550 character customizations.</span></div>
                                  </div>
                              </div>
                          </div>
                          <div className="PriceColumn-priceAndButton flex-container direction-column">
                              <div className="PriceColumn-priceContainerHeightAligner" style={{height: '42px'}}>
                                  <div className="PriceColumn-priceContainer">
                                      <div className="flex-container spacing-4 direction-column align-items-flex-start">
                                          <div className="flex-container align-items-center"><span className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"><span>$999.99</span></span><span className="PriceColumn-interval Text Text-color--default Text-fontSize--13"></span></div>
                                      </div>
                                  </div>
                              </div><button className="Button PriceColumn-button Button--primary Button--lg" onClick={GoogleLogin} type="button" style={{backgroundColor: 'rgb(0, 116, 212)', 'borderColor': 'rgb(0, 116, 212)'}}><div className="flex-container justify-content-center align-items-center"><span className="Text Text-color--default Text-fontWeight--500 Text--truncate">Buy Now</span></div></button></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
  );
}
