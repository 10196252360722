import Nav from "./Nav";
import Footer from "./Footer";

export default function Contact(props) {
  return (
    <div>
      <header className="">
        <Nav />
      </header>

      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="pt-5 color-fancy-gray fw-bolder">Contact Us</h2>
          </div>
          <div className="col-12 pb-5 text-center">
            <h5 className="pt-5 color-fancy-gray fw-bolder">Questions about using Krikey SDKs or AI</h5>
            <a className="color-link fw-bold fs-5" href="mailto:support@krikey.com">support@krikey.com</a>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
