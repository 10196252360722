import Nav from "./Nav";
import Footer from "./Footer";

export default function NotFound(props) {
  return (
    <div>
      <header className="">
        <Nav />
      </header>

      <div className="container">
        <div className="row p-5">
          <div className="col-12 text-center">
            <h1 className="p-5 color-fancy-gray fw-bolder">404 NOT FOUND</h1>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}
