export default function NavCollection(props) {

  const bg2d = (props.location === "2d") ? "bg-color-baby-blue" : "";
  const bgAnimated = (props.location === "animated") ? "bg-color-baby-blue" : "";
  const text2d = (props.location === "2d") ? "fw-bolder" : "";
  const textAnimated = (props.location === "animated") ? "fw-bolder" : "";

  return (
    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div className="position-sticky pt-3 sidebar-sticky">
        <ul className="nav flex-column">
          <li className={`nav-item ${bg2d}`} onClick={() => props.setNav('2d')}>
            <a className="nav-link active" aria-current="page" href="#">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-card-image color-fancy-gray pt-1" viewBox="0 0 16 16">
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"/>
              </svg>
              <span className="d-inline d-sm-none">
                <button className="clear-button-styles" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                  <span className={`ps-1 fs-6 color-fancy-gray ${text2d}`}>2D Images</span>
                </button>
              </span>

              <span className={`d-none d-sm-inline ps-1 fs-6 color-fancy-gray ${text2d}`}>2D Images</span>
            </a>
          </li>
          <li className={`nav-item ${bgAnimated}`} onClick={() => props.setNav('animated')}>
            <a className="nav-link" href="#">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" className="bi bi-camera-reels color-fancy-gray pt-1" viewBox="0 0 16 16">
                <path d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM1 3a2 2 0 1 0 4 0 2 2 0 0 0-4 0z"/>
                <path d="M9 6h.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 7.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 16H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h7zm6 8.73V7.27l-3.5 1.555v4.35l3.5 1.556zM1 8v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1z"/>
                <path d="M9 6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM7 3a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
              </svg>
              <span className="d-inline d-sm-none">
                <button className="clear-button-styles" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                  <span className={`ps-1 fs-6 color-fancy-gray ${textAnimated}`}>Animated Avatars</span>
                </button>
              </span>

              <span className={`d-none d-sm-inline ps-1 fs-6 color-fancy-gray ${textAnimated}`}>Animated Avatars</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
