import Nav from './components/Nav';
import Footer from './components/Footer';
import './index.css';
import './assets/css/home.css';
import './assets/css/flip-countdown.css';
import React, {useState} from "react";
import {getUserSession} from "./components/Login";
import {RiListSettingsFill} from "react-icons/all";
import {Image} from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CreditButton from "./components/CreditButton";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function Assets3D() {
  const [openAuth, setOpenAuth] = useState(false);

  const [openSettings, setOpenSettings] = useState(false);

  const [prompt, setPrompt] = useState('');

  const HandleCreateButton = async () => {
    if (getUserSession()) {
      if (!prompt) {
        alert('You should put prompt!');
        return;
      }
    } else {
      setOpenAuth(true);
    }
  }

  return (
    <div className="bg-cover-solar-pups">
      <header>
        <Nav location="2d-image" openAuth={openAuth}/>
      </header>

      <div className='container-fluid pt-0 px-3 bg-black' style={{minHeight: '85vh'}}>

        <CreditButton/>

        <div className={'row w-100 justify-content-center align-self-center'}>
          <div className={'col-12 text-center'}>
            <p className={'font-bold text-white image-banner-title-1'}>3D Assets</p>
            <p className={'text-white image-banner-title-2'}>Use AI to generate 3D assets on or off chain</p>
          </div>

          <div className={'col-10'}>
            <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={3000} infinite={true}>
              <div><Image src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/3d-assets/slide-1.png`} fluid={true}/></div>
              <div><Image src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/3d-assets/slide-2.png`} fluid={true}/></div>
              <div><Image src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/3d-assets/slide-3.png`} fluid={true}/></div>
              <div><Image src={`${process.env.REACT_APP_CDN_PATH}/krikey-ai/img/3d-assets/slide-4.png`} fluid={true}/></div>
            </Carousel>
          </div>

          <div className={'col-12 text-center mt-3 pt-2'}>
            <p className={'text-white home-banner-title-3'}>Describe what you want to see</p>
            <div className={'row justify-content-center'}>
              <div className={'col-10 col-md-10 col-lg-6 ms-2 pe-0'}>
                <input type="text" className="form-control home-banner-input text-white" placeholder={'E.g. Dog dressed for Indian Wedding'} value={prompt} onChange={(e) => setPrompt(e.target.value)}/>
              </div>
              <div className={'col-1'} style={{width: '10px'}}>
                <RiListSettingsFill style={{color: '#FFFFFF', cursor: 'pointer'}} size={35} onClick={() => {
                  setOpenSettings(!openSettings);
                }}/>
              </div>
            </div>
          </div>

          <div className={'row justify-content-center pt-2 mb-3'}>
            <div className={'col-10 col-lg-3 p-0 pe-lg-2 text-center'}>
              <button className={'btn-normal-outline btn-size-lg font-normal text-blue'}>Upload</button>
            </div>
            <div className={'col-10 col-lg-3 p-0 pt-2 pt-lg-0 pw-lg-2 text-center'}>
              <button className={'btn-normal btn-size-lg text-white font-normal'} onClick={HandleCreateButton}>Generate</button>
            </div>
          </div>

        </div>
      </div>
      <Footer/>
    </div>
  );
}
