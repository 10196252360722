export default function Footer() {
  return (
    <footer className="bg-color-blue-gray text-white p-5">
      <div className="container pb-5">
        <div className="row text-lg-start text-center">
          <div className="col-12 col-lg-3 pb-5 pb-lg-0">

          <img className="img-fluid" src="https://cdn.krikeyapp.com/krikey.xyz/images/logo.png" width="60" height="60" />
          <span className="fw-bolder color-aqua fs-1">rikey</span>
            <div className="py-2">&#169; {new Date().getFullYear()} Krikey Inc.</div>
            <div style={{fontSize:'0.85rem'}}>Made with 💜 by Krikey Inc. </div>
          </div>
          <div className="col-12 col-lg-3 pt-3 ps-lg-5">
            <h4>PRODUCT</h4>
            <div>
              <a className="nav-link py-1" href="/product/ai">SDKs</a>
            </div>
            <div>
              <a className="nav-link py-1" href="/product/ai">AI Assets</a>
            </div>
            <div>
              <a className="nav-link py-1" href="/pricing/sdk">SDK Pricing </a>
            </div>
            <div>
              <a className="nav-link py-1" href="/pricing/ai">AI Pricing</a>
            </div>
          </div>
          <div className="col-12 col-lg-3 pt-3 ps-lg-5">
            <h4>RESOURCES</h4>
            <div>
              <a className="nav-link py-1" href="/faq ">FAQ</a>
            </div>
            <div>
              <a className="nav-link py-1" href="/contact">Support</a>
            </div>
            <div>
              <a className="nav-link py-1" target="_blank" href="https://krikey.gitbook.io/krikey-ai/JBrm9ZNMHNyW43c3S73h/">Documentation</a>
            </div>
          </div>
          <div className="col-12 col-lg-3 pt-3 ps-lg-5">
            <h4>COMPANY</h4>
            <div>
              <a className="nav-link py-1" href="/about ">About Us</a>
            </div>
            <div>
              <a className="nav-link py-1" href="https://krikey.com">Krikey</a>
            </div>
            {/* <div>
              <a className="nav-link py-1" href="/blog">Blog</a>
            </div> */}
            <div>
              <a className="nav-link py-1" href="https://solarpups.com">Solarpups</a>
            </div>
            <div>
              <a className="nav-link py-1" href="/terms">Terms of Service</a>
            </div>
            <div>
              <a className="nav-link py-1" href="/privacy-policy">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
  